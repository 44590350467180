// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
  development: {
    apiKey: "AIzaSyBVR0lBgQgsgBhP5bZUyQCBgoe1eTMMMXc",
    authDomain: "smartway-632fb.firebaseapp.com",
    projectId: "smartway-632fb",
    storageBucket: "smartway-632fb.appspot.com",
    messagingSenderId: "185556854175",
    appId: "1:185556854175:web:9e6b87cbf4591f1cee49aa",
    measurementId: "G-K3SFETFL4S",
  },
  production: {
    apiKey: "AIzaSyARUc_MQQaaHph2-gkWFOLLyXaakzM14Rk",
    authDomain: "smartway-production-e4553.firebaseapp.com",
    projectId: "smartway-production-e4553",
    storageBucket: "smartway-production-e4553.appspot.com",
    messagingSenderId: "433191552705",
    appId: "1:433191552705:web:2f96ff4e7d2ead51137640",
    measurementId: "G-QV78LHK352",
  },
};
const nodeEnv = process.env.REACT_APP_NODE_ENV || "production";
console.log('Firebase', nodeEnv)

const app = initializeApp(firebaseConfig[nodeEnv]);
export const auth = getAuth(app);
export const db = getFirestore(app)

export const getFirebaseError = (error) => {
  // console.log('Handle firebase error', error)
  switch (error.code) {
    case 'auth/invalid-login-credentials':
      return 'Invalid credentials'
    case 'auth/user-not-found':
      return 'User not found.'
    case 'auth/invalid-email':
      return 'Invalid email address.'
    case 'auth/missing-email':
      return 'Please fill out the email address'
    case 'auth/wrong-password':
      return 'Wrong password.'
    case 'auth/too-many-requests':
      return 'Too many requests.'
    case 'auth/user-disabled':
      return 'Your account is disabled. Activate it it\'s your first login otherwise please contact an administrator.'
    default:
      return 'An error occurred. Please try again later.'
  }
}
