import { useState } from 'react'
import { read, utils, writeFile } from 'xlsx'

import MDButton from 'components/MDButton'
import MDBox from 'components/MDBox'
import MDAlert from 'components/MDAlert'
import MDTypography from 'components/MDTypography'

import {
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Icon,
  IconButton
} from '@mui/material'

import schema from './schemas/people-list'

import Form from 'components/Form'

import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

const PeopleListDialog = ({ open, image, title, error, onClose, onSave }) => {
  const [seeFullList, setSeeFullList] = useState(false)
  const [emailsList, setEmailsList] = useState(null)
  const [emailsListHTML, setEmailsListHTML] = useState(null)
  const [wrongFormatColumns, setWrongFormatColumns] = useState(null)

  const handleFieldChange = async (event) => {
    const { files, name } = event.target
    if (name === 'file') {
      if (files) {
        const data = await files[0].arrayBuffer()
        const { Sheets } = read(data)
        const firstSheet = Sheets[Object.keys(Sheets)[0]]
        const sheetsJson = utils.sheet_to_json(firstSheet)
        const sheetsHTML = utils.sheet_to_html(firstSheet)

        const firstRow = sheetsJson[0]
        const checkColumnsNames = firstRow.hasOwnProperty('firstname') && firstRow.hasOwnProperty('lastname') && firstRow.hasOwnProperty('email')
        if (!checkColumnsNames) {
          setWrongFormatColumns(Object.keys(firstRow).join(', '))
        }

        setEmailsList(
          sheetsJson.map((s) => {
            const email = s.email.trim()
            return {
              first_name: s.firstname,
              last_name: s.lastname,
              email
            }
          })
        )
        setEmailsListHTML(sheetsHTML)
      } else {
        resetState()
      }
    }
  }

  const handleFullList = () => {
    setSeeFullList(!seeFullList)
  }

  const resetState = () => {
    setEmailsList(null)
    setEmailsListHTML(null)
    setSeeFullList(false)
  }

  const handleOnSubmit = () => {
    onSave(emailsList)
  }

  const handleOnClose = () => {
    if (onClose && typeof onClose === 'function') {
      onClose()
      resetState()
    }
  }

  const hiddenFullListStyles = {
    height: '200px',
    overflow: 'hidden',
    position: 'relative',
    '&:after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      content: '""',
      background: 'linear-gradient(rgba(69,83,99,0),#fff)'
    }
  }

  const handleTemplateDownload = () => {
    const a = document.createElement('a')
    a.style = 'display: none'
    const data = {
      firstname: '',
      lastname: '',
      email: ''
    }
    document.body.appendChild(a)

    const sheet = utils.json_to_sheet([data])
    const wb = utils.book_new()
    utils.book_append_sheet(wb, sheet, 'Sheet1')
    writeFile(wb, 'participants_list.xlsx')
  }

  return (
    <Dialog
      key={open}
      open={open}
      maxWidth="md"
      fullWidth
      sx={{ marginLeft: { xl: '250px' } }}
      onClose={handleOnClose}>
      <Container>
        {error ? (
          <MDAlert color="error" sx={{ mt: 1 }}>
            <Icon fontSize="large">error</Icon>&nbsp;
            {error}
          </MDAlert>
        ) : null}
        <DialogTitle>{title}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleOnClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}>
          <Icon>close</Icon>
        </IconButton>
        <DialogContent className="dialogContent">
          {wrongFormatColumns ? (
            <MDAlert color="warning">
              <Icon fontSize="large" sx={{ mr: 2 }}>
                warning
              </Icon>
              <MDTypography variant="body2" color="white">
                The file you uploaded has not the right columns names: we found these{' '}
                <b>{wrongFormatColumns}</b>.
                <br />
                It should be <b>firstname, lastname, email</b>
              </MDTypography>
            </MDAlert>
          ) : null}
          <MDTypography variant="h5">Upload a list of people</MDTypography>
          <MDBox display="flex" justifyContent="space-between" alignItems="flex-end">
            <MDTypography variant="body2" mt={2}>
              <p>
                Upload a list of people that will be invited to your offsite.
                <br /> The format of the columns should be: <b>firstname, lastname, email</b>
              </p>
            </MDTypography>
            <MDButton variant="outlined" size="small" color="dark" onClick={handleTemplateDownload}>
              <Icon sx={{ mr: 1 }}>download</Icon>
              Download an .xlsx template
            </MDButton>
          </MDBox>
          <Form
            id="emails-list-form"
            {...schema}
            values={image || {}}
            onSubmit={handleOnSubmit}
            watchedFields={['file']}
            onFieldChange={handleFieldChange}
          />
          {emailsList ? (
            <MDBox
              py={2}
              sx={{
                ...(!seeFullList && hiddenFullListStyles),
                position: 'relative',
                table: {
                  width: '100%',
                  border: 'solid 1px #DDEEEE',
                  borderCollapse: 'collapse',
                  borderSpacing: 0,
                  font: 'normal 14px Arial, sans-serif'
                },
                'table thead th': {
                  backgroundColor: '#DDEFEF',
                  border: 'solid 1px #DDEEEE',
                  color: '#336B6B',
                  padding: '10px',
                  textAlign: 'left',
                  textShadow: '1px 1px 1px #fff'
                },
                'table tbody td': {
                  border: 'solid 1px #DDEEEE',
                  color: '#333',
                  padding: '10px',
                  textShadow: '1px 1px 1px #fff'
                }
              }}>
              <div dangerouslySetInnerHTML={{ __html: emailsListHTML }} />
              <MDButton
                color="secondary"
                sx={{
                  position: 'absolute',
                  bottom: '0px',
                  right: '0px',
                  zIndex: 2
                }}
                onClick={handleFullList}>
                {seeFullList ? <VisibilityOff sx={{ mr: 1 }} /> : <Visibility sx={{ mr: 1 }} />}
                {seeFullList ? 'Hide' : 'Show'} full list
              </MDButton>
            </MDBox>
          ) : null}
        </DialogContent>
        <DialogActions>
          <MDButton onClick={onClose}>Cancel</MDButton>
          <MDButton
            variant="contained"
            color="primary"
            disabled={!emailsList || wrongFormatColumns}
            onClick={handleOnSubmit}>
            Save
          </MDButton>
        </DialogActions>
      </Container>
    </Dialog>
  )
}

export default PeopleListDialog
