import { useState, useEffect, useCallback } from 'react'
import { format } from 'date-fns'

import DataTable from 'components/Tables/DataTable'
import OffsiteActionsCell from './OffsiteActionsCell'

import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import { ListSkeleton } from './Skeletons'
import Chip from '@mui/material/Chip'

import noResults from 'assets/illustrations/noresults.svg'
import { capitalizeFirstLetter } from 'utils/functions'

const OffsitesTableView = ({ offsites, hideColumns = [], pagination, onDelete, onClone }) => {
  const [table, setTable] = useState(null)
  const [tableReady, setTableReady] = useState(false)

  const handleDeleteOffer = (offsite) => {
    onDelete(offsite.id)
  }

  const handleCloneOffsite = (offsite) => {
    onClone(offsite.id)
  }

  const getOffsitesTable = useCallback(async () => {
    setTableReady(true)
    setTable({
      columns: [
        { Header: 'company', accessor: 'company' },
        { Header: 'town / venue', accessor: 'venue' },
        { Header: 'dates', accessor: 'dates' },
        { Header: 'title', accessor: 'title' },
        { Header: 'status', accessor: 'status', width: '15%' }
      ].filter((c) => !hideColumns.includes(c.accessor)),
      rows:
        offsites && !!offsites.length
          ? offsites.map((offsite) => {
              const { title, status, company, venue, start_at, end_at, indicative_number_days } = offsite
              return {
                __data: offsite,
                company: company?.name,
                venue: venue?.name,
                dates: indicative_number_days ? `${indicative_number_days} day${indicative_number_days > 1 ? 's' : ''}` :`${format(new Date(start_at), 'dd MMM')} - ${format(
                  new Date(end_at),
                  'dd MMM'
                )}`,
                title,
                status: (
                  <MDBox maxWidth="230px">
                    {status.map((status) => (
                      <Chip key={status} sx={{ mr: 1}} label={capitalizeFirstLetter(status.toLowerCase())} variant="outlined" size="small"/>
                    ))}
                  </MDBox>
                )
              }
            })
          : []
    })
  }, [hideColumns, offsites])

  useEffect(() => {
    if (offsites && offsites.length) {
      getOffsitesTable()
    }
  }, [getOffsitesTable, offsites])

  return offsites && offsites.length ? (
    tableReady ? (
      <DataTable
        table={table}
        pagination={pagination}
        hideEntriesPerPage
        actionsOnRowHover={
          <OffsiteActionsCell onClone={handleCloneOffsite} onDelete={handleDeleteOffer} />
        }
      />
    ) : (
      <ListSkeleton />
    )
  ) : (
    <MDBox
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="50vh">
      <MDBox component="img" src={noResults} alt="no results" width="100%" maxHeight={200} mb={3} />
      <MDTypography variant="h5">No results found!</MDTypography>
    </MDBox>
  )
}

export default OffsitesTableView
