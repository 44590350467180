const accommodation = ({ accommodation, groups, onPublishChange }) => {
  const distanceVisible = !!accommodation.position_switch
  const hosts = groups && groups.length ? groups.find(g => g.name === 'Host') : {}
  const publishButton = accommodation.id ? accommodation.published_at ? {
    label: 'Unpublish',
    color: 'warning',
    tooltip: 'By unpublishing, you will not be able to see the public page anymore',
    icon: 'visibility_off',
    onClick: () => onPublishChange(false, accommodation)
  } : {
    label: 'Publish',
    color: 'success',
    tooltip: 'Be sure to have saved before publishing',
    icon: 'rocket_launch',
    onClick: () => onPublishChange(true, accommodation)
  } : null

  return {
    id: 'accommodation-form',
    title: 'Accommodation Information',
    ctasPosition: 'top',
    fieldsets: [
      {
        id: 'general',
        title: 'General',
        type: 'accordion',
        fields: [
          {
            type: 'text',
            label: 'Name',
            name: 'name',
            required: true,
            validationType: 'string',
            validations: [
              {
                type: 'required',
                params: ['This field is required']
              }
            ]
          },
          {
            type: 'select',
            name: 'town',
            label: 'Town',
            labelProp: 'name',
            valueProp: 'id',
            optionsGetter: {
              endpoints: [
                {
                  name: 'town',
                  entity: 'town',
                  prefix: 'town'
                }
              ]
            },
            grid: { xs: 6 },
            validationType: 'string',
            validations: [
              {
                type: 'nullable',
                params: []
              }
            ]
          },
          {
            type: 'select',
            name: 'host',
            label: 'Host',
            labelProp: 'name',
            valueProp: 'profileId',
            required: true,
            optionsGetter: {
              endpoints: [
                { name: 'host', entity: 'profilesByRole', params: { roles: hosts.id, transformResponse: true } }
              ]
            },
            grid: { xs: 6 },
            validationType: 'number',
            validations: [
              {
                type: 'nullable',
                params: []
              },
              {
                type: 'required',
                params: ['This field is required']
              }
            ]
          },
          {
            type: 'address',
            name: 'address',
            label: 'Address',
            required: true,
            validationType: 'string',
            validations: [
              {
                type: 'required',
                params: 'Address is required'
              }
            ]
          },
          {
            type: 'text',
            label: 'Phone Number',
            name: 'phone_number',
            validationType: 'text',
            validations: null,
            grid: { xs: 6, mr: 8 }
          },
          {
            type: 'textarea',
            name: 'excerpt',
            label: 'Excerpt',
            placeholder: 'Write an excerpt',
            validationType: 'string',
            validations: null
          },
          {
            type: 'switch',
            name: 'position_switch',
            label: 'Is the accommodation outside the center?',
            validationType: 'boolean',
            validations: null,
            grid: { xs: 6 }
          },
          {
            type: 'number',
            name: 'position',
            label: 'Distance from center (kilometers)',
            placeholder: 'Position',
            suffix: 'km',
            hidden: !distanceVisible,
            validationType: 'string',
            validations: null,
            grid: { xs: 4 }
          },
          {
            type: 'number',
            label: 'Min price',
            name: 'price_min',
            validationType: 'string',
            suffix: '€',
            validations: null,
            grid: { xs: 3 }
          },
          {
            type: 'number',
            label: 'Max price',
            name: 'price_max',
            suffix: '€',
            validationType: 'string',
            validations: null,
            grid: { xs: 3 }
          },
          {
            type: 'number',
            label: 'Normal price',
            name: 'normal_price',
            suffix: '€',
            validationType: 'string',
            validations: null,
            grid: { xs: 3 }
          },
          {
            type: 'number',
            label: 'Weekday price',
            name: 'weekday_price',
            suffix: '€',
            validationType: 'string',
            validations: null,
            grid: { xs: 3 }
          },
          // {
          //   type: 'switch',
          //   name: 'available',
          //   label: 'Is the accommodation available?',
          //   validationType: 'boolean',
          //   validations: null
          // },
          {
            type: 'switch',
            name: 'breakfast_included',
            label: 'Is the breakfast included?',
            validationType: 'boolean',
            validations: null
          },
          {
            type: 'textarea',
            name: 'description',
            label: 'Description',
            required: true,
            placeholder: 'Write a description',
            validationType: 'string',
            validations: [
              {
                type: 'required',
                params: ['This field is required']
              }
            ]
          },
          {
            type: 'video',
            label: 'Video',
            name: 'video',
            placeholder: 'Upload a video',
            validationType: 'string',
            collectionName: 'accommodations',
            validations: [
              {
                type: 'fileSize',
                params: ['File too large', '104860000']
              },
              {
                type: 'fileFormat',
                params: ['Unsupported Format', ['video/mp4']]
              },
              {
                type: 'nullable'
              }
            ],
            maxSize: 104860000,
            accept: '.mp4, .mkv, .webm, .avi, .mov, .wmv, .amv, .m4p, .mpeg, .mpg'
          }
        ]
      },
      {
        id: 'settings',
        title: 'Accommodation settings',
        type: 'accordion',
        fields: [
          {
            type: 'select',
            name: 'categories',
            label: 'Categories of accommodation *',
            multiple: true,
            optionsGetter: {
              endpoints: [
                {
                  entity: 'locationCategories',
                  id: 'accommodation'
                }
              ]
            },
            labelProp: 'name',
            valueProp: 'id',
            required: true,
            validationType: 'array',
            validations: [
              {
                type: 'required',
                params: ['This field is required']
              },
              {
                type: 'nullable',
                params: []
              },
              {
                type: 'min',
                params: [1, 'You must select at least one category']
              }
            ],
            grid: { xs: 6 }
          },
          {
            type: 'number',
            label: 'Full capacity',
            name: 'capacity',
            validationType: 'string',
            validations: null,
            grid: { xs: 6 }
          },
          {
            type: 'number',
            label: 'Number of rooms',
            name: 'room_qty',
            validationType: 'number',
            validations: null,
            grid: { xs: 6 }
          },
          {
            type: 'number',
            label: 'Number of bathrooms',
            name: 'bathroom_qty',
            validationType: 'number',
            validations: null,
            grid: { xs: 6 }
          },
          {
            type: 'number',
            label: 'Surface',
            name: 'area',
            suffix: 'mq',
            validationType: 'number',
            validations: null,
            grid: { xs: 6, mr: '50%' }
          },
          {
            type: 'number',
            label: 'Download speed',
            name: 'download_speed',
            suffix: 'Mbps',
            validationType: 'number',
            validations: null,
            grid: { xs: 6 }
          },
          {
            type: 'number',
            label: 'Upload speed',
            name: 'upload_speed',
            validationType: 'number',
            suffix: 'Mbps',
            validations: null,
            grid: { xs: 6 }
          },
          {
            type: 'text',
            label: 'Park name',
            name: 'park_name',
            validationType: 'string',
            validations: null,
            grid: { xs: 6 }
          },
          {
            type: 'address',
            name: 'park_address',
            label: 'Park position',
            latitudeProp: 'park_latitude',
            longitudeProp: 'park_longitude',
            validationType: 'string'
          }
        ]
      },
      {
        id: 'features',
        title: 'Features',
        type: 'accordion',
        fields: [
          {
            type: 'checkboxgroup',
            name: 'features',
            label: '',
            // groups: accommodationsFeatures,
            optionsGetter: {
              endpoints: [
               {
                 name: 'feature',
                 entity: 'locationFeatures',
                 id: 'accommodation',
                 formatGroup: (options) => {
                  return options.reduce((acc, curr) => {
                    const alreadyInAcc = acc.find(g => g.label === curr.subtype)
                    if (alreadyInAcc) {
                      alreadyInAcc.controls.push({
                        name: curr.id,
                        label: curr.name
                      })
                    } else {
                      acc.push({
                        label: curr.subtype,
                        controls: [{name: curr.id, label: curr.name}]
                      })
                    }
                    return acc
                  }, [])
                 }
               }
              ]
            }
          }
        ]
      }
    ],
    submitButton: {
      label: 'Save',
      icon: 'save'
    },
    cancelButton: {
      label: 'Back'
    },
    publishButton
  }
}

export default accommodation