import { defer } from 'react-router-dom'
import { BaseLoader } from 'router/BaseLoader'
import { smartwayApi } from 'services/api'
import { OFFSITE_STATUSES } from 'utils/offsiteStatus'
import offsiteFilters from 'layouts/pages/offsites/list/filters'


const waiter = (resolver, time = 3000) => {
  return new Promise((resolve) => setTimeout(() => {
    resolve(resolver)
  }, time))
}

export class OffsitesLoader extends BaseLoader {
  listLoader = async ({ request }) => {
    const url = new URL(request.url)
    const searchParamsObject = Object.fromEntries(url.searchParams)
    try {
      const results = this._loader(
        smartwayApi.endpoints.fetchEntities,
        request,
        {
          entity: 'offsite',
          is_past: false,
          ...searchParamsObject,
          status: searchParamsObject.status || OFFSITE_STATUSES.map(s => s.name),
          page: 1,
          page_size: 24
        }
      )
      return defer({
        results,
        initialFilters: offsiteFilters
      })
    } catch (error) {
      return error
    }
  }

  detailLoader = async ({ params, request }) => {
    const isNew = !params.id
    const promises = isNew
      ? [this._loader(smartwayApi.endpoints.fetchFullProfile, request)]
      : [
          this._loader(smartwayApi.endpoints.fetchFullProfile, request),
          this._loader(smartwayApi.endpoints.fetchEntity, request, {
            entity: 'offsite',
            id: params.id
          }),
          this._loader(smartwayApi.endpoints.fetchOffsiteEntity, request, {
            entity: 'offer',
            offsiteId: params.id
          })
        ]
    const [profile, offsite, offer] = await Promise.all([
      ...promises
    ])
    let offsiteVenue = {}
    if (offsite && (offsite.town || offsite.all_in_venue)) {
      offsiteVenue = await this._loader(
        smartwayApi.endpoints.fetchEntity,
        request,
        {
          entity: offsite.all_in_venue ? 'allInVenue' : 'town',
          id: offsite.all_in_venue || offsite.town
        }
      )
    }

    
    return !isNew && offsite && offsite.unauthorized ? {
      unauthorized: true,
      profile,
    } : {
      offsite: {...offsite, ...offer },
      profile,
      offsiteVenue
    }
  }

  detailDefinitionLoader = async ({ params, request }) => {
    const agendasResponse = await this._loader(
      smartwayApi.endpoints.fetchEntities,
      request,
      {
        entity: 'agenda',
        offsite: params.id
      }
    )
    if (agendasResponse && agendasResponse.results && agendasResponse.results[0]) {
      const isActivities = agendasResponse.results[0].activities && agendasResponse.results[0].activities.length
      const activities = isActivities ? agendasResponse.results[0].activities : []
      return {
        activitiesNumber: activities.length
      }
    }
    return {}
  }
  detailParticipantsLoader = async ({ params, request }) => {
    const participants = await waiter(this._loader(
      smartwayApi.endpoints.fetchAllEntities,
      request,
      {
        entity: 'participants',
        offsiteId: params.id,
        params: {
          page_size: 100
        }
      },{ forceRefetch: true }
    ), 600)
    
    const [dietaryOptions, allergiesOptions] = await Promise.all([
      this._loader(smartwayApi.endpoints.fetchAllEntities, request, {
        entity: 'dietaryOptions',
      }),
      this._loader(smartwayApi.endpoints.fetchAllEntities, request, {
        entity: 'foodAllergies',
      })
    ])

    return {
      participants,
      dietaryOptions,
      allergiesOptions
    }
  }
  detailAgendaLoader = async ({ params, request }) => {
    const participants = await this._loader(smartwayApi.endpoints.fetchAllEntities, request, {
        entity: 'participants',
        offsiteId: params.id,
        params: {
          page_size: 100
        }
      }, { forceRefetch: true })

    return {
      participants: participants.filter(p => p.confirmation !== false)
    }
  }
  detailCommunicationLoader = async ({ params, request }) => {
    const [communication, offsiteNotifications] = await Promise.all([
      this._loader(smartwayApi.endpoints.fetchOffsiteEntity, request, {
        entity: 'offer',
        offsiteId: params.id
      }, { forceRefetch: true }),
      this._loader(smartwayApi.endpoints.fetchAllEntities, request, {
        entity: 'service-notifications',
        offsiteId: params.id,
        params: {
          page_size: 100
        }
      }, { forceRefetch: true })
    ])
    return {
      communication,
      offsiteNotifications
    }
  }
  detailAccommodationsLoader = async ({ params, request }) => {
    let rooms = []
    let roomsAccommodations = []
    const [roomsResponse, participants] = await Promise.all([
      this._loader(smartwayApi.endpoints.fetchOffsiteEntity, request, {
        entity: 'rooms',
        offsiteId: params.id,
        params: {
          page: 1,
          page_size: 200
        }
      }, { forceRefetch: true }),
      this._loader(
        smartwayApi.endpoints.fetchAllEntities,
        request,
        {
          entity: 'participants',
          offsiteId: params.id,
          params: {
            page_size: 100
          }
        }, { forceRefetch: true }
      )
    ])
    if (roomsResponse && roomsResponse.results) {
      rooms = roomsResponse.results

      const accommodations = roomsResponse.results.reduce((acc, curr) => {
        const { accommodation } = curr.detail
        if (accommodation && !acc.includes(accommodation)) {
          return [...acc, accommodation]
        }
        return acc
      }, [])
      if (accommodations && accommodations.length) {
        roomsAccommodations = await Promise.all(
          accommodations.map((accommodation) => {
            return this._loader(smartwayApi.endpoints.fetchEntity, request, {
              entity: 'accommodation',
              id: accommodation
            }, { forceRefetch: true })
          })
        )
      }
    }

    return {
      rooms,
      roomsAccommodations,
      participants: participants.filter(p => p.confirmation !== false)
    }
  }
  
  detailReviewLoader = async ({ params, request}) => {
    const [participants, agendas] = [
      this._loader(smartwayApi.endpoints.fetchAllEntities, request, {
        entity: 'participants',
        offsiteId: params.id,
        params: {
          page_size: 100
        }
      }),
      this._loader(smartwayApi.endpoints.fetchEntities, request, {
        entity: 'agenda',
        offsite: params.id,
        page: 1,
        page_size: 100
      })
    ]

    return defer({
      participants,
      agendas
    })
  }
}
