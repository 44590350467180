import { useState, useEffect } from 'react'

import MDBox from 'components/MDBox'
import Grid from '@mui/material/Grid'

import CustomActivityCard from './CustomActivityCard'
import AddCustomActivityCard from './AddCustomActivityCard'
import CustomActivityDialog from 'components/Dialogs/CustomActivityDialog'

import CalendarSlot from 'utils/events'

const CustomActivities = ({
  slot,
  selectedActivity,
  activities,
  illustration,
  onActivityChange,
  onDeleteActivity,
  onSaveCustomActivity
}) => {
  const [customActivity, setCustomActivity] = useState()
  const [selected, setSelected] = useState(selectedActivity)
  const [formOpen, setFormOpen] = useState(false)
  const [selectedProp, setSelectedProp] = useState(selectedActivity && selectedActivity.custom_id ? 'custom_id' : 'id')

  const handleSelectCustomActivity = (activity) => {
    const { description, title, activity: nestedActivity, custom_id, id, custom_latitude, custom_longitude, custom_image_formats, custom_address } = activity
    const formattedActivity =
      selected && selected[selectedProp] && selected[selectedProp] === activity[selectedProp]
        ? null
        : { ...nestedActivity, id, custom_id, custom_image_formats, custom_latitude, custom_longitude, custom_address, title, description, name: title }
    setSelected(formattedActivity)
    if (onActivityChange && typeof onActivityChange === 'function') {
      onActivityChange(formattedActivity)
    }
  }

  const handleSaveActivity = async (values) => {
    return new Promise(async (resolve) => {
      const _slot = {
        ...slot,
        id: null,
        activity: null,
        start: new Date('1970-1-1'),
        end: new Date('1970-1-1')
      }
      const newSlot = new CalendarSlot({
        ..._slot,
        activity: { ...values, name: values.title },
        type: 'CUSTOM',
      })
      const formattedSlot = newSlot.formatToServer()
      await onSaveCustomActivity({ ...formattedSlot })
      setFormOpen(false)
      resolve()
    })
  }

  const handleUpdateCustomActivity = async (values) => {
    return new Promise(async (resolve) => {
      const customActivity = activities.find(({ id }) => id === values.id)
      await onSaveCustomActivity({ ...customActivity, ...values })
      setFormOpen(false)
      resolve()
    })
  }

  const handleDeleteCustomActivity = async (activity) => {
    onDeleteActivity(activity)
  }

  const onAddClick = () => {
    setFormOpen(true)
  }

  const closeCustomActivityDialog = () => {
    setFormOpen(false)
  }

  const handleEditCustomActivity = (activityId) => {
    const activity = activities.find(({ id }) => id === activityId)
    setCustomActivity(activity)
    setTimeout(() => {
      setFormOpen(true)
    }, 200)
  }

  useEffect(() => {
    setSelectedProp(selectedActivity && selectedActivity.custom_id ? 'custom_id' : 'id')
  }, [selectedActivity])

  return (
    <MDBox>
      <Grid
        container
        spacing={2}
        mt={2}
        sx={
          !!selected
            ? {
                '.custom-activity-card:not(.selected)': { opacity: 0.4 }
              }
            : {}
        }>
        {activities && !!activities.length
          ? activities.map((act, index) => {
              const activity = { ...act, ...act.slot }
              return (
                <Grid
                  key={`custom-activity-card-${index}-${activity.id}`}
                  item
                  xs={12}
                  sm={6}
                  md={3}>
                  <CustomActivityCard
                    selected={selected && selected[selectedProp] === activity.id}
                    illustration={illustration}
                    activity={activity}
                    onCardClick={handleSelectCustomActivity}
                    onEdit={handleEditCustomActivity}
                    onDelete={handleDeleteCustomActivity}
                  />
                </Grid>
              )
            })
          : null}
        <Grid item xs={12} sm={6} md={3}>
          <AddCustomActivityCard onClick={onAddClick} />
        </Grid>
      </Grid>

      <CustomActivityDialog
        title={!!customActivity ? customActivity.name : 'Add Custom Activity'}
        open={formOpen}
        activity={customActivity}
        onSave={handleSaveActivity}
        onUpdate={handleUpdateCustomActivity}
        onClose={closeCustomActivityDialog}
      />
    </MDBox>
  )
}

export default CustomActivities
