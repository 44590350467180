import { useState } from 'react'

import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'

import MUIAvatar from '@mui/material/Avatar'
import Badge from '@mui/material/Badge'
import Icon from '@mui/material/Icon'

import AvatarDialog from 'components/Dialogs/AvatarDialog'

import neutralAvatar from 'assets/illustrations/neutral-avatar.png'

const Avatar = ({ image, alt, editable, onSave }) => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const handleAvatarDialog = () => {
    setDialogOpen(!dialogOpen)
  }

  const handleOnSave = (imageUrl) => {
    if (onSave && typeof onSave === 'function') {
      onSave(imageUrl)
      setDialogOpen(false)
    }
  }

  const FullAvatar = () => {
    const getIllustration = () => {
      return neutralAvatar
    }
    return (
      <MUIAvatar
        alt={alt}
        src={image && image.thumbnail ? image.thumbnail : getIllustration()}
        sx={{ width: 56, height: 56 }}
      />
    )
  }

  const OpenButton = () => {
    return (
      <MUIAvatar sx={{ width: 18, height: 18 }}>
        <MDButton
          variant="gradient"
          color="primary"
          sx={{ cursor: 'pointer' }}
          iconOnly
          onClick={handleAvatarDialog}>
          <Icon sx={{ color: 'white !important' }}>add-circle</Icon>
        </MDButton>
      </MUIAvatar>
    )
  }

  return (
    <MDBox>
      {editable ? (
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          badgeContent={<OpenButton />}>
          <AvatarDialog
            open={dialogOpen}
            onClose={handleAvatarDialog}
            onSave={handleOnSave}
          />
          <FullAvatar />
        </Badge>
      ) : (
        <FullAvatar />
      )}
    </MDBox>
  )
}

export default Avatar
