import { useState, useEffect, useCallback } from 'react'

import { useDispatch } from 'react-redux'
import { smartwayApi } from 'services/api'

import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import ButtonGroup from '@mui/material/ButtonGroup'
import Icon from '@mui/material/Icon'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'

import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'

import Filters from 'components/Filters'
import offsiteRoomsFilters from './filters/offsite-rooms'

import SimpleLocationCard from 'components/Cards/SimpleLocationCard'
import RoomCard from './components/OffsiteRooms/RoomCard'
import AccommodationSidebarCard from './components/OffsiteRooms/AccommodationSidebarCard'
import RoomsTableView from './components/OffsiteRooms/RoomsTableView'

import accommodationSearch from 'assets/illustrations/accommodation-search.svg'
import empty from 'assets/illustrations/empty.svg'
import { AccommodationsSkeleton } from './Skeletons'
import Spinner from 'components/Spinner'

const NoResults = ({ title, minHeight, illustration = accommodationSearch }) => {
  return (
    <MDBox
      width="100%"
      minHeight={minHeight}
      display="flex"
      justifyContent="center"
      alignItems="center">
      <MDBox display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <MDBox
          component="img"
          src={illustration}
          alt="select an accommodation"
          width="100%"
          maxHeight={200}
          mb={3}
        />
        <MDTypography variant="body">{title}</MDTypography>
      </MDBox>
    </MDBox>
  )
}

const OffsiteRoomsDialog = ({ open, offsite, venueType, rooms, setOpen, onSave, onClose }) => {
  const dispatch = useDispatch()
  const mapRooms = useCallback(() =>
    rooms && !!rooms.length
      ? rooms.map((r) => {
          return { id: r.detail.id, offsiteRoomId: r.id }
        })
      : [], [rooms])
  const [isLoading, setIsLoading] = useState(true)
  const [roomsLoading, setRoomsLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const [accommodations, setAccommodations] = useState([])
  const [accommodationIsVenue, setAccommodationIsVenue] = useState(false)
  const [filteredAccommodations, setFilteredAccommodations] = useState(accommodations)
  const [selectedAccommodation, setSelectedAccommodation] = useState(null)
  const [selectedRooms, setSelectedRooms] = useState(!!rooms ? mapRooms(rooms) : [])

  const [filtersOpen, setFiltersOpen] = useState(false)
  const [appliedFilters, setAppliedFilters] = useState({})
  const [view, setView] = useState('grid')

  const resetState = () => {
    setSelectedAccommodation(null)
    setSelectedRooms([])
    setFiltersOpen(false)
    setAppliedFilters({})
  }

  const handleOnClose = () => {
    if (onClose && typeof onClose === 'function') {
      onClose()
      resetState()
    }
  }

  const handleSelectAccommodation = async (accommodation) => {
    try {
      setRoomsLoading(true)
      setIsError(false)
      let rooms = []
      const roomsResponse = await dispatch(smartwayApi.endpoints.fetchRooms.initiate({
        entity: venueType === 'town' ? 'accommodation' : 'venueAccommodation',
        id: accommodation.id,
        page_size: 100
      }))
      if (roomsResponse.status === 'fulfilled' && roomsResponse?.data && roomsResponse?.data.results) {
        rooms = roomsResponse.data.results
      }
      setSelectedAccommodation({ ...accommodation, rooms })
      
    } catch (error) {
      console.error(error)
      setIsError(true)
    } finally {
      setRoomsLoading(false)
    }
  }

  const handleRoomSelection = (room) => {
    if (selectedRooms.map((r) => r.id).includes(room.id)) {
      setSelectedRooms(selectedRooms.filter((r) => r.id !== room.id))
    } else {
      setSelectedRooms([...selectedRooms, room])
    }
  }

  const handleSaveRooms = () => {
    onSave(selectedRooms)
    resetState()
  }

  const handleSelectAllRooms = () => {
    setSelectedRooms([...selectedRooms, ...selectedAccommodation.rooms])
  }

  const handleApplyFilters = (values) => {
    setAppliedFilters(values)
  }

  const toggleFilters = () => {
    setFiltersOpen(!filtersOpen)
  }

  const getFilteredAccommodations = useCallback(
    (filters) => {
      setSelectedAccommodation(null)
      const { amenities, categories, position, price } = filters
      let filtered = accommodations
      if (
        Object.keys(filters).some((k) =>
          Array.isArray(filters[k]) ? !!filters[k].length : !!filters[k]
        )
      ) {
        if (amenities && !!amenities.length) {
          filtered = filtered.filter((a) => {
            return amenities.every((amenity) => !!a[amenity])
          })
        }
        if (categories && !!categories.length) {
          return filtered.filter(({ rooms }) => {
            return !!rooms && rooms.some(({ level }) => !!categories.includes(level))
          })
        }
        if (position && position !== 'all') {
          filtered = filtered.filter((a) => {
            return position === 'center' ? a.position === 0 : a.position > 0
          })
        }
        if (price && price.length === 2) {
          filtered = filtered.filter(({ normal_price, rooms }) => {
            return rooms.some((r) => {
              const priceToCompare = r.normal_price || normal_price
              return priceToCompare
                ? price[0] <= priceToCompare && priceToCompare <= price[1]
                : false
            })
          })
        }
      }
      return filtered
    },
    [accommodations]
  )

  useEffect(() => {
    const offsiteVenueProp = venueType === 'allInVenue' ? 'all_in_venue' : 'town'
    const getAccommodations = async () => {
      try {
        setIsLoading(true)
        setIsError(false)
        const accommodationsResponse = await dispatch(
          smartwayApi.endpoints.fetchAllEntities.initiate({
            entity: venueType === 'allInVenue' ? 'venueAccommodation' : 'accommodation',
            [venueType === 'allInVenue' ? 'venue' : 'town']:
              venueType === 'allInVenue' ? offsite.all_in_venue : offsite.town
          })
        )
        if (accommodationsResponse && accommodationsResponse.data) {
          const results = accommodationsResponse.data
          setAccommodations(results)
          if (results.length === 1) {
            setSelectedAccommodation(results[0])
            setView('list')
            console.log(selectedAccommodation, isLoading, roomsLoading)
            await handleSelectAccommodation(results[0], true)
            setAccommodationIsVenue(true)
          } else {
            setAccommodationIsVenue(false)
          }
        }
      } catch (error) {
        console.warn(error)
        setIsError(true)
      } finally {
        setIsLoading(false)
      }
      
      setIsLoading(false)
    }
    if (offsite && offsite[offsiteVenueProp]) {
      getAccommodations()
    }
    return () => {
      resetState()
    }
  }, [dispatch, offsite, venueType, open])

  useEffect(() => {
    setFilteredAccommodations(getFilteredAccommodations(appliedFilters))
  }, [appliedFilters, getFilteredAccommodations])

  useEffect(() => {
    setSelectedRooms(!!rooms ? mapRooms(rooms) : [])
  }, [rooms, open, mapRooms])

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth="xl"
      fullWidth
      sx={{ marginLeft: { xl: '250px' } }}>
      <MDBox sx={{ maxHeight: 'calc(100vh - 80px)', overflow: 'hidden' }}>
        <IconButton
          aria-label="close"
          onClick={handleOnClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            zIndex: 2,
            color: (theme) => theme.palette.grey[500]
          }}>
          <Icon>close</Icon>
        </IconButton>
        <DialogContent sx={{ p: 0 }}>
          <MDBox display="flex" position="relative">
            {!accommodationIsVenue ? (
              <MDBox
                sx={{
                  width: '35%',
                  borderRight: '1px solid #afafaf',
                  p: 2,
                  maxHeight: 'calc(100vh - 100px)',
                  overflowY: 'scroll'
                }}>
                <MDButton sx={{ pl: 0, mb: 2 }} onClick={toggleFilters}>
                  <Icon sx={{ mr: 1 }}>tune</Icon> Filter results
                </MDButton>

                {filtersOpen ? (
                  <Card sx={{ backgroundColor: 'grey.100', position: 'relative', mb: 4 }}>
                    <IconButton
                      aria-label="close"
                      onClick={toggleFilters}
                      sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                      }}>
                      <Icon>close</Icon>
                    </IconButton>
                    <MDBox pt={1} px={1}>
                      <Filters
                        id={'offsite-rooms-filters'}
                        initialFilters={offsiteRoomsFilters}
                        appliedFilters={appliedFilters}
                        onApply={handleApplyFilters}
                      />
                    </MDBox>
                    <MDBox display="flex" justifyContent="flex-end" width="100%" p={2}>
                      <MDButton
                        form="offsite-rooms-filters"
                        type="submit"
                        variant="contained"
                        size="small"
                        sx={{ padding: '2px 16px', maxHeight: '24px', minHeight: '24px' }}
                        color="primary">
                        Apply
                      </MDButton>
                    </MDBox>
                  </Card>
                ) : null}

                <MDBox>
                  {isLoading ? (
                    <AccommodationsSkeleton />
                  ) : !!filteredAccommodations && !!filteredAccommodations.length ? (
                    filteredAccommodations.map((accommodation, i) => (
                      <MDBox key={`accommodation-card-${i}`} mb={2}>
                        <AccommodationSidebarCard
                          {...accommodation}
                          selected={selectedAccommodation?.id === accommodation.id}
                          onSelect={handleSelectAccommodation}
                        />
                      </MDBox>
                    ))
                  ) : (
                    <MDBox display="flex" justifyContent="center" alignItems="center" height="100%">
                      <MDBox width="50%">
                        <NoResults
                          title="Sorry but there are no accommodations that match your search"
                          minHeight="calc(100vh - 700px)"
                          illustration={empty}
                        />
                      </MDBox>
                    </MDBox>
                  )}
                </MDBox>
              </MDBox>
            ) : null}

            <MDBox sx={{ width: accommodationIsVenue ? '100%' : '65%' }}>
              {selectedAccommodation ? roomsLoading ? (
                <MDBox display="flex" justifyContent="center" alignItems="center" minHeight="calc(100vh - 200px)">
                  <Spinner />
                </MDBox>
              ) : (
                <MDBox>
                  <MDBox
                    sx={{
                      minHeight: 'calc(100vh - 180px)',
                      maxHeight: 'calc(100vh - 180px)',
                      overflowY: 'scroll'
                    }}>
                    <MDBox pt={6} pb={3} px={3} borderBottom="1px solid #dfdfdf">
                      <SimpleLocationCard
                        {...selectedAccommodation}
                        cta={{
                          color: 'secondary',
                          label: 'View Accommodation',
                          href: `/locations/accommodations/${selectedAccommodation.id}`
                        }}
                      />
                    </MDBox>
                    <MDBox>
                      <MDBox display="flex" justifyContent="space-between" py={2} px={3}>
                        <MDBox>
                          <MDButton
                            sx={
                              selectedAccommodation.rooms && !!selectedAccommodation.rooms.length
                                ? {}
                                : { visibility: 'hidden' }
                            }
                            variant="outlined"
                            color="secondary"
                            size="small"
                            onClick={handleSelectAllRooms}>
                            Select all
                          </MDButton>
                        </MDBox>
                        <ButtonGroup>
                          <MDButton
                            size="small"
                            variant="outlined"
                            color={view === 'grid' ? 'primary' : 'secondary'}
                            onClick={() => setView('grid')}>
                            <Icon>grid_view</Icon>
                          </MDButton>
                          <MDButton
                            size="small"
                            variant="outlined"
                            color={view === 'list' ? 'primary' : 'secondary'}
                            onClick={() => setView('list')}>
                            <Icon>view_list</Icon>
                          </MDButton>
                        </ButtonGroup>
                      </MDBox>

                      {view === 'grid' ? (
                        <Grid container rowSpacing={5} columnSpacing={2} mt={2} px={3}>
                          {selectedAccommodation.rooms && !!selectedAccommodation.rooms.length ? (
                            selectedAccommodation.rooms.map((room, index) => {
                              return (
                                <Grid key={`room-card-${index}`} item xs={6}>
                                  <RoomCard
                                    {...room}
                                    selected={
                                      selectedRooms && !!selectedRooms.length
                                        ? selectedRooms
                                            .map((r) => (r.detail ? r.detail.id : r.id))
                                            .includes(room.id)
                                        : false
                                    }
                                    onSelect={handleRoomSelection}
                                  />
                                </Grid>
                              )
                            })
                          ) : (
                            <NoResults
                              title="No rooms for this accommodation!"
                              minHeight="calc(100vh - 580px)"
                            />
                          )}
                        </Grid>
                      ) : (
                        <RoomsTableView
                          rooms={selectedAccommodation?.rooms}
                          selectedRooms={selectedRooms.map((r) => (r.detail ? r.detail.id : r.id))}
                          onSelect={handleRoomSelection}
                        />
                      )}
                    </MDBox>
                  </MDBox>
                  <MDBox
                    mt={2}
                    width="100%"
                    display="flex"
                    justifyContent="flex-end"
                    position="sticky"
                    bottom={0}
                    p={2}
                    boxShadow="2px -3px 8px 1px rgba(0, 0, 0, 0.06) !important">
                    <MDButton
                      variant="gradient"
                      color="primary"
                      disabled={!selectedRooms || !selectedRooms.length}
                      onClick={handleSaveRooms}>
                      Add selected rooms
                    </MDButton>
                  </MDBox>
                </MDBox>
              ) : (
                <NoResults title="Choose an accommodation" minHeight="calc(100vh - 200px)" />
              )}
            </MDBox>
          </MDBox>
        </DialogContent>
      </MDBox>
    </Dialog>
  )
}

export default OffsiteRoomsDialog
