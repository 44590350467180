const getRoomSchema = ({
  id,
  capacity,
  participants,
  availableParticipants,
  initialValues
}) => {
  const mappedParticipants = availableParticipants.map(
    ({ first_name, last_name, ...p }) => ({
      name: `${first_name} ${last_name}`,
      ...p
    })
  )
  return {
    id: 'room-form',
    fieldsets: [
      {
        id: 'room-participants',
        fields: new Array(capacity).fill([]).map((p, index) => {
          const name = `room-${id}-participant-${index}`
          const selectedParticipant = initialValues[name]
            ? participants.find(({ id }) => id === initialValues[name])
            : ''
          const roommates = selectedParticipant && selectedParticipant.offsite_preference && selectedParticipant.offsite_preference.roommates
          return {
            type: 'select',
            name,
            placeholder: 'Type the name of the guest',
            options: selectedParticipant
              ? [
                  {
                    name: `${selectedParticipant.first_name} ${selectedParticipant.last_name}`,
                    ...selectedParticipant
                  },
                  ...mappedParticipants
                ]
              : mappedParticipants,
            labelProp: 'name',
            helperText: roommates && !!roommates.length ? `
            Desired roommates:
            ${roommates.map(r => {
              const participant = participants.find(({ id }) => id === r)
              return participant ? `${participant.first_name} ${participant.last_name}` : ''
            }).join(', ')}`
            : '',
            valueProp: 'id',
            validationType: 'string',
            validations: null,
            grid: {
              xs: 12,
              sx: {
                '> .MuiBox-root': {
                  marginBottom: '8px'
                }
              }
            }
          }
        })
      }
    ]
  }
}

export default getRoomSchema
