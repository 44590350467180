import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { smartwayApi } from 'services/api'

import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Icon from '@mui/material/Icon'
import Skeleton from '@mui/material/Skeleton'

import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'

import ImageCarousel from 'components/ImageCarousel/ImageCarousel'
import PackageForm from '../PackageForm/PackageForm'
import SampleAccommodationsDialog from 'components/Dialogs/SampleAccommodationsDialog'
import ConfirmationDialog from 'components/Dialogs/ConfirmationDialog'
import AccommodationsSmallCard from 'layouts/pages/offsites/detail/components/communication/AccommodationSmallCard'

const iconStyles = {
  transitionProperty: 'opacity, transform',
  transitionDuration: '0.3s',
  opacity: 0,
  transform: 'scale(0.8)',
  position: 'absolute',
  top: '5px',
  right: '5px',
  zIndex: '10',
  color: 'white !important',
  borderRadius: '50%',
  width: '30px',
  height: '30px',
  backgroundColor: 'primary.main',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}
const AddAccommodationCard = ({ onAdd }) => {
  return (
    <Card sx={{ height: '100%', border: '1px dashed #7b809a' }}>
      <MDBox
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        height="100%"
        py={4}
        px={2}
        sx={{ cursor: 'pointer' }}
        onClick={onAdd}>
        <MDTypography variant="body2">Add an accommodation</MDTypography>
        <MDBox>
          <Icon fontSize="large" sx={{ color: '#7b809a !important' }}>
            house
          </Icon>
        </MDBox>
      </MDBox>
    </Card>
  )
}

const PackageFormStep = ({
  editingMode,
  entity,
  venueType,
  venue,
  onImagesChange,
  onSubmit,
  onCancel,
  onPublishChange
}) => {
  const dispatch = useDispatch()
  const [accommodationDialogOpen, setAccommodationDialogOpen] = useState(false)
  const [cloneConfirmationOpen, setCloneConfirmationOpen] = useState(false)
  const [selectedAccommodations, setSelectedAccommodations] = useState(null)
  const [accommodationsLoading, setAccommodationsLoading] = useState(false)
  const [valuesToSave, setValuesToSave] = useState(null)
  const [agendasNeedClone, setAgendasNeedClone] = useState([])

  const handleOnSubmit = async (values) => {
    if (values.agendas && !!values.agendas.length) {
      const fullAgendas = await Promise.all(
        values.agendas.map((a) =>
          dispatch(smartwayApi.endpoints.fetchEntity.initiate({ entity: 'agenda', id: a }))
        )
      )
      const needClone = fullAgendas
        .map((a) => a.data)
        .filter((a) => !!a.premade_package && a.premade_package !== entity?.id)
      if (!!needClone.length) {
        setValuesToSave(values)
        setAgendasNeedClone(needClone)
        setCloneConfirmationOpen(true)
      } else {
        onSubmit(values)
      }
    } else {
      onSubmit(values)
    }
  }

  const handleImageChange = () => {
    onImagesChange()
  }

  // Acccommodations
  const handleAddAccommodations = () => {
    setAccommodationDialogOpen(true)
  }

  const handleRemoveAccommodation = (accommodation) => {
    const newAccommodations = selectedAccommodations.filter((a) => a.id !== accommodation.id)
    const sampleProp =
      venueType === 'town' ? 'sample_accommodations' : 'sample_all_in_venues_accommodations'

    setAccommodationsLoading(true)
    onSubmit(
      { id: entity.id, [sampleProp]: newAccommodations.map((a) => a.id) },
      { method: 'patch', encType: 'application/json' }
    )
  }

  const handleSaveAccommodations = (accommodations) => {
    const sampleProp =
      venueType === 'town' ? 'sample_accommodations' : 'sample_all_in_venues_accommodations'
    onSubmit(
      { id: entity.id, [sampleProp]: accommodations.map((a) => a.id) },
      { method: 'patch', encType: 'application/json' }
    )
    setAccommodationsLoading(true)
    setAccommodationDialogOpen(false)
  }

  const handleConfirmCloneAgendas = () => {
    setCloneConfirmationOpen(false)
    onSubmit({ ...valuesToSave, agendasToClone: agendasNeedClone })
  }

  const handleCancelConfirmation = () => {
    setCloneConfirmationOpen(false)
    onSubmit({
      ...valuesToSave,
      agendas: valuesToSave.agendas.filter((a) => agendasNeedClone.map((a) => a.id).includes(a))
    })
  }

  useEffect(() => {
    const entityType = venueType === 'town' ? 'accommodation' : 'venueAccommodation'
    const sampleProp =
      venueType === 'town' ? 'sample_accommodations' : 'sample_all_in_venues_accommodations'
    const fetchFullAccommodations = async () => {
      const promises = entity[sampleProp].map((a) =>
        dispatch(smartwayApi.endpoints.fetchEntity.initiate({ entity: entityType, id: a.id }))
      )
      const results = await Promise.all(promises)
      setSelectedAccommodations(results.map((r) => r.data))
      setAccommodationsLoading(false)
    }
    if (entity && entity[sampleProp] && entity[sampleProp].length) {
      const sortedOffsite = [...entity[sampleProp]].sort().join(',')
      const sortedSelected =
        selectedAccommodations && selectedAccommodations.length
          ? [...selectedAccommodations]
              .map((a) => a.id)
              .sort()
              .join(',')
          : ''
      if (!selectedAccommodations || sortedOffsite !== sortedSelected) {
        fetchFullAccommodations()
      } else {
        setAccommodationsLoading(false)
      }
    } else {
      setSelectedAccommodations(null)
      setAccommodationsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, entity, venueType])

  return (
    <MDBox>
      <MDTypography variant="h3" fontWeight="light">
        Premade package for <span style={{ fontWeight: 500 }}>{venue.name}</span>
      </MDTypography>
      <MDTypography variant="body" fontWeight="light">
        Please be aware that you cannot change the venue for this package as it would change the
        entire nature of it.
      </MDTypography>
      {editingMode ? (
        <MDBox display="flex" justifyContent="flex-end" mt={2}>
          <a
            href={`https://listings.smartway.work/premade-offsites/${entity.slug}`}
            target="_blank"
            rel="noreferrer">
            <MDButton variant="outlined" color="dark" size="small">
              See the public page
              <Icon sx={{ ml: 1 }}>open_in_new</Icon>
            </MDButton>
          </a>
        </MDBox>
      ) : null}
      <Grid container spacing={3} mt={0}>
        {editingMode ? (
          <Grid item xs={4}>
            <ImageCarousel
              id={entity.id}
              entity="package"
              images={entity.images}
              onChange={handleImageChange}
            />
          </Grid>
        ) : null}
        <Grid item xs={editingMode ? 8 : 12}>
          <PackageForm
            entity={entity}
            venueType={venueType}
            venue={venue}
            editingMode={editingMode}
            onSubmit={handleOnSubmit}
            onPublishChange={onPublishChange}
            onCancel={onCancel}
          />
          {editingMode ? (
            <Card sx={{ mt: 4 }}>
              <MDBox p={4} width="100%">
                {accommodationsLoading ? (
                  <Grid container spacing={4}>
                    {Array.from(new Array(4)).map((_, index) => (
                      <Grid item xs={3} key={`cards-skeleton-${index}`}>
                        <Skeleton animation="wave" variant="rounded" width="100%" height={136} />
                      </Grid>
                    ))}
                  </Grid>
                ) : entity?.id ? (
                  <MDBox>
                    <MDBox mb={3}>
                      <MDTypography variant="h5" fontWeight="medium">
                        Sample accommodations
                      </MDTypography>
                      <MDTypography variant="body2" fontWeight="light">
                        Show some nice places to stay in {venue.name}
                      </MDTypography>
                    </MDBox>
                    <Grid container spacing={4}>
                      <Grid item xs={4}>
                        <AddAccommodationCard onAdd={handleAddAccommodations} />
                      </Grid>
                      {selectedAccommodations &&
                        selectedAccommodations.map((accommodation) => {
                          return (
                            <Grid item xs={4} key={`selected-accommodation-${accommodation.id}`}>
                              <AccommodationsSmallCard
                                {...accommodation}
                                iconStyles={iconStyles}
                                onRemove={handleRemoveAccommodation}
                              />
                            </Grid>
                          )
                        })}
                    </Grid>
                  </MDBox>
                ) : null}
              </MDBox>
            </Card>
          ) : null}
        </Grid>
      </Grid>
      <SampleAccommodationsDialog
        open={accommodationDialogOpen}
        setOpen={() => setAccommodationDialogOpen(true)}
        onClose={() => setAccommodationDialogOpen(false)}
        venue={entity?.all_in_venue || entity?.town}
        venueType={entity?.all_in_venue ? 'allInVenue' : 'town'}
        selected={selectedAccommodations}
        iconStyles={iconStyles}
        onSave={handleSaveAccommodations}
      />
      <ConfirmationDialog
        title={`You've selected agendas that are used in other packages`}
        message="If you confirm, agendas will be cloned so that you can change them later. If it's a mistake, click cancel and it will save the other informations and the agendas not associated with other packages."
        open={cloneConfirmationOpen}
        cancelLabel={'Save'}
        setOpen={setCloneConfirmationOpen}
        onConfirm={handleConfirmCloneAgendas}
        onCancel={handleCancelConfirmation}
      />
    </MDBox>
  )
}

export default PackageFormStep
