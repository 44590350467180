import { useState, useEffect } from 'react'

import { useDispatch } from 'react-redux'

import { smartwayApi } from 'services/api'

import { getInitialFilters, getAppliedFilters } from './filters'
import Filters from 'components/Filters'

import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import Grid from '@mui/material/Grid'
import Icon from '@mui/material/Icon'
import Card from '@mui/material/Card'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'

import { FilterButton } from 'components/Filters/Filters'

import ActivityPickerCard from './ActivityPickerCard'
import CustomActivities from '../CustomActivities'

import coworking from 'assets/illustrations/coworking.svg'
import experience from 'assets/illustrations/experience.svg'
import catering from 'assets/illustrations/catering.svg'
import plenaryHall from 'assets/illustrations/plenary-hall.svg'
import customActivity from 'assets/illustrations/custom-activity.svg'
import noResults from 'assets/illustrations/noresults.svg'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { CardsSkeleton } from './Skeletons'
import { ACTIVITY_TYPES, LOCATION_ACTIVITY_TYPES } from 'utils/events'
import { getActiveFiltersNumber } from 'utils/filters'

const CustomTabPanel = ({ children, tab, value, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={tab !== value}
      id={`tabpanel-${value}`}
      aria-labelledby={`tab-${value}`}
      style={{ position: 'relative' }}
      {...other}>
      {tab === value && <MDBox sx={{ pb: 2 }}>{children}</MDBox>}
    </div>
  )
}

const illustrations = {
  coworking: coworking,
  experience: experience,
  catering: catering,
  plenary_hall: plenaryHall
}

const ActivityPicker = ({
  slot,
  activityType,
  activity,
  customActivities,
  venueType,
  venue,
  company,
  onSaveCustomActivity,
  onActivityChange,
  onDeleteActivity
}) => {
  const dispatch = useDispatch()
  const isActivity = !!activity && !!Object.keys(activity).length
  const [locations, setLocations] = useState([])
  const [foodExperiences, setFoodExperiences] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [selectedActivity, setSelectedActivity] = useState(isActivity ? activity : null)
  const [foodExperiencesAccordionExpanded, setFoodExperiencesAccordionExpanded] = useState(true)

  const [initialFilters, setInitialFilters] = useState({})
  const [appliedFilters, setAppliedFilters] = useState({})
  const [activeFiltersNumber, setActiveFiltersNumber] = useState({})
  const [filtersOpen, setFiltersOpen] = useState(false)
  const [filteredLocations, setFilteredLocations] = useState(locations)

  const handleCardClick = (location, activityTypeOverride = null) => {
    if (selectedActivity && location && selectedActivity.id === location.id) {
      setSelectedActivity(null)
      onActivityChange(null)
    } else {
      let _activityType = activityType
      if (activityTypeOverride) {
        _activityType = ACTIVITY_TYPES.find((a) => a.name === activityTypeOverride)
      }
      setSelectedActivity({ activityType: _activityType, ...location })
      onActivityChange({ activityType: _activityType, ...location })
    }
  }

  const handleSaveCustomActivity = async (values) => {
    await onSaveCustomActivity(values)
  }

  const handleDeleteCustomActivity = (activity) => {
    onDeleteActivity(activity)
  }

  const toggleFilters = () => {
    setFiltersOpen(!filtersOpen)
  }

  const handleApplyFilters = (filters) => {
    const newFilteredLocations = getAppliedFilters(activityType, filters, locations)
    setAppliedFilters(filters)
    setActiveFiltersNumber(getActiveFiltersNumber(filters, initialFilters))
    setFilteredLocations(newFilteredLocations)
    setFiltersOpen(false)
  }

  const handleClearFilters = () => {
    setFiltersOpen(false)
    setFilteredLocations(locations)
    setAppliedFilters({})
    setActiveFiltersNumber(0)
  }

  useEffect(() => {
    const getAllSelectableLocation = async () => {
      try {
        setIsLoading(true)
        const otherPromises = {
          catering: {
            name: ['categories', 'dietary_options', 'experience_categories'],
            promises: [
              smartwayApi.endpoints.fetchEntities.initiate({
                entity: 'locationCategories',
                id: 'catering'
              }),
              smartwayApi.endpoints.fetchEntities.initiate({
                entity: 'dietaryOptions'
              }),
              smartwayApi.endpoints.fetchEntities.initiate({
                entity: 'locationCategories',
                id: 'experience'
              })
            ]
          },
          experience: {
            name: ['categories'],
            promises: [
              smartwayApi.endpoints.fetchEntities.initiate({
                entity: 'locationCategories',
                id: 'experience'
              })
            ]
          },
          coworking: {
            name: ['categories', 'features'],
            promises: [
              smartwayApi.endpoints.fetchEntities.initiate({
                entity: 'locationCategories',
                id: 'coworking'
              }),
              smartwayApi.endpoints.fetchEntities.initiate({
                entity: 'locationFeatures',
                id: 'coworking'
              })
            ]
          },
          plenary_hall: {
            name: ['features'],
            promises: [
              smartwayApi.endpoints.fetchEntities.initiate({
                entity: 'locationFeatures',
                id: 'plenary_hall'
              })
            ]
          },
          workshop: {
            name: [],
            promises: []
          }
        }
        const venueParam = venueType === 'allInVenue' ? { all_in_venue: venue } : { town: venue }
        const [selectableLocations, ...otherResponses] = await Promise.all([
          dispatch(
            smartwayApi.endpoints.fetchAllEntities.initiate({
              entity: activityType.name,
              ...(activityType.name !== 'workshop' ? venueParam : {}),
              page_size: 100,
              published: true
            })
          ),
          ...otherPromises[activityType.name].promises.map((promise) => dispatch(promise))
        ])

        if (selectableLocations.status === 'fulfilled' && selectableLocations.data) {
          let otherData = {}
          if (otherResponses && !!otherResponses.length) {
            otherResponses.forEach((response, index) => {
              otherData[otherPromises[activityType.name].name[index]] = response
            })
          }

          if (activityType.name === 'catering') {
            const experienceCategories = otherData.experience_categories
              ? otherData.experience_categories.data
              : []
            const foodAndDrinkCategory = experienceCategories.find(
              (cat) => cat.key === 'FOOD_DRINK'
            )
            if (foodAndDrinkCategory) {
              const foodDrinkExperiences = await dispatch(
                smartwayApi.endpoints.fetchEntities.initiate({
                  entity: 'experience',
                  ...(venueType === 'allInVenue' ? { all_in_venue: venue } : { town: venue }),
                  categories: foodAndDrinkCategory.id
                })
              )
              if (foodDrinkExperiences && foodDrinkExperiences.data) {
                setFoodExperiences(
                  foodDrinkExperiences.data.results.map((e) => ({
                    ...e,
                    category: foodAndDrinkCategory
                  }))
                )
              }
            }
          }

          const newLocations = selectedActivity
            ? [...selectableLocations.data].sort((a, b) => {
                return a.id === selectedActivity.id ? -1 : 1
              })
            : selectableLocations.data
          const newMappedLocations = newLocations.map((l) => ({
            ...l,
            category: l.category
              ? { ...otherData?.categories?.data.find((a) => a.id === l.category) }
              : null,
            features: l.features
              ? l.features.map((f) => ({ ...otherData?.features?.data.find((a) => a.id === f) }))
              : null,
            categories: l.categories
              ? l.categories.map((c) => ({
                  ...otherData?.categories?.data.find((a) => a.id === c)
                }))
              : null,
            dietary_options: l.dietary_options
              ? l.dietary_options.map((d) => ({
                  ...otherData?.dietary_options?.data.find((a) => a.id === d)
                }))
              : null
          }))
          setLocations(newMappedLocations)
          setFilteredLocations(newMappedLocations)
        }
      } catch (error) {
        console.warn(error)
      } finally {
        setIsLoading(false)
      }
    }
    if (activityType && activityType.isLocation) {
      getAllSelectableLocation()
      setInitialFilters(getInitialFilters(activityType.name))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [venueType, venue, dispatch, activityType])

  return activityType ? (
    <MDBox>
      {LOCATION_ACTIVITY_TYPES.map((entity, index) => {
        return (
          <CustomTabPanel key={index} tab={activityType.name} value={entity}>
            <MDBox
              sx={{
                position: 'sticky',
                top: '-24px',
                pt: 3,
                pb: 2,
                pl: 2,
                marginLeft: '-16px',
                marginRight: '-4px',
                background: 'white',
                zIndex: 11
              }}>
              <MDBox display="flex" justifyContent="space-between">
                <MDTypography>{activityType.label}</MDTypography>
                {initialFilters && Object.keys(initialFilters).length ? (
                  <FilterButton activeFiltersNumber={activeFiltersNumber}>
                    <MDButton color="dark" variant="outlined" onClick={toggleFilters}>
                      Filters &nbsp;<Icon>tunesharp</Icon>
                    </MDButton>
                  </FilterButton>
                ) : null}
              </MDBox>
              {initialFilters && Object.keys(initialFilters).length && filtersOpen ? (
                <MDBox
                  pt={2}
                  px={2}
                  mr={2}
                  my={3}
                  sx={{
                    backgroundColor: 'grey.100',
                    borderRadius: '12px',
                    boxShadow: '1px 4px 10px -6px #aaa'
                  }}>
                  <Filters
                    id={`${activityType.name}-filters-form`}
                    initialFilters={initialFilters}
                    appliedFilters={appliedFilters}
                    onApply={handleApplyFilters}
                  />
                  <MDBox display="flex" justifyContent="flex-end" width="100%" px={2} pb={2}>
                    <MDButton
                      variant="outlined"
                      color="secondary"
                      size="small"
                      sx={{ padding: '2px 16px', maxHeight: '24px', minHeight: '24px', mr: 1 }}
                      onClick={handleClearFilters}>
                      Reset
                    </MDButton>
                    <MDButton
                      form={`${activityType.name}-filters-form`}
                      type="submit"
                      variant="contained"
                      size="small"
                      sx={{ padding: '2px 16px', maxHeight: '24px', minHeight: '24px' }}
                      color="primary">
                      Apply
                    </MDButton>
                  </MDBox>
                </MDBox>
              ) : null}
            </MDBox>
            <Grid
              container
              spacing={3}
              mt={2}
              sx={
                !!selectedActivity
                  ? {
                      '.activity-picker-card:not(.selected):not(:hover)': {
                        backgroundColor: 'rgba(200, 200, 200, 0.2)',
                        boxShadow: 'none'
                      }
                    }
                  : {}
              }>
              {foodExperiences && foodExperiences.length ? (
                <MDBox my={4} ml={3} width="100%">
                  <Card sx={{ backgroundColor: '#f4f4f4' }}>
                    <Accordion
                      sx={{ backgroundColor: '#f4f4f4' }}
                      expanded={foodExperiencesAccordionExpanded}
                      onChange={() =>
                        setFoodExperiencesAccordionExpanded(!foodExperiencesAccordionExpanded)
                      }>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <MDTypography variant="h6" fontWeight="medium">
                          Exclusive experiences which includes food & drink
                        </MDTypography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={2} my={1}>
                          {foodExperiences.map((experience, index) => (
                            <Grid key={`experience-card-${index}`} item xs={12} md={4} sm={6}>
                              <ActivityPickerCard
                                entity="experience"
                                location={experience}
                                illustrations={illustrations}
                                selected={selectedActivity && selectedActivity.id === experience.id}
                                onCardClick={(location) => handleCardClick(location, 'experience')}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Card>
                </MDBox>
              ) : null}
              {isLoading ? (
                <CardsSkeleton />
              ) : filteredLocations && !!filteredLocations.length ? (
                filteredLocations.map((location, index) => (
                  <Grid key={`card-${index}`} item xs={12} sm={6} md={4}>
                    <ActivityPickerCard
                      entity={entity}
                      location={location}
                      illustrations={illustrations}
                      selected={selectedActivity && selectedActivity.id === location.id}
                      onCardClick={handleCardClick}
                    />
                  </Grid>
                ))
              ) : (
                <Grid item xs={12}>
                  <MDBox
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center">
                    <MDBox
                      component="img"
                      src={noResults}
                      alt="no results"
                      width="100%"
                      maxHeight={200}
                      mb={3}
                    />
                    <MDTypography variant="body">No results found!</MDTypography>
                  </MDBox>
                </Grid>
              )}
            </Grid>
          </CustomTabPanel>
        )
      })}
      {!!company ? (
        <CustomTabPanel tab={activityType.name} value="CUSTOM">
          <CustomActivities
            slot={slot}
            venueType={venueType}
            venue={venue}
            selectedActivity={selectedActivity}
            activities={customActivities}
            illustration={customActivity}
            onSaveCustomActivity={handleSaveCustomActivity}
            onActivityChange={handleCardClick}
            onDeleteActivity={handleDeleteCustomActivity}
          />
        </CustomTabPanel>
      ) : null}
    </MDBox>
  ) : null
}

export default ActivityPicker
