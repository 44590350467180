import { capitalizeFirstLetter } from 'utils/functions'

const getGeneralCommunicationSchema = ({ profile, parent }) => {
  const company = profile && profile.company
  const collectionName = company ? `${company.name}-${company.id}` : ''
  return {
    id: 'general-communication-form',
    title: 'General communication',
    sx: {
      marginLeft: '-12px',
      marginBottom: '-12px',
      marginRight: '12px',
      padding: '24px',
      backgroundColor: 'grey.100',
      borderRadius: '8px'
    },
    fieldsets: [
      {
        id: 'general',
        fields: [ 
          {
            type: 'text',
            label: `${capitalizeFirstLetter(parent)} Name`,
            name: 'title',
            required: true,
            validationType: 'string',
            validations: [
              {
                type: 'required',
                params: ['This field is required']
              }
            ],
            grid: { xs: 12 },
          },
          {
            type: 'textarea',
            label: 'Welcome text / Payoff',
            name: 'welcome_text',
            validationType: 'string',
            validations: null,
            maxLength: 700,
            grid: { xs: 12 },
            helperText: 'Max. 700 characters',
          },
          {
            type: 'textarea',
            label: 'How to arrive on the offsite',
            name: 'transport_description',
            validationType: 'string',
            validations: null,
            helperText: 'Write here what are the options to arrive on the offsite',
            grid: { xs: 12 },
          },
          {
            type: 'file',
            label: 'Logo of the event',
            name: 'event_logo',
            validationType: 'mixed',
            validations: [
              {
                type: 'fileSize',
                params: ['File too large', '5480000']
              },
              {
                type: 'fileFormat',
                params: ['Unsupported Format', ['image/jpg', 'image/jpeg', 'image/gif', 'image/png']]
              },
              {
                type: 'nullable'
              },
            ],
            accept: 'image/png,image/jpeg,image/jpg',
            showPreview: true,
            grid: { xs: 12, md: 6, mt: 2 },
          },
          {
            type: 'file',
            label: 'Logo of the event (inverted colors)',
            name: 'event_logo_negative',
            validationType: 'mixed',
            validations: [
              {
                type: 'fileSize',
                params: ['File too large', '5480000']
              },
              {
                type: 'fileFormat',
                params: ['Unsupported Format', ['image/jpg', 'image/jpeg', 'image/gif', 'image/png']]
              },
              {
                type: 'nullable'
              },
            ],
            accept: 'image/png,image/jpeg,image/jpg',
            showPreview: true,
            grid: { xs: 12, md: 6, mt: 2 },
          },
          {
            type: 'video',
            name: 'video',
            label: 'Video for your event',
            validationType: 'string',
            placeholder: 'Upload a video',
            collectionName,
            containerStyles: {
              minWidth: '380px'
            },
            validations: [
              {
                type: 'fileSize',
                params: ['File too large', '104860000']
              },
              // {
              //   type: 'fileFormat',
              //   params: ['Unsupported Format', ['image/jpg', 'image/jpeg', 'image/gif', 'image/png']]
              // },
              {
                type: 'nullable'
              }
            ],
            maxSize: 104860000,
            accept: 'video/mp4',
            grid: { xs: 12, md: 6 },
          }
        ],
      },
    ],
    submitButton: {
      label: 'Save',
      color: 'dark',
      fullWidth: false
    },
    cancelButton: null
  }
}
const getCommunicationSchema = ({ handleGenerateClick }) => {
  
  return {
    id: 'definition-step-form',
    fieldsets: [
     
      {
        id: 'accommodation-description',
        title: 'Accommodation description',
        subtitle: 'Write something to introduce the chosen accommodations',
        fields: [
          {
            type: 'textarea',
            name: 'accommodations_description',
            validationType: 'string',
            validations: null,
            
          },
        ]
      },
      {
        id: 'agenda-description',
        title: 'Agenda description',
        subtitle: 'Write or generate a text to describe the agenda of the offsite',
        fields: [
          {
            type: 'textarea',
            name: 'agenda_description',
            validationType: 'string',
            validations: null,
            insideCta: {
              label: 'Generate with AI',
              icon: 'auto_awesome',
              variant: 'outlined',
              color: 'primary',
              size: 'small',
              onClick: handleGenerateClick,

            },
            grid: { xs: 12 },
          },
        ]
      },
      {
        id: 'experiences-description',
        title: 'Experiences description',
        subtitle: 'Write or generate a text to describe the experiences of the offsite',
        fields: [
          {
            type: 'textarea',
            name: 'experiences_description',
            validationType: 'string',
            validations: null,
            insideCta: {
              label: 'Generate with AI',
              icon: 'auto_awesome',
              variant: 'outlined',
              color: 'primary',
              size: 'small',
              onClick: handleGenerateClick,
            },
            grid: { xs: 12 },
          },
        ]
      }
    ],
    submitButton: null
  }
}



export {
  getCommunicationSchema,
  getGeneralCommunicationSchema
}
