import { Suspense, useState, useEffect } from 'react'

import { isPast, addDays } from 'date-fns'

import {
  Await,
  Link,
  useLoaderData,
  useSubmit,
  useSearchParams
} from 'react-router-dom'

import { useDispatch } from 'react-redux'
import { notify } from 'store/reducers/UISlice'

import ButtonGroup from '@mui/material/ButtonGroup'
import Grid from '@mui/material/Grid'
import Icon from '@mui/material/Icon'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

import MDButton from 'components/MDButton'
import MDBox from 'components/MDBox'
import ConfirmationDialog from 'components/Dialogs/ConfirmationDialog'
import { CardsSkeleton, FiltersButtonSkeleton } from './Skeletons'

import OffsitesList from './OffsitesList'

import {
  convertQueryStringToFilters,
  getActiveFiltersNumber
} from 'utils/filters'
import FiltersDialog from 'components/Dialogs/FiltersDialog'
import { FilterButton } from 'components/Filters/Filters'

const OffsiteListPage = () => {
  const { results, initialFilters, key: loaderDataKey } = useLoaderData()
  const [searchParams, setSearchParams] = useSearchParams()
  const dispatch = useDispatch()
  const submit = useSubmit()
  const [view, setView] = useState('grid')
  const [activeTab, setActiveTab] = useState(searchParams.get('is_past') && searchParams.get('is_past') === 'true' ? 1 : 0)
  const [delConfirmationOpen, setDelConfirmationOpen] = useState(false)
  const [markedForDel, setMarkedForDel] = useState(null)
  const [filtersDialogOpen, setFiltersDialogOpen] = useState(false)
  const [activeFiltersNumber, setActiveFiltersNumber] = useState(0)
  const [appliedFilters, setAppliedFilters] = useState({})

  const openFiltersDialog = () => setFiltersDialogOpen(true)
  const closeFiltersDialog = () => setFiltersDialogOpen(false)

  const handleConfirmDeleteOffsite = () => {
    submit(
      { intent: 'delete', id: markedForDel },
      { method: 'delete', encType: 'application/json' }
    )
  }

  const handleCancelConfirmation = () => {
    setDelConfirmationOpen(false)
    setMarkedForDel(null)
  }

  const handleDeleteOffsite = (id) => {
    setDelConfirmationOpen(true)
    setMarkedForDel(id)
  }

  const handleApplyFilters = (activeFilters) => {
    closeFiltersDialog()
    submit(
      { intent: 'filters', activeFilters, initialFilters },
      { method: 'POST', encType: 'application/json' }
    )
  }

  const handleTabChange = (event, tabIndex) => {
    const view = event.target.id
    setActiveTab(tabIndex)
    submit(
      { intent: 'filters', activeFilters: { is_past: view !== 'upcoming' }, initialFilters },
      { method: 'POST', encType: 'application/json' }
    )
  }

  useEffect(() => {
    if (searchParams.get('deleted')) {
      const newSearchParams = {}
      for (const [key, value] of searchParams.entries()) {
        if (key !== 'deleted') {
          newSearchParams[key] = value
        }
      }
      setSearchParams(newSearchParams)
      dispatch(
        notify({
          type: 'success',
          title: 'All right!',
          message: `Offsite deleted successfully`,
          anchorOrigin: { vertical: 'top', horizontal: 'right' }
        })
      )
    }
  }, [dispatch, searchParams, setSearchParams])

  useEffect(() => {
    if (!!initialFilters) {
      const applied = convertQueryStringToFilters(searchParams, initialFilters)
      const activeFiltersNumber = getActiveFiltersNumber(applied, initialFilters)
      setAppliedFilters(applied)
      setActiveFiltersNumber(activeFiltersNumber)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, initialFilters])

  return (
    <>
      <ConfirmationDialog
        title={`Are you sure you want to delete this offsite?`}
        message="You cannot undo this action."
        open={delConfirmationOpen}
        setOpen={setDelConfirmationOpen}
        onConfirm={handleConfirmDeleteOffsite}
        onCancel={handleCancelConfirmation}
      />
      <Suspense>
        <Await resolve={initialFilters}>
          {(initialFilters) => (
            <FiltersDialog
              open={filtersDialogOpen}
              initialFilters={initialFilters}
              appliedFilters={appliedFilters}
              searchParams={searchParams}
              onApply={handleApplyFilters}
              onClose={closeFiltersDialog}
            />
          )}
        </Await>
      </Suspense>
      <MDBox sx={{ marginInline: 'auto', mb: 6, minHeight: { xs: '100vh', md: 'auto' } }}>
        <Grid mt={6} container justifyContent="space-between">
          <Suspense fallback={<FiltersButtonSkeleton />}>
            <Await resolve={initialFilters}>
              {() => initialFilters ? (
                <MDBox display="flex" gap={2} alignItems="center">
                  <FilterButton activeFiltersNumber={activeFiltersNumber}>
                    <MDButton
                      color="white"
                      onClick={openFiltersDialog}
                      sx={{
                        border: !!activeFiltersNumber
                          ? '2px solid grey'
                          : '2px solid transparent'
                      }}>
                      Filters &nbsp;<Icon>tunesharp</Icon>
                    </MDButton>
                  </FilterButton>
                  <Tabs value={activeTab} onChange={handleTabChange} aria-label="Upcoming / past offsites" sx={{ '.MuiTab-root': { py: '4px', px: 2}}}>
                    <Tab label="Upcoming" id="upcoming" />
                    <Tab label="Past offsites" id="past"/>
                  </Tabs>
                </MDBox>
              ) : <div></div> }
            </Await>
          </Suspense>
          <MDBox>
            <Link to={`/dashboard/offsites/new`}>
              <MDButton color="primary">
                <Icon>add</Icon>&nbsp; Create an offsite
              </MDButton>
            </Link>
            <ButtonGroup sx={{ ml: 2 }}>
              <MDButton size="small" variant="outlined" color={view === 'grid' ? 'primary' : 'secondary'} onClick={() => setView('grid')}>
                <Icon >grid_view</Icon>
              </MDButton>
              <MDButton size="small" variant="outlined" color={view === 'table' ? 'primary' : 'secondary'} onClick={() => setView('table')}>
                <Icon>view_list</Icon>
              </MDButton>
            </ButtonGroup>
          </MDBox>
        </Grid>
        <Suspense fallback={<CardsSkeleton />} key={loaderDataKey}>
          <Await resolve={results}>
            {({ results, page_number, count }) => {
              const pagination = {
                page: searchParams.get('page') || 1,
                lastPage: page_number || Math.ceil(count / 24) || 1,
                count,
                pageSize: parseInt(searchParams.get('page_size')) || 24
              }
              const offsites = results && results.length ? results.reduce((acc, offsite) => {
                const isPastOffsite = isPast(addDays(new Date(offsite.end_at), 1))
                acc.push({...offsite, isPast: isPastOffsite})
                return acc
              }, []).sort((a, b) => {
                if (a.isPast && !b.isPast) return 1
                if (!a.isPast && b.isPast) return -1
                return new Date(a.start_at).getTime() - new Date(b.start_at).getTime()
              }).map((o) => {
                return {
                  ...o,
                  venue: o.all_in_venue || o.town,
                  venueType: o.all_in_venue ? 'allInVenue' : 'town'
                }
              }) : []

              return (
                <OffsitesList 
                  offsites={offsites}
                  pagination={pagination}
                  searchParams={searchParams}
                  view={view}
                  onDelete={handleDeleteOffsite}
                />
              )
            }}
          </Await>
        </Suspense>
      </MDBox>
     
    </>
  )
}

export default OffsiteListPage
