import { useState, useEffect } from 'react'

import MDBox from 'components/MDBox'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'

import IconButton from '@mui/material/IconButton'
import Icon from '@mui/material/Icon'
import Form from 'components/Form'

import schema from './schemas/custom-activity'
import { getBestFormat } from 'utils/image'

const CustomActivityDialog = ({ title, open, activity, onClose, onSave, onUpdate }) => {
  const image =
    activity && activity.custom_image_formats
      ? getBestFormat(activity.custom_image_formats, 'mobile')
      : ''
  const handleOnClose = () => {
    if (onClose && typeof onClose === 'function') {
      onClose()
      setPreviewURL(null)
    }
  }
  const [previewURL, setPreviewURL] = useState(image)

  const onError = (e) => {
    setPreviewURL('https://via.placeholder.com/640x360.jpg?text=Set%20Image')
  }

  const handleFieldChange = (event) => {
    const { files, name } = event.target
    if (name === 'custom_image') {
      if (files) {
        const reader = new FileReader()
        reader.onload = () => {
          setPreviewURL(reader.result)
        }
        reader.readAsDataURL(files[0])
      } else {
        setPreviewURL(null)
      }
    }
  }

  const handleOnSave = async (_values) => {
    const { __address, links, groups, ...values } = _values
    if (activity && activity.id) {
      if (onUpdate && typeof onUpdate === 'function') {
        await onUpdate({
          ...values,
          id: activity.id,
          custom_image:
            values.custom_image && Array.isArray(values.custom_image)
              ? values.custom_image[0]
              : values.custom_image
        })
        handleOnClose()
      }
    } else {
      if (onSave && typeof onSave === 'function') {
        await onSave({
          ...values,
          custom_image:
            values.custom_image && Array.isArray(values.custom_image)
              ? values.custom_image[0]
              : values.custom_image
        })
        handleOnClose()
      }
    }
  }

  useEffect(() => {
    if (open && activity) {
      setPreviewURL(
        activity && activity.custom_image_formats
          ? getBestFormat(activity.custom_image_formats, 'mobile')
          : ''
      )
    }
  }, [activity, open])

  return (
    <Dialog
      key={open}
      open={open}
      maxWidth="md"
      fullWidth
      sx={{ marginLeft: { xl: '250px' } }}
      onClose={handleOnClose}>
      <DialogTitle>{title}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleOnClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500]
        }}>
        <Icon>close</Icon>
      </IconButton>
      <DialogContent className="dialogContent">
        {previewURL ? (
          <MDBox px={2} display="flex" justifyContent="center">
            <MDBox
              component="img"
              src={previewURL}
              alt={activity && activity.title ? activity.title : 'Custom activity'}
              borderRadius="lg"
              shadow="md"
              position="relative"
              zIndex={1}
              sx={{
                maxWidth: '100%',
                maxHeight: '25vh',
                objectFit: 'contain'
              }}
              onError={onError}
            />
          </MDBox>
        ) : null}
        <Form
          {...schema}
          values={activity}
          watchedFields={['custom_image']}
          onFieldChange={handleFieldChange}
          onSubmit={handleOnSave}
        />
      </DialogContent>
    </Dialog>
  )
}

export default CustomActivityDialog
