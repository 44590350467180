import { needZero, fromMinutesToReadableTime } from './dates'
import { addHours, differenceInMinutes, addMinutes } from 'date-fns'
import { formatInTimeZone, getTimezoneOffset } from 'date-fns-tz'

const ACTIVITY_TYPES_COLORS = {
  catering: {
    backgroundColor: 'rgb(213, 242, 115)'
  },
  coworking: {
    backgroundColor: 'rgb(109, 226, 223)'
  },
  experience: {
    backgroundColor: 'rgb(236, 64, 122)'
  },
  workshop: {
    backgroundColor: 'rgb(236, 64, 122)'
  },
  plenary_hall: {
    backgroundColor: 'rgb(109, 226, 223)'
  },
  CUSTOM: {
    backgroundColor: 'rgb(236, 64, 122)'
  },
  FREE_TIME: {
    backgroundColor: 'rgb(213, 242, 115)'
  },
  LOGISTIC: {
    backgroundColor: 'rgb(213, 242, 115)'
  }
}

export const ACTIVITY_NOTIFICATIONS = {
  BUSINESS_ACTIVITY: {
    title: 'Work session incoming!',
    body: 'Hey, get ready to your upcoming work session. Tap for details'
  },
  EXPERIENTIAL_ACTIVITY: {
    title: 'Experience in 15 mins!',
    body: '{title} is starting soon! Get to the meeting point, will be exciting!'
  },
  TRANSFER: {
    title: 'You have a transfer in 15 mins!',
    body: '{title} Get to the meeting point!'
  }
}

export const ACTIVITY_TYPES = [
  {
    name: 'coworking',
    label: 'Work spaces',
    isLocation: true,
    type: 'BUSINESS_ACTIVITY',
    hasNotification: true
  },
  {
    name: 'experience',
    label: 'Experience',
    isLocation: true,
    type: 'EXPERIENTIAL_ACTIVITY',
    hasNotification: true
  },
  {
    name: 'workshop',
    label: 'Workshops / Team building',
    isLocation: true,
    type: 'EXPERIENTIAL_ACTIVITY',
    hasNotification: true
  },
  {
    name: 'catering',
    label: 'Food & drinks',
    isLocation: true,
    type: 'FOOD_DRINK'
  },
  {
    name: 'plenary_hall',
    label: 'Event spaces',
    isLocation: true,
    type: 'BUSINESS_ACTIVITY',
    hasNotification: true
  },
  {
    name: 'LOGISTIC',
    label: 'Check-in / Check-out',
    type: 'LOGISTIC'
  },
  {
    name: 'TRANSFER',
    label: 'Transfer',
    type: 'TRANSFER',
    hasNotification: true
  },
  {
    name: 'FREE_TIME',
    label: 'Free time',
    type: 'FREE_TIME'
  },
  {
    name: 'CUSTOM',
    label: 'Custom',
    type: 'CUSTOM'
  }
]

export const LOCATION_ACTIVITY_TYPES = ACTIVITY_TYPES.filter(({ isLocation }) => !!isLocation).map(
  ({ name }) => name
)

export const NOTIFICATION_ACTIVITY_TYPES = ACTIVITY_TYPES.filter(
  ({ hasNotification }) => !!hasNotification
).map(({ name }) => name)

export const castStringToValue = (string) => {
  if (typeof string === 'string' && string === 'undefined') {
    return undefined
  }
  return string
}

/**
 * Checks for warnings related to the activity and returns an array of warning objects.
 *
 * @param {Object} activity - The activity object to check for warnings.
 * @param {number} slotMinutesDuration - The duration of the time slot in minutes.
 * @param {number} numParticipants - The number of participants for the activity.
 * @param {boolean} overlapEvents - Indicates if the event overlaps with another one.
 * @param {Function} onClick - Optional onClick function for handling events.
 * @return {Array} An array of warning objects indicating potential issues with the activity.
 */
export const checkActivityWarnings = ({
  activity,
  slotMinutesDuration,
  numParticipants,
  overlapEvents,
  onClick = () => {}
}) => {
  const warnings = []
  if (!activity) {
    return warnings
  }
  const { duration, min_participants, max_participants } = activity
  if (!!duration && duration !== slotMinutesDuration) {
    warnings.push({
      id: 'time',
      icon: 'av_timer',
      text:
        'There could be a change in the duration of the event. Normally this experience lasts around ' +
        fromMinutesToReadableTime(duration, 'h')
    })
  }
  if (
    numParticipants &&
    !isNaN(Number(numParticipants)) &&
    (min_participants || max_participants)
  ) {
    const isLower = min_participants && min_participants > numParticipants
    const isUpper = max_participants && max_participants < numParticipants
    if (isLower || isUpper) {
      warnings.push({
        id: 'participants',
        icon: 'groups',
        text: `The number of participants is ${
          isLower ? 'lower' : 'greater'
        } than the minimum required for this experience (${
          isLower ? min_participants : max_participants
        })`
      })
    }
  }
  if (overlapEvents) {
    warnings.push({
      id: 'overlap',
      icon: 'groups',
      text: `The event overlaps with another one. Please select how the participants will be splitted between the two events.`,
      onClick
    })
  }
  return warnings
}

/**
 * Handles the initial slot duration based on specific conditions.
 *
 * @param {Object} slot - The slot object containing start and end properties.
 * @param {Object} activityType - The type of activity.
 * @param {Object} activity - The activity object with duration information.
 * @return {Object} The updated slot object with adjusted end time and related properties.
 */
export const handleInitialSlotDuration = (slot, activityType, activity) => {
  const { start, end } = slot
  const thirtyMinutes = differenceInMinutes(new Date(end), new Date(start)) === 30
  if (
    thirtyMinutes &&
    activityType.type === 'EXPERIENTIAL_ACTIVITY' &&
    activity.duration &&
    activity.duration !== 30
  ) {
    const newEnd = addMinutes(new Date(end), activity.duration - 30)
    return {
      ...slot,
      end: newEnd,
      endStr: newEnd.toISOString(),
      end_hour: `${needZero(newEnd.getHours())}:${needZero(newEnd.getMinutes())}`
    }
  }
  return slot
}

export default class CalendarSlot {
  constructor({ id, ...slot }) {
    this.slot = {}
    const activityType = slot.location
      ? // Type BUSINESS_ACTIVITY is associated with coworking and plenary_hall
        ACTIVITY_TYPES.find((a) => a.name === slot.location.location_type)
      : slot.type
      ? ACTIVITY_TYPES.find((a) => a.type === slot.type)
      : null
    if (!activityType) {
      console.warn(`Activity type not found for ${slot.title}`)
    } else {
      // console.debug(`ACTVITY_TYPE for ${rest.title} -> ${activityType.name}`)
    }

    if (id) {
      const {
        type,
        location,
        // This prop is the id of the original activity in the company agenda
        // used to create an actual activity inside an offsite agenda
        custom_id,
        custom_image,
        custom_address,
        custom_latitude,
        custom_longitude,
        start_at,
        end_at,
        title,
        description,
        ...rest
      } = slot

      const activityInfos =
        type === 'CUSTOM'
          ? {
              id,
              title,
              description: description || rest.excerpt,
              name: title,
              custom_id,
              custom_image,
              custom_address,
              custom_latitude,
              custom_longitude
            }
          : location

      this.slot = this.createSlot({
        id,
        title,
        description,
        activity: {
          ...activityInfos
        },
        custom_image,
        activityType,
        start: start_at,
        end: end_at,
        ...rest
      })
    } else {
      this.slot = this.createSlot({ ...slot, activityType })
    }
  }

  createSlot(event) {
    const { title, start, end, activity, activityType, extendedProps } = event
    const _start = new Date(start)
    const start_hour = `${needZero(_start.getHours())}:${needZero(_start.getMinutes())}`
    const _end = new Date(end)
    const end_hour = `${needZero(_end.getHours())}:${needZero(_end.getMinutes())}`

    let _activity = activity
    if (!_activity && extendedProps) {
      _activity = extendedProps.activity
    }

    return {
      ...event,
      ...extendedProps,
      extendedProps,
      activity: _activity,
      activityType,
      title: castStringToValue(title),
      start,
      end,
      start_hour,
      end_hour,
      date: start,
      ...(_activity ? ACTIVITY_TYPES_COLORS[activityType.name] : {})
    }
  }

  updateSlotFromEvent({ start, end }) {
    this.slot.start = start
    this.slot.end = end
    this.slot.start_hour = `${needZero(start.getUTCHours())}:${needZero(start.getUTCMinutes())}`
    this.slot.end_hour = `${needZero(end.getUTCHours())}:${needZero(end.getUTCMinutes())}`
  }

  updateSlot(values) {
    const { start_hour, end_hour, activity, activityType } = values
    const originalStart = new Date(this.slot.start)
    const originalEnd = new Date(this.slot.end)

    const originalStartHour = `${needZero(originalStart.getHours())}:${needZero(
      originalStart.getMinutes()
    )}`
    const originalEndHour = `${needZero(originalEnd.getHours())}:${needZero(
      originalEnd.getMinutes()
    )}`

    let newEvent = { ...this.slot }

    if (start_hour && start_hour !== originalStartHour) {
      originalStart.setHours(parseInt(start_hour.split(':')[0]))
      originalStart.setMinutes(parseInt(start_hour.split(':')[1]))
      newEvent = {
        ...newEvent,
        start: originalStart
      }
    }
    if (end_hour && end_hour !== originalEndHour) {
      originalEnd.setHours(parseInt(end_hour.split(':')[0]))
      originalEnd.setMinutes(parseInt(end_hour.split(':')[1]))
      newEvent = {
        ...newEvent,
        end: originalEnd
      }
    }

    if (activityType?.isLocation) {
      const isActivity = activity && activity.id
      const isSlotActivity = this.slot.activity && this.slot.activity.id
      if (isActivity && isSlotActivity && isActivity !== isSlotActivity) {
        values.title = activity.name
      }
    }

    this.slot = {
      ...newEvent,
      ...values
    }
    return this.slot
  }

  /**
   * Checks if the current slot overlaps with any other events in the given list.
   *
   * @param {Array} events - The list of events to check for overlap.
   * @return {void} This function does not return a value. It updates the `overlapEvents` property of the `slot` object.
   */
  checkIsOverlapSlot(events) {
    const { start, end } = this.slot
    const isOverlap = events
      .filter((e) => e.id !== this.slot.id)
      .filter(({ start_at, end_at }) => {
        const slotStartTimestamp = new Date(start).getTime()
        const slotEndTimestamp = new Date(end).getTime()

        const eventStartTimestamp = new Date(start_at).getTime()
        const eventEndTimestamp = new Date(end_at).getTime()

        return slotStartTimestamp >= eventStartTimestamp && slotEndTimestamp <= eventEndTimestamp
      })
    this.slot.overlapEvents = isOverlap && isOverlap.length ? isOverlap : null
  }

  formatToServer() {
    const {
      id,
      activity,
      custom_image,
      start,
      end,
      title,
      description,
      activityType,
      groups,
      links,
      participants,
      meeting_point_name,
      meeting_point_address,
      meeting_point_latitude,
      meeting_point_longitude,
      custom_notification_title,
      custom_notification_body,
    } = this.slot

    let additionalProps = {}
    if (activityType.name === 'CUSTOM') {
      const isExistingSlot = activity && activity.id
      const existingSlotProps = isExistingSlot
        ? {
            custom_id: activity.id
          }
        : {}
      additionalProps = {
        ...activity,
        ...existingSlotProps
      }
      if (isExistingSlot) {
        delete additionalProps.custom_image
      }
    } else if (activity && activity.id && activityType.isLocation) {
      additionalProps = { location: activity.id, location_type: activityType.name }
      if (activityType.name === 'workshop') {
        additionalProps = {
          ...additionalProps,
          ...(groups && groups.length ? { groups } : { groups: [] }),
          ...(links && links.length
            ? { links: typeof links[0] === 'object' ? links.map((link) => link.id) : links }
            : { links: [] })
        }
      }
    } else if (['TRANSFER', 'FREE_TIME', 'LOGISTIC'].includes(activityType.name)) {
      const isFile = custom_image && custom_image instanceof File
      additionalProps = {
        ...(isFile ? { custom_image } : {}),
        ...(meeting_point_name ? { meeting_point_name } : {}),
        ...(meeting_point_address ? { meeting_point_address } : {}),
        ...(meeting_point_latitude ? { meeting_point_latitude } : {}),
        ...(meeting_point_longitude ? { meeting_point_longitude } : {})
      }
    }

    const getDescription = () => {
      if (description) {
        return description.slice(0, 250)
      }
      if (activity && activity.excerpt) {
        return activity.excerpt.slice(0, 250)
      }
      return ''
    }

    const newEvent = {
      ...(id ? { id } : {}),
      start_at: new Date(start).toISOString(),
      end_at: new Date(end).toISOString(),
      type: activityType.type,
      ...additionalProps,
      title: title || activity?.name,
      description: getDescription(),
      ...(participants && participants.length ? { participants } : { participants: [] }),
      ...(additionalProps.groups && additionalProps.groups.length ? {} : { groups: [] }),
      ...(additionalProps.links && additionalProps.links.length ? {} : { links: [] }),
      ...(activityType.hasNotification  ? {
        ...(custom_notification_title ? { custom_notification_title } : {}),
        ...(custom_notification_body ? { custom_notification_body } : {}),
      } : {})
    }
    return newEvent
  }
}
