import { useEffect, Suspense } from 'react'
import {
  useLoaderData,
  useSearchParams,
  Await,
  useSubmit
} from 'react-router-dom'

import { useDispatch } from 'react-redux'
import { notify } from 'store/reducers/UISlice'

import MDBox from 'components/MDBox'
import DataTable from 'components/Tables/DataTable'
import DashboardNavbar from 'components/DashboardNavbar'
import UserCell from 'components/Tables/Cells/UserCell'
import StatusCell from 'components/Tables/Cells/StatusCell'
import { UsersTableSkeleton } from '../Skeletons'

const UsersList = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { results } = useLoaderData()
  const submit = useSubmit()
  const dispatch = useDispatch()

  const handleSearch = (value) => {
    const newSearchParams = {}
    searchParams.delete('page')
    for (const [key, value] of searchParams.entries()) {
      newSearchParams[key] = value
    }
    submit({ ...newSearchParams, search: value }, { method: 'post', encType: 'application/json' })
  }

  const handlePageSizeChange = (pageSize) => {
    const newSearchParams = {}
    for (const [key, value] of searchParams.entries()) {
      newSearchParams[key] = value
    }
    submit({ ...newSearchParams,  page_size: pageSize }, { method: 'post', encType: 'application/json' })
  }

  useEffect(() => {
    if (searchParams.get('deleted')) {
      const newSearchParams = {}
      for (const [key, value] of searchParams.entries()) {
        if (key !== 'deleted') {
          newSearchParams[key] = value
        }
      }
      setSearchParams(newSearchParams)
      dispatch(
        notify({
          type: 'success',
          title: 'All right!',
          message: <p>The user has been deleted successfully.</p>,
          anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
        })
      )
    }
  }, [dispatch, searchParams, setSearchParams])

  return (
    <>
      <Suspense fallback={<UsersTableSkeleton />}>
        <Await resolve={results} errorElement={<h1>Something went wrong</h1>}>
          {(data) => {
            const { count, page_number, results } = data
            const table = {
              columns: [
                { Header: 'user', accessor: 'user' },
                { Header: 'company', accessor: 'company' },
                { Header: 'last online', accessor: 'lastOnline' },
                { Header: 'role', accessor: 'role' },
                { Header: 'status', accessor: 'status' }
              ],
              rows: results.map(
                ({
                  id,
                  is_active,
                  last_login,
                  first_name = 'Mario',
                  last_name = 'Rossi',
                  profile,
                  groups
                }) => {
                  const { avatar_formats, company } = profile
                  const name = `${first_name} ${last_name}`
                  const statusValue = is_active
                    ? 'Active'
                    : 'Pending activation'
                  const statusIcon = is_active
                    ? { color: 'success', name: 'done' }
                    : { color: 'warning', name: 'loop-sharp' }
                  return {
                    user: <UserCell id={id} image={avatar_formats} name={name} />,
                    company: company ? company.name : '',
                    lastOnline: last_login ? new Date(last_login).toLocaleString() : null,
                    role: groups && !!groups.length ? groups.map(({ name }) => name).join(', ') : 'Not defined',
                    status: <StatusCell value={statusValue} icon={statusIcon} />
                  }
                }
              )
            }
            return (
              <MDBox mt={4}>
                <DataTable
                  title="Users"
                  subtitle="View all users. If you’re a company manager you can see only users from your company."
                  canSearch
                  table={table}
                  pagination={{
                    count,
                    page: parseInt(searchParams.get('page')) || 1,
                    lastPage: page_number,
                    pageSize: parseInt(searchParams.get('page_size')) || 10
                  }}
                  onSearch={handleSearch}
                  onPageSizeChange={handlePageSizeChange}
                />
              </MDBox>
            )
          }}
        </Await>
      </Suspense>
    </>
  )
}

export default UsersList
