const getSchema = ({ offsiteId, values = {}, allParticipants }) => {
  const { participants } = values
  const showParticipantsFields =
    allParticipants && Array.isArray(allParticipants) && !!allParticipants.length
  return {
    name: 'additional-information-form',
    id: 'additional-information-form',
    watchedFields: ['participants'],
    fieldsets: [
      ...(showParticipantsFields
        ? [
            {
              title: 'Who can see this information?',
              subtitle: 'This information should be seen by',
              fields: [
                {
                  type: 'select',
                  name: 'participants',
                  label: 'Offsite participants',
                  multiple: true,
                  options: [
                    {
                      label: 'Everybody',
                      value: 'all'
                    },
                    ...allParticipants?.map((participant) => ({
                      label: `${participant.first_name} ${participant.last_name}`,
                      value: participant.id,
                      // disabled: participants?.includes(participant.id)
                    }))
                  ],
                  grid: { xs: 8 }
                }
              ]
            }
          ]
        : []),
      {
        id: 'general',
        title: 'Information',
        sx: {
          mt: 0,
          pt: 0
        },
        fields: [
          {
            type: 'text',
            name: 'title',
            label: 'Information title',
            grid: { xs: 12 },
            validationType: 'string',
            required: true,
            validations: [
              {
                type: 'required',
                params: ['This field is required']
              }
            ]
          },
          {
            type: 'text',
            name: 'subtitle',
            label: 'Information subtitle',
            grid: { xs: 12 },
            validationType: 'string',
            validations: null
          },
          {
            type: 'text',
            name: 'category',
            label: 'Information category',
            grid: { xs: 12 },
            validationType: 'string',
            validations: null,
            helperText: 'It will appear as the title of the tab in the app'
          },
          {
            type: 'textarea',
            name: 'description',
            label: 'Information body',
            grid: { xs: 12 },
            validationType: 'string',
            required: true,
            validations: [
              {
                type: 'required',
                params: ['This field is required']
              }
            ]
          },
          {
            type: 'text',
            name: 'button_url',
            label: 'External link',
            grid: { xs: 12 },
            validationType: 'string',
            validations: null,
          },
          {
            type: 'text',
            name: 'phone',
            label: 'Phone number',
            grid: { xs: 12 },
            validationType: 'string',
            validations: null,
          },
          {
            type: 'bunny-image',
            name: 'url',
            label: 'Image',
            offsiteId,
            grid: { xs: 8 },
            validationType: 'string',
            validations: [
              {
                type: 'fileSize',
                params: ['File too large', '104860000']
              },
              {
                type: 'fileFormat',
                params: ['Unsupported Format', ['image/jpg', 'image/jpeg', 'image/png']]
              },
              {
                type: 'nullable'
              }
            ],
            maxSize: 104860000,
            accept: '.jpg, .jpeg, .png'
          }
        ]
      },
     
    ]
  }
}

export default getSchema