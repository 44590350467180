const getSchema = ({ editingMode, entity, venueType, venue, handleNoResults, onPublishChange }) => {

  const publishButton = null
  // const publishButton =  entity.id ? entity.published_at ? {
  //   label: 'Unpublish',
  //   color: 'warning',
  //   tooltip: 'By unpublishing, you will not be able to see the public page anymore',
  //   icon: 'visibility_off',
  //   onClick: () => onPublishChange(false, entity)
  // } : {
  //   label: 'Publish',
  //   color: 'success',
  //   tooltip: 'Be sure to have saved before publishing',
  //   icon: 'rocket_launch',
  //   onClick: () => onPublishChange(true, entity)
  // } : null

  return {
    id: 'package-form',
    title: editingMode ? entity.title : `New package for ${venue.name}`,
    ctasPosition: 'top',
    fieldsets: [
      {
        id: 'general',
        fields: [
          {
            type: 'text',
            name: 'title',
            label: 'Title',
            required: true,
            validationType: 'string',
            validations: [
              {
                type: 'required',
                params: ['This field is required']
              }
            ]
          }, 
          {
            type: 'textarea',
            label: 'Excerpt',
            name: 'excerpt',
            validationType: 'string',
            validations: null,
            grid: { xs: 12 },
          },
          {
            type: 'textarea',
            label: 'Description',
            name: 'description',
            validationType: 'string',
            validations: null,
            grid: { xs: 12 },
          },
          {
            type: 'textarea',
            label: 'Sample accommodations description',
            name: 'sample_accommodations_description',
            validationType: 'string',
            validations: null,
            grid: { xs: 12 },
            helperText: 'If empty there will be a default description'
          },

          {
            type: 'number',
            name: 'min_participants',
            label: 'Num. min. participants',
            validationType: 'number',
            validations: null,
            grid: { xs: 6 },
          },
          {
            type: 'number',
            name: 'max_participants',
            label: 'Num. max. participants',
            validationType: 'number',
            validations: null,
            grid: { xs: 6 },
          },
          {
            type: 'select',
            multiple: true,
            name: 'agendas',
            label: 'Agendas',
            validationType: 'array',
            validations: null,
            labelProp: 'selectTitle',
            valueProp: 'id',
            link: '/dashboard/agendas/{value}',
            onNoResults: handleNoResults,
            optionsGetter: { 
              endpoints: [
                {
                  entity: 'agenda',
                  params: {
                    is_template: true,
                    transformResponse: true,
                    ...(venueType === 'town' ? { town: venue.id } : { all_in_venue: venue.id }),
                  }
                }
              ]
            },
            grid: { xs: 6 },
          },
          {
            type: 'select',
            multiple: true,
            name: 'categories',
            label: 'Categories *',
            validationType: 'array',
            validations: null,
            labelProp: 'name',
            valueProp: 'id',
            optionsGetter: { 
              endpoints: [
                {
                  entity: 'packageCategories',
                }
              ]
            },
            grid: { xs: 6 },
          },
          {
            type: 'video',
            name: 'video',
            label: 'Upload a video',
            validationType: 'string',
            placeholder: 'Upload a video',
            collectionName: 'package',
            containerStyles: {
              minWidth: '500px'
            },
            validations: [
              {
                type: 'fileSize',
                params: ['File too large', '104860000']
              },
              // {
              //   type: 'fileFormat',
              //   params: ['Unsupported Format', ['image/jpg', 'image/jpeg', 'image/gif', 'image/png']]
              // },
              {
                type: 'nullable'
              }
            ],
            maxSize: 104860000,
            accept: 'video/mp4'
          },
        ]
      }
    ],
    submitButton: {
      label: 'Save',
      icon: 'save'
    },
    cancelButton: {
      label: 'Back'
    },
    publishButton
  }
}

export default getSchema