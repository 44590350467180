import { useState } from 'react'

import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import { format, differenceInDays } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'

import CalendarSlot, { ACTIVITY_TYPES, LOCATION_ACTIVITY_TYPES } from 'utils/events'
import { useActivities } from './useActivities'
import filtersSchema from './filters-schema'

import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import MDBox from 'components/MDBox'

import Form from 'components/Form/Form'
import AgendaSlot from './AgendaSlot'
import ActivityDialog from 'components/Dialogs/ActivityDialog'
import ActivityParticipantsDialog from 'components/Dialogs/ActivityParticipantsDialog'

import { AgendaSkeleton } from './Skeleton'

const AgendaBuilder = ({
  agendaId,
  venueType,
  venue,
  editable = true,
  durationDays,
  participants = null,
  profile = {},
  showCustomActivities = false,
  actionsSlot = null,
  onEventResize,
  onSlotSelection,
  onSlotSave,
  onSlotRemove,
  onSaveCustomActivity,
  onDeleteCustomActivity
}) => {
  const [activityDialogOpen, setActivityDialogOpen] = useState(false)
  const [participantsDialogOpen, setParticipantsDialogOpen] = useState(false)
  const schema = filtersSchema()

  const [currentSlot, setCurrentSlot] = useState(null)
  const {
    isLoading,
    isTemplate,
    slots,
    initialDate,
    agendaInfos,
    customActivities,
    cloneCustomActivity,
    saveActivity,
    saveCustomActivity,
    deleteActivity,
    deleteCustomActivity,
    refreshActivities,
    filterActivities,
  } = useActivities({ agendaId })

  // const TIMEZONE = venue.timezone || 'Europe/Rome'

  // slots.forEach(({ slot: { title, start, end, start_hour, end_hour }}) => {
  //   console.table({
  //     title,
  //     start,
  //     end,
  //     start_hour,
  //     end_hour
  //   })
  // })

  const handleSlotClick = ({ event }) => {
    const selectedSlot = slots.find(({ slot }) => slot.id === Number(event.id))
    setCurrentSlot(selectedSlot)
    setTimeout(() => {
      setActivityDialogOpen(true)
    })
  }

  const handleEventResize = async (event) => {
    const calendarSlot = slots.find(({ slot }) => slot.id === Number(event.id))
    calendarSlot.updateSlotFromEvent(event)
    const formattedEvent = calendarSlot.formatToServer()
    await saveActivity(formattedEvent)
    if (onEventResize && typeof onEventResize === 'function') {
      onEventResize(event)
    }
  }

  const handleSlotSelection = (event) => {
    const calendarSlot = new CalendarSlot(event)
    setCurrentSlot(calendarSlot)
    setActivityDialogOpen(true)
    if (onSlotSelection && typeof onSlotSelection === 'function') {
      onSlotSelection(event)
    }
  }

  const handleSlotSave = async (payload) => {
    setActivityDialogOpen(false)
    setCurrentSlot(null)
    currentSlot.updateSlot(payload)
    const formattedEvent = currentSlot.formatToServer()
    const { activityType, id, custom_id } = currentSlot.slot
    if (activityType.name === 'CUSTOM' && id === custom_id) {
      cloneCustomActivity(formattedEvent)
    } else {
      // Non location type could have a custom image uploaded
      // const bodyType = LOCATION_ACTIVITY_TYPES.includes(activityType.name) ? 'json' : 'formData'
      await saveActivity(formattedEvent)
    }
    if (onSlotSave && typeof onSlotSave === 'function') {
      onSlotSave()
    }
  }

  const handleOnRefresh = async () => {
    await refreshActivities()
  }

  const handleUpdateCurrentSlot = (slot) => {
    setCurrentSlot(slot)
  }

  const handleSaveParticipants = async (participantsBySlots) => {
    const promises = Object.keys(participantsBySlots).map((slotId) => {
      return saveActivity(
        {
          id: slotId,
          participants: participantsBySlots[slotId]
        }
      )
    })
    await Promise.all(promises)
    setParticipantsDialogOpen(false)
  }

  const handleRemoveSlot = async (activityId) => {
    const response = await deleteActivity(activityId)
    if (onSlotRemove && typeof onSlotRemove === 'function') {
      onSlotRemove(activityId, response)
    }
  }

  const handleCloseActivity = () => {
    setActivityDialogOpen(false)
    setCurrentSlot(null)
    refreshActivities()
  }

  const handleCloseParticipants = () => {
    setParticipantsDialogOpen(false)
    setCurrentSlot(null)
  }

  const handleSaveCustomActivity = (slot) => {
    saveCustomActivity(slot)
    if (slot && onSaveCustomActivity && typeof onSaveCustomActivity === 'function') {
      onSaveCustomActivity(slot)
    }
  }

  const handleDeleteCustomActivity = () => {
    deleteCustomActivity(currentSlot.slot.id)
    if (currentSlot && onDeleteCustomActivity && typeof onDeleteCustomActivity === 'function') {
      onDeleteCustomActivity(currentSlot)
    }
  }

  const handleOverlapClick = (id) => {
    const selectedSlot = slots.find(({ slot }) => slot.id === Number(id))
    setCurrentSlot(selectedSlot)
    setParticipantsDialogOpen(true)
  }

  const handleFilterChange = (event) => {
    const { target: { name, value }} = event
    if (name === 'types') {
      filterActivities({ types: value })
    } 
  }

  const renderEventContent = (eventInfo) => {
    const { loading } = eventInfo.event.extendedProps
    return (
      <AgendaSlot
        slot={eventInfo}
        numParticipants={participants ? participants.length : null}
        loading={loading}
        onOverlapClick={handleOverlapClick}
        onRemove={handleRemoveSlot}
      />
    )
  }

  return isLoading ? (
    <AgendaSkeleton days={durationDays} />
  ) : initialDate ? (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} md={3} xxl={2}>
          <MDBox py={1}>
            {actionsSlot}
            <MDBox sx={{ backgroundColor: 'grey.200'}}>
              <Form {...schema} onFieldChange={handleFilterChange} values={{ types: Array.from(new Set(ACTIVITY_TYPES.map((t) => t.type))) }}/>
            </MDBox>
          </MDBox>
        </Grid>
        <Grid 
          item 
          xs={12} 
          md={9}
          xxl={10}
          key={initialDate} 
          sx={{
            height: '100%',
            minHeight: { xs: '450px', md: 'auto' },
            ':root': {
              '--fc-border-color': '#F9F9F9 !important'
            },
            '.fc.fc-media-screen': {
              height: '100%',
              minHeight: { xs: '450px', md: 'auto' }
            },
            '.fc .fc-popover': {
              zIndex: '100 !important'
            },
            '.fc-scroller.fc-scroller-liquid-absolute': {
              '::-webkit-scrollbar': {
                width: '4px'
              },
              '::-webkit-scrollbar-track': {
                backgroundColor: '#eee',
                borderRadius: '2px'
              },
              '::-webkit-scrollbar-thumb': {
                backgroundColor: '#ccc',
                borderRadius: '2px'
              }
            },
            '.fc table': {
              fontSize: '1rem'
            },
            '.fc-theme-standard .fc-scrollgrid': {
              border: 'none'
            },
            '.fc .fc-timegrid-col.fc-day-today': {
              backgroundColor: 'transparent !important'
            },
            '.fc .fc-scrollgrid-section-liquid > td': {
              border: 'none'
            },
            '.fc-event-main': {
              padding: '0px'
            },
            '.fc-event, .fc-event-mirror': {
              fontWeight: 500,
              borderRadius: '6px',
              padding: '0px',
              margin: '0px',
              border: 'none',
              '&.has-image': {
                '&::after': {
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  width: '100%',
                  height: '100%',
                  borderRadius: '6px',
                  background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.25) 62%, rgb(0, 0, 0, 0.5) 80%)'
                }
              }
            },
            '.fc-timegrid-event-harness.agenda-slot--overlap': {
              zIndex: '2 !important'
            }
          }}>
          <FullCalendar
            plugins={[timeGridPlugin, interactionPlugin]}
            initialView="timeGridFourDay"
            headerToolbar={null}
            editable={editable}
            selectable={editable}
            selectMirror
            // timeZone={TIMEZONE}
            select={handleSlotSelection}
            slotEventOverlap={false}
            events={slots && !!slots.length ? slots.map(({ slot }) => slot) : null}
            eventContent={renderEventContent}
            eventClick={handleSlotClick}
            eventResize={({ event }) => {
              handleEventResize(event)
            }}
            eventDrop={({ event }) => {
              handleEventResize(event)
            }}
            initialDate={initialDate}
            views={{
              timeGridFourDay: {
                type: 'timeGrid',
                duration: { days: durationDays },
                allDaySlot: false,
                eventMaxStack: 2,
                slotMinTime: '00:00:00',
                dayHeaderContent: ({ date }) => {
                  if (isTemplate) {
                    return <p>Day {differenceInDays(new Date(date), initialDate) + 1}</p>
                  }
                  const day = format(new Date(date), 'EEE dd')
                  return <p>{day}</p>
                }
              }
            }}
          />
        </Grid>
      </Grid>
      {currentSlot ? (
        <>
          {/** We can handle participants per activity when the agenda is in an actual offsite */}
          {agendaInfos && agendaInfos.offsite ? (
            <ActivityParticipantsDialog
              open={participantsDialogOpen}
              slot={currentSlot.slot}
              participants={participants}
              onSave={handleSaveParticipants}
              onClose={handleCloseParticipants}
            />
          ) : null}
          <ActivityDialog
            venueType={venueType}
            venue={venue}
            agendaId={agendaId}
            agendaInfos={agendaInfos}
            open={activityDialogOpen}
            calendarSlot={currentSlot}
            slot={currentSlot.slot}
            activity={currentSlot.slot ? currentSlot.slot.activity : null}
            activityType={currentSlot.slot ? currentSlot.slot.activityType : null}
            showCustomActivities={showCustomActivities}
            customActivities={customActivities}
            profile={profile}
            participants={participants}
            onUpdateCurrentSlot={handleUpdateCurrentSlot}
            onSave={handleSlotSave}
            onSaveCustomActivity={handleSaveCustomActivity}
            onDelete={handleDeleteCustomActivity}
            onClose={handleCloseActivity}
            onRefresh={handleOnRefresh}
          />
        </>
      ) : null}
    </>
  ) : null
}

export default AgendaBuilder
