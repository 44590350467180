const getSchema = ({ values, offsiteId }) => {
  const { scheduled } = values
  return {
    name: 'notification-form',
    id: 'notification-form',
    watchedFields: ['scheduled'],
    fieldsets: [
      {
        id: 'general',
        fields: [
          {
            type: 'text',
            name: 'title',
            label: 'Notification title',
            grid: { xs: 12 },
            validationType: 'string',
            required: true,
            validations: [
              {
                type: 'required',
                params: ['This field is required']
              }
            ]
          },
          {
            type: 'text',
            name: 'body',
            label: 'Notification message',
            grid: { xs: 12 },
            validationType: 'string',
            required: true,
            validations: [
              {
                type: 'required',
                params: ['This field is required']
              }
            ]
          },
          {
            type: 'bunny-image',
            name: 'image_url',
            label: 'Image',
            offsiteId,
            grid: { xs: 8 },
            validationType: 'string',
            validations: [
              {
                type: 'fileSize',
                params: ['File too large', '104860000']
              },
              {
                type: 'fileFormat',
                params: ['Unsupported Format', ['image/jpg', 'image/jpeg', 'image/png']]
              },
              {
                type: 'nullable'
              }
            ],
            maxSize: 104860000,
            accept: '.jpg, .jpeg, .png'
          }
          
        ]
      },
      {
        id: 'details',
        title: 'Specify the details of the notification',
        fields: [
          {
            type: 'switch',
            name: 'scheduled',
            label: 'Send notification at a specific time',
            grid: { xs: 12 },
          },
          {
            type: 'datepicker',
            label: 'Send notification at',
            name: 'send_at',
            disabled: !scheduled,
            disablePast: true,
            inputProps: {
              variant: 'outlined'
            },
            timepicker: {
              label: 'Time',
            },
            helperText: 'Notification will be sent at the selected day and time'
          },
          {
            type: 'radiogroup',
            name: 'confirmed',
            wrapperStyles: {
              mt: 4
            },
            label: 'Send this notification to',
            options: [
              {
                label: 'Everyone',
                value: 'everyone'
              },
              {
                label: 'Only confirmed participants',
                value: 'confirmed'
              },
              {
                label: 'Only participants that have not confirmed',
                value: 'not_confirmed'
              }
            ]
          }
        ]
      }
    ]
  }
}

export default getSchema