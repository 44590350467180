import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
// import MDDatepicker from 'components/MDDatePicker'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { PickersDay } from '@mui/x-date-pickers/PickersDay'

export const DatepickerDay = ({ day, ...props }) => {
  return (
    <PickersDay
      sx={{
        '&.MuiPickersDay-root:focus:not(.MuiPickersDay-today)': { backgroundColor: 'primary.main' }
      }}
      {...props}
      day={day}
    />
  )
}

export const getDatepickerStyles = (inputProps) => {
  let standardStyles = {}
  if (inputProps.variant === 'standard') {
    standardStyles = {
      label: {
        transform: 'translate(0px, 16px) scale(1)',
        '&.MuiFormLabel-filled, &.Mui-focused': {
          transform: 'translate(0px, -9px) scale(0.75)'
        }
      }
    }
  }
  return inputProps.variant !== 'outlined'
    ? {
        width: '100%',
        input: {
          paddingLeft: 0
        },
        fieldset: {
          borderWidth: '0px',
          borderRadius: 0,
          borderBottom: '1px solid #d2d6da'
        },
        '.Mui-focused fieldset': {
          borderWidth: '0px !important',
          borderBottom: '2px solid #1a73e8 !important'
        },
        ...standardStyles
      }
    : {}
}
const Datepicker = ({
  name,
  value = null,
  timepicker,
  timezone = 'default',
  onChange,
  errors,
  helperText,
  inputProps = {},
  ...props
}) => {
  const handleChange = (date) => {
    onChange({
      target: {
        name,
        value: new Date(date)
      }
    })
  }

  const handleTimeChange = (time) => {
    const _value = new Date(value.setHours(time.getHours(), time.getMinutes()))
    onChange({
      target: {
        name,
        value: _value
      }
    })
  }

  return (
    <MDBox position="relative">
      <MDBox display="flex" alignItems="center" gap={1}>
        <DatePicker
          name={name}
          value={value || new Date()}
          timezone={timezone}
          {...props}
          onChange={handleChange}
          slots={{
            day: DatepickerDay
          }}
          sx={{ ...getDatepickerStyles(inputProps) }}
          input={{ fullWidth: true, ...inputProps }}
        />
        {!!timepicker ? (
          <TimePicker 
            {...timepicker}
            disabled={props.disabled}
            ampm={false}
            value={value || new Date()} 
            onChange={handleTimeChange} 
          />
        ) : null}
      </MDBox>
      {errors ? (
        <MDBox position="absolute">
          <MDTypography color="error" variant="caption">
            {errors}
          </MDTypography>
        </MDBox>
      ) : null}
      {helperText && !errors ? (
        <MDBox position="absolute" sx={{ top: 'calc(100% + 4px)', lineHeight: 0.8 }}>
          <MDTypography variant="caption">{helperText}</MDTypography>
        </MDBox>
      ) : null}
    </MDBox>
  )
}

export default Datepicker
