import { useState, useEffect } from 'react'

import { smartwayApi } from 'services/api'

import { useDispatch } from 'react-redux'
import { notify } from 'store/reducers/UISlice'

// react-simple-image-viewer components
// import ImageViewer from 'react-simple-image-viewer'

// @mui material components
import Stack from '@mui/material/Stack'
import AddCircleIcon from '@mui/icons-material/AddCircle'
// import Icon from '@mui/material/Icon'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'

// import Spinner from 'components/Spinner'
import EditImageDialog from 'components/Dialogs/EditImageDialog'
import EditableImage from 'components/EditableImage'
// import Image from 'components/Image'
import { getMainImage, getBestFormat } from 'utils/image'

const mapImage = (image) => {
  return { ...image, src: getBestFormat(image.formats, 'desktop') }
}
const mapImages = (images) => {
  return images.map(mapImage)
}

const iconStyles = {
  transitionProperty: 'opacity, transform',
  transitionDuration: '0.3s',
  opacity: 0,
  transform: 'scale(0.8)',
  position: 'absolute',
  zIndex: '10',
  right: '5px',
  color: 'white !important',
  borderRadius: '50%',
  width: '30px',
  height: '30px',
  backgroundColor: 'primary.main',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}

const ImageCarousel = ({ id, entity, images, onChange }) => {
  const dispatch = useDispatch()
  const [mainImage, setMainImage] = useState(images && !!images.length ? getMainImage(images) : {})
  const [mappedImages, setMappedImages] = useState(images ? mapImages([...images]).filter((image) => !image.featured) : [])
  const [editingImage, setEditingImage] = useState(null)
  // const [imgsViewer, setImgsViewer] = useState(false)
  // const [imgsViewerCurrent, setImgsViewerCurrent] = useState(0)
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false)

  // const openImgsViewer = () => setImgsViewer(true)
  // const closeImgsViewer = () => setImgsViewer(false)
  // const imgsViewerNext = () => setImgsViewerCurrent(imgsViewerCurrent + 1)
  // const imgsViewerPrev = () => setImgsViewerCurrent(imgsViewerCurrent - 1)
  const openEditDialog = () => setIsEditDialogOpen(true)
  const closeEditDialog = () => {
    setEditingImage(null)
    setIsEditDialogOpen(false)
  }

  const handleProblematicImage = (size) => (e) => {
    e.target.src = `https://via.placeholder.com/${size}.jpg?text=Add Image`
  }

  const handleEditImage = (image) => {
    setEditingImage(image)
  }

  const handleSaveImage = async (values) => {
    return new Promise(async (resolve, reject) => {
      const action = !!editingImage ? 'updateImage' : 'createImage'
      const imageProperties = !!editingImage ? { imageId: editingImage.id } : {}

      const multipleImages = Array.isArray(values.image) && Array.from(values.image).length > 1
      let result
      if (multipleImages) {
        let promises = []
        for (const image of values.image) {
          let multipleFormData = new FormData()
          multipleFormData.append('image', image)
          promises.push(
            dispatch(
              smartwayApi.endpoints[action].initiate({
                entity,
                id,
                formData: multipleFormData,
                ...imageProperties
              })
            )
          )
        }
        result = await Promise.all(promises)
      } else {
        const formData = new FormData()
        for (const [key, value] of Object.entries(values)) {
          if (key === 'image') {
            formData.append(key, value[0])
          } else {
            formData.append(key, value)
          }
        }
        result = await dispatch(
          smartwayApi.endpoints[action].initiate({
            entity,
            id,
            formData,
            ...imageProperties
          })
        )
      }
      const resultData = Array.isArray(result) ? result.every((r) => r.data) : result.data
      if (resultData) {
        resolve(resultData)
        setIsEditDialogOpen(false)
        setEditingImage(null)
        onChange()
        dispatch(
          notify({
            title: 'Well done!',
            message: 'Image was saved successfully',
            type: 'success',
            icon: 'done'
          })
        )
      } else {
        reject('Error during upload')
        dispatch(
          notify({
            title: 'Ops!',
            message: 'There was an error uploading the image',
            type: 'error',
            icon: 'error'
          })
        )
      }
    })
  }

  const handleDeleteImage = async (image) => {
    const result = await dispatch(
      smartwayApi.endpoints.deleteImage.initiate({
        entity,
        id,
        imageId: image.id
      })
    )
    if (result && result.data) {
      onChange()
      dispatch(
        notify({
          title: 'Well done!',
          message: 'Image was deleted successfully',
          type: 'success',
          icon: 'done'
        })
      )
    } else {
      dispatch(
        notify({
          title: 'Ops!',
          message: 'There was an error deleting the image',
          type: 'error',
          icon: 'error'
        })
      )
    }
  }

  useEffect(() => {
    if (editingImage) {
      openEditDialog()
    }
  }, [editingImage])

  useEffect(() => {
    const mapped = images ? mapImages([...images]) : []
    setMappedImages(mapped.filter((image) => !image.featured))
    if (!!mapped.length) {
      setMainImage(getMainImage(mapped))
    } else {
      setMainImage(null)
    }
  }, [images])

  return (
    <MDBox>
      <EditImageDialog
        image={editingImage}
        open={isEditDialogOpen}
        onClose={closeEditDialog}
        onSave={handleSaveImage}
      />
      {/* {imgsViewer ? (
        <ImageViewer
          src={mappedImages}
          isOpen={imgsViewer}
          onClose={closeImgsViewer}
          currentIndex={imgsViewerCurrent}
          onClickPrev={imgsViewerPrev}
          onClickNext={imgsViewerNext}
          backdropCloseable
        />
      ) : null} */}

      { mainImage && mainImage.featured ? (
        <EditableImage
          image={mainImage}
          iconStyles={iconStyles}
          onEdit={handleEditImage}
          onDelete={handleDeleteImage}
          // onClick={openImgsViewer}
          onError={handleProblematicImage('1200x900')}
        />
      ) : null }
      <MDBox mt={1}>
        <Stack direction="row" justifyContent="space-between" sx={{ flexWrap: 'wrap', gap: '4px' }}>
          {mappedImages && mappedImages.length
            ? mappedImages.map((img, ind) => {
                return (
                  <MDBox key={img.id} width="calc(50% - 4px)" position="relative">
                    <EditableImage
                      id={`image-${ind}`}
                      iconStyles={iconStyles}
                      image={{
                        ...img,
                        cursor: 'pointer',
                        objectFit: 'cover',
                        minHeight: '100px',
                        maxHeight: '100px'
                      }}
                      format="mobile"
                      borderRadius="lg"
                      shadow="md"
                      onEdit={handleEditImage}
                      onDelete={handleDeleteImage}
                    />
                    {/* { img && img.featured ? (
                      <MDBox
                        sx={{...iconStyles, width: '20px', height: '20px', opacity: 1, transform: 'scale(1)', backgroundColor: 'rgba(255, 255, 255, 0.5)', bottom: '10px', color: 'warning.main'}}>
                        <Icon fontSize="small">star</Icon>
                      </MDBox>
                    ) : null } */}
                  </MDBox>
                )
              })
            : null}

          <MDBox
            id="new-img"
            borderRadius="lg"
            width="100%"
            height="5rem"
            minHeight="100px"
            maxWidth="calc(50% - 4px)"
            display="flex"
            justifyContent="center"
            alignItems="center"
            color="primary"
            sx={{
              cursor: 'pointer',
              fontSize: '2rem',
              border: '1px dashed',
              borderColor: 'primary'
            }}
            onClick={openEditDialog}>
            <AddCircleIcon />
          </MDBox>
        </Stack>
      </MDBox>
    </MDBox>
  )
}

export default ImageCarousel
