import Grid from '@mui/material/Grid'

import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'

import { Link } from 'react-router-dom'
import Image from 'components/Image'

import errorImage from 'assets/illustrations/image-placeholder.svg'
const SimpleLocationCard = ({
  id,
  name,
  address,
  description,
  featured_image,
  images,
  cta,
  className = '',
  showThumbnails = 3
}) => {

  return (
    <MDBox display="flex" className={className}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4} sx={showThumbnails > 0 ? {} : { height: '100%' }}>
          <Image
            key={id}
            {...featured_image}
            alt="accommodation"
            borderRadius="md"
            width="100%"
            maxHeight={showThumbnails > 0 ? '220px' : '100%'}
            wrapperStyles={showThumbnails > 0 ? {} : { height: '100%' }}
            sx={{ objectFit: 'cover' }}
          />
          <Grid container spacing={1}>
            {images && images.length
              ? images.map((image, index) => {
                  return index < showThumbnails ? (
                    <Grid
                      key={`accommodation-image-${image.id}`}
                      item
                      xs={12 / showThumbnails}
                      maxHeight="80px">
                      <Image
                        {...image}
                        errorImage={errorImage}
                        width="100%"
                        height="100%"
                        minHeight="60px"
                        maxHeight="60px"
                        sx={{
                          objectFit: 'cover',
                          display: 'inline-block',
                          position: 'relative',
                          '::before': {
                            content: "''",
                            border: 'none',
                            width: '100%',
                            height: 'calc(100% + 10px)',
                            backgroundColor: 'rgba(230, 230, 230, 1)',
                            position: 'absolute',
                            top: '50%',
                            left: 0,
                            transform: 'translateY(-50%)'
                          }
                        }}
                      />
                    </Grid>
                  ) : null
                })
              : null}
          </Grid>
        </Grid>
        <Grid item xs={12} md={8} sx={showThumbnails > 0 ? {} : { my: 3, px: 2 }}>
          <MDTypography variant="h5">{name}</MDTypography>
          <MDTypography variant="button">{address}</MDTypography>
          <MDTypography
            variant="body2"
            dangerouslySetInnerHTML={{
              __html: description ? description.slice(0, 150) : ''
            }}
            mt={2}
          />

          {cta ? (
            <MDBox mt={4}>
              <Link to={cta.href}>
                <MDButton variant="gradient" color={cta.color}>
                  {cta.label || 'Discover more'}
                </MDButton>
              </Link>
            </MDBox>
          ) : null}
        </Grid>
      </Grid>
    </MDBox>
  )
}

export default SimpleLocationCard
