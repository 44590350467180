import { BaseLoader } from 'router/BaseLoader'
import { smartwayApi } from 'services/api'
import { defer } from 'react-router-dom'

import townFilters from 'layouts/pages/locations/listPage/filters/town'
import accommodationFilters from 'layouts/pages/locations/listPage/filters/accommodation'
import experienceFilters from 'layouts/pages/locations/listPage/filters/experience'
import coworkingFilters from 'layouts/pages/locations/listPage/filters/coworking'
import cateringFilters from 'layouts/pages/locations/listPage/filters/catering'
import workshopFilters from 'layouts/pages/locations/listPage/filters/workshop'
import plenaryHallFilters from 'layouts/pages/locations/listPage/filters/plenaryHall'
import allInVenuesFilters from 'layouts/pages/locations/listPage/filters/allInVenues'
import venueAccommodationFilters from 'layouts/pages/locations/listPage/filters/venueAccommodation'

const waiter = (resolver, time = 3000) => {
  return new Promise((resolve) =>
    setTimeout(() => {
      resolve(resolver)
    }, time)
  )
}

export class LocationsLoader extends BaseLoader {
  constructor(store, entity) {
    super(store)
    this.entity = entity
  }
  listLoader = async ({ request }) => {
    const url = new URL(request.url)
    let filters
    try {
      const results = this._loader(smartwayApi.endpoints.fetchEntities, request, {
        entity: this.entity,
        ...Object.fromEntries(url.searchParams),
        page_size: 21
      })

      const _filters = {
        accommodation: accommodationFilters,
        experience: experienceFilters,
        coworking: coworkingFilters,
        catering: cateringFilters,
        plenaryHall: plenaryHallFilters,
        allInVenue: allInVenuesFilters,
        venueAccommodation: venueAccommodationFilters,
        town: townFilters,
        workshop: workshopFilters
      }
      filters = _filters[this.entity]
      
      return defer({
        results,
        initialFilters: filters
      })
    } catch (error) {
      return error
    }
  }

  detailLoader = async ({ params, request }) => {
    const isNew = !params.id
    if (isNew) {
      return {
        entity: {},
        images: null
      }
    }
    const [entity, images] = await Promise.all([
      this._loader(
        smartwayApi.endpoints.fetchEntity,
        request,
        {
          entity: this.entity,
          id: params.id
        },
        { forceRefetch: true }
      ),
      this._loader(
        smartwayApi.endpoints.fetchImages,
        request,
        {
          entity: this.entity,
          id: params.id,
          page_size: 30
        },
        { forceRefetch: true }
      )
    ])

    let rooms = new Promise((res) => res(null))
    if (['accommodation', 'venueAccommodation'].includes(this.entity)) {
      rooms = waiter(
        this._loader(
          smartwayApi.endpoints.fetchRooms,
          request,
          {
            entity: this.entity,
            id: params.id,
            page_size: 200
          },
          {
            forceRefetch: true
          }
        ),
        500
      )
    }

    return defer({
      entity,
      images: images && images.results && images.results.length ? images.results : null,
      rooms
    })
  }
}
