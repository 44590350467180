const schema = {
  name: 'avatar-form',
  fieldsets: [
    {
      id: 'avatar',
      fields: [
        {
          type: 'file',
          name: 'avatar',
          label: 'Choose your avatar',
          validationType: 'mixed',
          validations: [
            {
              type: 'fileSize',
              params: ['File too large', '5480000']
            },
            {
              type: 'fileFormat',
              params: ['Unsupported Format', ['image/jpg', 'image/jpeg', 'image/gif', 'image/png']]
            },
            {
              type: 'nullable'
            }
          ],
          accept: 'image/png,image/jpeg,image/jpg'
        }
      ]
    }
  ]
}

export default schema