import { useState } from 'react'

import Icon from '@mui/material/Icon'

import MDBox from 'components/MDBox'
import ConfirmationDialog from 'components/Dialogs/ConfirmationDialog'

import Image from 'components/Image'


const EditableImage = ({ image, iconStyles, onClick, onError, onEdit, onDelete }) => {
  const [delConfirmationOpen, setDelConfirmationOpen] = useState(false)

  const openConfirmationDialog = () => setDelConfirmationOpen(true)
  const closeConfirmationDialog = () => setDelConfirmationOpen(false)

  const handleOnClick = () => {
    if (onClick) {
      onClick(image)
    }
  } 
  const handleOnEdit = () => {
    if (onEdit) {
      onEdit(image)
    }
  } 
  const handleOnDelete = () => {
    if (onDelete) {
      onDelete(image)
    }
  } 

  

  return (
    <>
      {image && image.formats ? (
        <MDBox position="relative" sx={{ 
          '&:hover': {
            '.editable-image__icon': {
              opacity: '1 !important',
              transform: 'scale(1)'
            }
        } }}>
          <ConfirmationDialog
            title="Are you sure you want to delete the image?"
            message="You cannot undo this action."
            open={delConfirmationOpen}
            setOpen={setDelConfirmationOpen}
            onConfirm={handleOnDelete}
            onCancel={closeConfirmationDialog}
          />
          <Image
            maxHeight="none"
            shadow="lg"
            borderRadius="lg"
            width="100%"
            {...image}
            onClick={handleOnClick}
          />
          <MDBox display="flex">
            <MDBox
              className="editable-image__icon"
              sx={{...iconStyles, top: '5px', right: '42px'}}
              onClick={handleOnEdit}>
              <Icon fontSize="small">edit</Icon>
            </MDBox>
            <MDBox
              className="editable-image__icon"
              sx={{...iconStyles, top: '5px', }}
              onClick={openConfirmationDialog}>
              <Icon fontSize="small">delete</Icon>
            </MDBox>
          </MDBox>
          { image && image.featured ? (
            <MDBox
              className="editable-image__icon"
              sx={{...iconStyles, opacity: 1, transform: 'scale(1)', backgroundColor: 'rgba(255, 255, 255, 0.5)', bottom: '15px', color: 'warning.main'}}
              onClick={openConfirmationDialog}>
              <Icon fontSize="small">star</Icon>
            </MDBox>
          ) : null }
        </MDBox>
      ) : null}
    </>
  )
}

export default EditableImage
