const catering = ({ groups, catering, onPublishChange }) => {

  const publishButton = catering.id ? catering.published_at ? {
    label: 'Unpublish',
    color: 'warning',
    tooltip: 'By unpublishing, you will not be able to see the public page anymore',
    icon: 'visibility_off',
    onClick: () => onPublishChange(false, catering)
  } : {
    label: 'Publish',
    color: 'success',
    tooltip: 'Be sure to have saved before publishing',
    icon: 'rocket_launch',
    onClick: () => onPublishChange(true, catering)
  } : null

  const showIsInsideVenue = catering && catering.all_in_venue

  return  {
    id: 'catering-form',
    title: 'Catering Information',
    ctasPosition: 'top',
    fieldsets: [
      {
        id: 'general',
        title: 'General',
        type: 'accordion',
        fields: [
          {
            type: 'text',
            label: 'Name',
            name: 'name',
            required: true,
            validationType: 'string',
            validations: [
              {
                type: 'required',
                params: ['This field is required']
              }
            ]
          },
          {
            type: 'textarea',
            name: 'excerpt',
            label: 'Excerpt',
            placeholder: 'Write an excerpt',
            validationType: 'string',
            validations: null
          },
          {
            type: 'address',
            name: 'address',
            label: 'Address',
            validationType: 'string',
            grid: { xs: 6 },
            validations: [
              {
                type: 'required',
                params: ['This field is required']
              }
            ]
          },
          {
            type: 'select',
            name: 'town',
            label: 'Town / Venue',
            labelProp: 'name',
            valueProp: 'id',
            required: true,
            groupBy: (o) => o.entity,
            optionsGetter: {
              endpoints: [
                { name: 'Towns', entity: 'town', prefix: 'town' },
                { name: 'Venues', entity: 'allInVenue', prefix: 'allInVenue' }
              ]
            },
            grid: { xs: 6 },
            validationType: 'string',
            validations: [
              {
                type: 'required',
                params: ['This field is required']
              },
              {
                type: 'nullable',
                params: []
              }
            ]
          },
          {
            type: 'number',
            label: 'Capacity',
            name: 'capacity',
            validationType: 'string',
            validations: null,
            grid: { xs: 6 }
          },
          {
            type: 'text',
            label: 'Phone Number',
            name: 'phone_number',
            validationType: 'text',
            validations: null,
            grid: { xs: 6 }
          },
          {
            type: 'number',
            label: 'Min price',
            name: 'price_min',
            validationType: 'string',
            suffix: '€',
            validations: null,
            grid: { xs: 3 }
          },
          {
            type: 'number',
            label: 'Max price',
            name: 'price_max',
            suffix: '€',
            validationType: 'string',
            validations: null,
            grid: { xs: 3 }
          },
          {
            type: 'number',
            label: 'Normal price',
            name: 'normal_price',
            suffix: '€',
            validationType: 'string',
            validations: null,
            grid: { xs: 3 }
          },
          {
            type: 'number',
            label: 'Weekday price',
            name: 'weekday_price',
            suffix: '€',
            validationType: 'string',
            validations: null,
            grid: { xs: 3 }
          },
          {
            type: 'switch',
            name: 'available',
            label: 'Is the catering available?',
            grid: { xs: 12 },
            validationType: 'boolean',
            validations: null
          },
          {
            type: 'switch',
            name: 'is_inside_venue',
            label: 'Catering is inside the venue?',
            grid: { xs: 12 },
            validationType: 'boolean',
            validations: null,
            hidden: !showIsInsideVenue
          },
          {
            type: 'textarea',
            name: 'description',
            label: 'Description',
            required: true,
            placeholder: 'Write a description',
            validationType: 'string',
            validations: [
              {
                type: 'required',
                params: ['This field is required']
              }
            ]
          }
        ]
      },
      {
        id: 'settings',
        title: 'Catering settings',
        type: 'accordion',
        fields: [
          {
            type: 'select',
            name: 'categories',
            multiple: true,
            label: 'Type of catering *',
            optionsGetter: {
              endpoints: [
                { name: 'catering_types', entity: 'locationCategories', id: 'catering' }
              ]
            },
            labelProp: 'name',
            valueProp: 'id',
            validationType: 'object',
            validations: [
              {
                type: 'nullable',
                params: []
              }
            ],
            grid: { xs: 6 }
          },
          {
            type: 'select',
            name: 'dietary_options',
            label: 'Dietary options',
            multiple: true,
            labelProp: 'name',
            valueProp: 'id',
            optionsGetter: {
              endpoints: [
                {
                  name: 'dietary_options',
                  entity: 'dietaryOptions'
                }
              ]
            },
            validationType: 'object',
            validations: [
              {
                type: 'nullable',
                params: []
              }
            ],
            grid: { xs: 6 }
          }
        ]
      }
    ],
    submitButton: {
      label: 'Save',
      icon: 'save'
    },
    cancelButton: {
      label: 'Back'
    },
    publishButton,
  }
}

export default catering