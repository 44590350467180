import MuiPhoneNumber from 'mui-phone-number'

import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

const Phone = ({ name, label, value, errors, helperText, success, boxStyles = {}, onChange, ...field }) => {
  const handleOnChange = (value) => {
    if (onChange && typeof onChange === 'function') {
      onChange({ target: { name, value } })
    }
  }

  return (
    <MDBox position="relative" {...boxStyles}>
      <MuiPhoneNumber
        name={name}
        label={label}
        defaultCountry={'it'}
        value={value}
        fullWidth
        {...field}
        onChange={handleOnChange}
      />
       {helperText && !success && !errors ? (
          <MDBox position="absolute" sx={{ top: 'calc(100% + 4px)', lineHeight: 0.8 }}>
            <MDTypography variant="caption">{helperText}</MDTypography>
          </MDBox>
        ) : null}
    </MDBox>
  )
}

export default Phone
