import { useState, useEffect } from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import MaterialCheckbox from '@mui/material/Checkbox'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import * as colors from '@mui/material/colors';

const Checkbox = ({ errors, color = 'grey', colorIntensity = 700, styles = {}, ...field }) => {
  const { setFieldTouched, setFieldValue, value, ...fieldProps } = field
  const [checked, setChecked] = useState(value || false)

  useEffect(() => {
    if (value !== checked) {
      setChecked(value)
    }
  }, [value])

  return (
    <MDBox {...styles}>
      <FormControlLabel
        sx={{
          color: '#495057',
          display: 'inline-flex',
          '& > span': { fontWeight: '400 !important' }
        }}
        label={field.label}
        control={
          <MaterialCheckbox
            {...fieldProps}
            checked={checked}
            sx={{
              color: colors[color][colorIntensity],
              '&.Mui-checked': {
                color: colors[color][colorIntensity],
                '.MuiSvgIcon-root': {
                  borderColor: `${colors[color][colorIntensity]}`,
                  backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 -1 22 22'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2.5' d='M6 10l3 3l6-6'/%3e%3c/svg%3e"),linear-gradient(195deg, ${colors[color][colorIntensity]} 0%, ${colors[color][colorIntensity]} 100%)`
                }
              },
            }}
          />
        }
      />
      {errors ? (
        <MDTypography color="error" variant="caption">
          {errors}
        </MDTypography>
      ) : null}
    </MDBox>
  )
}

export default Checkbox
