import { useState, useEffect, useCallback } from 'react'

// react-router-dom components
import {
  useNavigate,
  useSearchParams,
  Link,
  useSubmit,
  useActionData
} from 'react-router-dom'

import { addMinutes, isPast } from 'date-fns'

// Redux components
import { useDispatch } from 'react-redux'
import { notify } from 'store/reducers/UISlice'
import { smartwayApi } from 'services/api'


// Form
import getSchema from './schema'
import Form from 'components/Form'
import useInterval from 'hooks/useInterval'

// @mui material components
import Card from '@mui/material/Card'
import Icon from '@mui/material/Icon'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'

// Authentication layout components
import BasicLayout from 'layouts/authentication/components/BasicLayout'

// Images
import bgImage from 'assets/images/montepulciano.jpeg'
import fireworks from 'assets/illustrations/fireworks.svg'
import mailbox from 'assets/illustrations/mailbox.svg'


const Registration = () => {
  const [schema, setSchema] = useState(getSchema({ emailParam: null }))
  const [isLoading, setIsLoading] = useState(true)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [emailVerification, setEmailVerification] = useState(false)
  const [resendDisabled, setResendDisabled] = useState(false)
  const [resendSeconds, setResendSeconds] = useState(60)
  const dispatch = useDispatch()
  const submit = useSubmit()
  const actionData = useActionData()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [initialValues, setInitialValues] = useState({})


  useInterval(() => {
    if (resendDisabled) {
      const lastSent = localStorage.getItem('resend')
      const plusOneMinute = addMinutes(new Date(lastSent), 1)

      if (isPast(plusOneMinute)) {
        setResendDisabled(false)
      } else {
        const secondsLeft = Math.floor((plusOneMinute - new Date()) / 1000)
        setResendSeconds(secondsLeft)
      }
    }
  }, 1000)


  const handleRegister = async (values) => {
    setName(`${values.first_name} ${values.last_name}`)
    setEmail(values.email)
    submit({...values, email: values.email?.toLowerCase(), password2: values.password}, { method: 'post', encType: 'application/json' })
  }

  const verifyFirebaseToken = useCallback(async () => {
    try { 
      const firebaseResponse = await dispatch(smartwayApi.endpoints.verifyToken.initiate(null, { forceRefetch: true }))
      if (firebaseResponse.success) {
        setIsLoading(false)
        const next = searchParams.get('next') 
        navigate(next ? next : '/dashboard/profile')
      } else {
        setIsLoading(false)
        dispatch(smartwayApi.util.resetApiState())
      }
    } catch (error) {
      setIsLoading(false)
    }
  }, [dispatch, navigate, searchParams])

  const handleReset = () => {
    setEmailVerification(false)
    setName('')
    setEmail('')
    setInitialValues({})
    setResendDisabled(false)
    setResendSeconds(60)
    setIsLoading(false)
  }

  const handleResendEmail = async () => {
    try {
      const resend = await dispatch(smartwayApi.endpoints.createEntity.initiate({ entity: 'resendActivation', email }, { forceRefetch: true }))
      if (resend) {
        localStorage.setItem('resend', new Date())
        setResendDisabled(true)
        
        dispatch(
          notify({
            type: 'success',
            title: 'Email sent',
            icon: 'done',
            message: 'Please check your email',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right'
            }
          })
        )
      }
    } catch (error) {
      console.warn(error)
      dispatch(
        notify({
          type: 'error',
          title: 'Email not sent',
          icon: 'error',
          message: 'Please try again',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right'
          }
        })
      )
    }
  }

  useEffect(() => {
    if (isLoading) {
      verifyFirebaseToken()
    }
  }, [verifyFirebaseToken, isLoading])

  useEffect(() => {
    if (actionData && actionData.error) {
      dispatch(
        notify({
          type: 'error',
          title: 'Registration failed',
          icon: 'error',
          message: actionData.fullError,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right'
          }
        })
      )
    }
    if (actionData && actionData.success) {
      if (actionData.active) {
        const falseToken = btoa('active')
        navigate(`/authentication/confirm-registration?activation=${falseToken}`)
      }
      setEmailVerification(true)
      dispatch(
        notify({
          type: 'success',
          title: 'Registration success',
          icon: 'done',
          message: 'Please verify your email',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right'
          }
        })
      )
    }
  }, [actionData, dispatch])

  useEffect(() => {
    if (searchParams && searchParams.get('ie')) {
      const email = searchParams.get('ie').replace(' ', '+').toLowerCase()
      setEmail(email)
      setSchema(getSchema({ emailParam: email }))
      setInitialValues({ email: email })
      setSearchParams(new URLSearchParams())
    }
  }, [searchParams, setSearchParams])

  return !isLoading ? (
    <BasicLayout image={bgImage}>
      <Card sx={{ position: 'relative', zIndex: 100 }}>
        { emailVerification ? (
          <MDBox width="100%" minHeight="50vh" p={4} display="flex" justifyContent="center" alignItems="center" flexDirection="column">
            <MDBox
              component="img"
              src={mailbox}
              alt="You've got mail from Smartway!"
              width="100%"
              maxHeight={200}
              mb={3}
            />
            <MDTypography variant="h6" mt={4}>Please verify your email address</MDTypography>
            <MDTypography variant="body2" mt={2}>We just sent an email to <strong>{email}</strong>.<br/> Click the link in the email to verify your account.</MDTypography>

            <MDBox display="flex" justifyContent="center" alignItems="center" mt={4} gap={2}> 
              <MDButton variant="outlined" size="small" color="secondary" onClick={handleReset}>
                Back to registration
              </MDButton>
              <MDButton variant="gradient" color="primary" size="small" onClick={handleResendEmail} disabled={resendDisabled}>
                Resend <Icon sx={{ ml: 1 }}>refresh</Icon>
              </MDButton>
            </MDBox>
            { resendDisabled ? (
              <MDBox textAlign="center" mt={1}>
                <MDTypography variant="button" color="secondary">
                  You can try again in {resendSeconds} seconds
                </MDTypography>
              </MDBox>
            ) : null }
          </MDBox>
        ) : (
          <>
            <MDBox
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              mx={2}
              mt={-3}
              p={2}
              mb={1}
              textAlign="center">
              <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                Create your account
              </MDTypography>
            </MDBox>
            <MDBox pt={4} pb={3} px={3}>
              <MDBox>
                <Form {...schema} values={initialValues} onSubmit={handleRegister} />
                <MDBox mt={1} display="flex" justifyContent="center">
                  <MDButton variant="text" size="small">
                    <Link to="/authentication/sign-in">
                      Already have an account? Login
                    </Link>
                  </MDButton>
                </MDBox>
              </MDBox>
            </MDBox>
          </>
        )}
      </Card>
    </BasicLayout>
  ) : <p>Loading...</p>
}

export default Registration
