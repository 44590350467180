import { useState, useEffect, useRef } from 'react'

import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import FormHelperText from '@mui/material/FormHelperText'

import { styled } from '@mui/material/styles'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import CloseIcon from '@mui/icons-material/Close'

const StyledFileUpload = styled('label')(({ theme, isVideo }) => {
  return {
    position: 'relative',
    display: 'flex',
    height: 'auto',
    padding: '10px 12px',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: 'solid 1px #d2d6da',
    borderRadius: '6px',
    color: '#495057',
    backgroundColor: 'transparent',
    appearance: 'none',
    flexGrow: 1,
    outline: 'none',
    minWidth: '152px',
    width: '100%',
    maxWidth: '100vw',
    marginBottom: '36px',
    fontSize: '14px',
    cursor: 'pointer',
    '&.disabled': {
      backgroundColor: 'grey',
      cursor: 'not-allowed',
      pointerEvents: 'none'
    },
    ...(isVideo && {
      flexDirection: 'column',
      justifyContent: 'center',
      height: '250px',
      marginBottom: '0px',
      border: '1px dashed #d2d6da',
      p: {
        fontSize: '16px',
        marginBottom: '8px'
      }
    })
  }
})

const FileUpload = ({
  name,
  value,
  label,
  accept,
  acceptReadable,
  required,
  multiple = false,
  maxSize,
  isVideo,
  disabled,
  errors,
  placeholder = 'No selected file',
  showPreview,
  onFileChange
}) => {
  const [filename, setFilename] = useState(null)
  const [files, setFiles] = useState([])
  const [filesPreviews, setFilesPreviews] = useState({})
  const [previewsReady, setPreviewsReady] = useState(false)
  const [sizeError, setSizeError] = useState(null)
  const inputRef = useRef(null)

  const checkSize = (file) => {
    return file.size <= maxSize || 'File is too large, please select a smaller file'
  }

  const humanFileSize = (size) => {
    const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
    return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
}

  const getFilesPreviews = (files) => {
    let previews = {}
    files.forEach((file) => {
      const reader = new FileReader()
        reader.onload = () => {
          previews[file.name] = reader.result
        }
        reader.readAsDataURL(file)
    })
    setFilesPreviews(previews)
    setTimeout(() => {
      setPreviewsReady(true)
    }, 200) 
  }

  const handleChange = async (e) => {
    setPreviewsReady(false)
    const _files = Array.from(e.currentTarget.files)
    const isNotTooBig = !multiple && isVideo ? checkSize(_files[0]) : true
    if (isNotTooBig) {
      setSizeError(null)
      setFilename(_files.length === 1 ? _files[0].name : `${_files.length} files selected`)
      if (typeof onFileChange === 'function') {
        onFileChange(name, _files, e)
      }
      if (showPreview || (multiple && _files.length > 1)) {
        setFiles(_files)
        setSizeError(null)
      }
    } else {
      setSizeError(isNotTooBig)
    }
  }

  const handleClick = (e) => {
    e.preventDefault()
    inputRef.current.click()
  }
  const handleReset = (e) => {
    e.preventDefault()
    inputRef.current.value = ''
    setFilename(null)
    setFiles([])
    setSizeError(null)
    if (typeof onFileChange === 'function') {
      onFileChange(name, null, { target: { name, value: null } })
    }
  }

  const handleFocus = (e) => {}

  const handleRemoveFile = (file) => {
    const newFiles = files.filter((f) => f.name !== file.name)
    const newFilesLength = newFiles.length
    const newFilename = newFilesLength > 1 ? `${newFilesLength} files selected` : !!newFilesLength ? newFiles[0].name : 'No selected file'
    
    setFiles(newFiles)
    setFilename(newFilename)

    const newPreviews = { ...filesPreviews }
    delete newPreviews[file.name]
    setFilesPreviews(newPreviews)
    if (typeof onFileChange === 'function') {
      onFileChange(name, newFiles, { target: { name  } })
    }
  }

  useEffect(() => {
    if (showPreview || files.length > 1) {
      getFilesPreviews(files)
    }
  }, [files])

  useEffect(() => {
    if (showPreview && value && typeof value === 'object' && !Array.isArray(value)) {
      setPreviewsReady(false)
      setFiles([])
      setFilename(null)
      setFilesPreviews(value)
      setSizeError(null)
    }
  }, [value])

  return (
    <MDBox>
      { label ? <MDTypography variant="button" fontWeight="regular" color="text" ml={0.5} mb={1} >{label}</MDTypography> : null }
      <StyledFileUpload isVideo={isVideo} htmlFor={name}>
        <p
          style={{
            color: !!filename ? '#495057' : '#7b809a',
            fontSize: isVideo ? '22px' : '16px'
          }}>
          {!!filename ? filename : `${placeholder}${required ? ' *' : ''}`}
        </p>
        <input
          style={{ display: 'none' }}
          type="file"
          ref={inputRef}
          id={name}
          name={name}
          multiple={multiple}
          accept={accept}
          disabled={disabled}
          onChange={handleChange}
          onFocus={handleFocus}
        />
        {filename ? (
          <CloseIcon onClick={handleReset} />
        ) : (
          <CloudUploadIcon
            fontSize={isVideo ? 'large' : 'medium'}
            onClick={handleClick}
          />
        )}
        {accept && isVideo ? (
          <MDTypography
            sx={{
              textAlign: 'center',
              maxWidth: '300px',
              color: !!filename ? '#495057' : '#7b809a',
              fontSize: '22px'
            }}>
            Accepted formats: {accept}
          </MDTypography>
        ) : null}
        {accept && !isVideo && !errors ? (
          <MDBox position="absolute" top={50} left={0}>
            <FormHelperText
              sx={{
                fontSize: '12px'
              }}>
              Accepted formats: {acceptReadable || accept}
            </FormHelperText>
          </MDBox>
        ) : null}
        {errors || sizeError ? (
          <MDBox position="absolute" top={isVideo ? '100%' : 50} left={0}>
            <MDTypography color="error" variant="caption">
              {errors || sizeError}
            </MDTypography>
          </MDBox>
        ) : null}
      </StyledFileUpload>
      { (showPreview || (multiple && files && files.length > 1)) && previewsReady ? (
        <MDBox>
          { files.map((file) => {
            return (
              <MDBox key={file.name} mt={1} display="flex" justifyContent="space-between" bgColor="grey-100" pr={1}>
                <MDBox display="flex" alignItems="center">
                  <MDBox component="img" src={filesPreviews[file.name]} alt={file.name} width="50px" mr={2}/>
                  <MDTypography variant="button">{file.name}</MDTypography>
                  <MDTypography variant="caption" ml={2}>{humanFileSize(file.size)}</MDTypography>
                </MDBox>
                <MDBox display="flex" alignItems="center">
                  <CloseIcon onClick={() => handleRemoveFile(file)} />
                </MDBox>
              </MDBox>
            )
          })}
        </MDBox> 
      ) : null }
      { value && typeof value === 'object' && !!Object.keys(value).length && !Array.isArray(value) && showPreview ? (
        <MDBox component="img" src={value.thumbnail} alt={name} width="100%" maxWidth="200px"/>
      ) : null }

    </MDBox>
  )
}

export default FileUpload
