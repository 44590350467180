const getInitialFilters = (entity) => {
  switch (entity) {
    case 'catering': 
      return {
        name: 'catering-filters-form',
        fieldsets: [
          {
            fields: [
              {
                type: 'text',
                name: 'search',
                placeholder: 'Search by name',
                grid: { xs: 4, },
                boxStyles: { mb: 1},
                inputProps: {
                  sx: {
                    p: 1,       
                  }
                },
                validationType: 'string',
                validations: null
              },
              {
                label: 'Type of place',
                type: 'badges',
                name: 'categories',
                labelProp: 'name',
                valueProp: 'id',
                multiple: true,
                size: 'small',
                sx: { marginBottom: '8px !important' },
                optionsGetter: { 
                  endpoints: [
                    {
                      entity: 'locationCategories', id: 'catering'
                    }
                  ]
                },
              },
              {
                label: 'Dietary options',
                type: 'badges',
                name: 'dietary_options',
                multiple: true,
                labelProp: 'name',
                valueProp: 'id',
                size: 'small',
                sx: { marginBottom: '0px !important' },
                optionsGetter: { 
                  endpoints: [
                    {
                      entity: 'dietaryOptions'
                    }
                  ]
                },
              },
            ]
          }
        ]
      }
    case 'experience': 
      return {
        name: 'experience-filters-form',
        fieldsets: [
          {
            fields: [
              {
                type: 'text',
                name: 'search',
                placeholder: 'Search by name',
                grid: { xs: 4, },
                boxStyles: { mb: 1},
                inputProps: {
                  sx: {
                    p: 1,       
                  }
                },
                validationType: 'string',
                validations: null
              },
              {
                label: 'Typology of experience',
                type: 'badges',
                name: 'categories',
                labelProp: 'name',
                valueProp: 'id',
                multiple: true,
                size: 'small',
                sx: { marginBottom: '8px !important' },
                optionsGetter: { 
                  endpoints: [
                    {
                      entity: 'locationCategories', id: 'experience'
                    }
                  ]
                },
              },
              {
                type: 'range',
                label: 'Participants',
                name: 'participants',
                min: 2,
                max: 100,
                minDistance: 10,
                suffix: 'pp',
                initialValue: [2, 100],
                grid: { xs: 12 },
                boxStyles: {
                  width: '80%',
                  marginBottom: '0px !important'
                }
              },
              {
                type: 'range',
                label: 'Duration',
                name: 'duration',
                min: 20,
                max: 240,
                minDistance: 20,
                suffix: 'mn',
                initialValue: [20, 240],
                grid: { xs: 12 },
                boxStyles: {
                  width: '80%',
                  marginBottom: '0px !important'
                }
              },
            ]
          }
        ]
      }
    default: 
      return {}
  }
}

const getAppliedFilters = (activityType, values, initialResults) => {
  let results = initialResults
  const areFilters = Object.keys(values).length
  if (areFilters) {
    switch (activityType.name) {
      case 'catering': 
        if (values.categories && values.categories.length) {
          results = results.filter(r => {
            // if (!r.categories.length) return true
            return r.categories.some(c => values.categories.includes(c.id))
          })
        }
        if (values.dietary_options && values.dietary_options.length) {
          results = results.filter(r => {
            // if (!r.dietary_options.length) return true
            return r.dietary_options.some(d => values.dietary_options.includes(d.id))
          })
        }
        if (values.search) {
          results = results.filter(r => {
            return r.name.toLowerCase().includes(values.search.toLowerCase())
          })
        }
        break
      case 'experience': {
        if (values.categories) {
          results = results.filter(r => {
            return values.categories.includes(r.category?.id)
          })
        }
        if (values.participants) {
          results = results.filter(({ min_participants, max_participants }) => {
            return values.participants[0] <= min_participants && values.participants[1] >= max_participants
          })
        }
        if (values.duration) {
          results = results.filter(({ duration }) => {
            return values.duration[0] <= duration && values.duration[1] >= duration
          })
        }
        if (values.search) {
          results = results.filter(r => {
            return r.name.toLowerCase().includes(values.search.toLowerCase())
          })
        }
        break
      }
      default: 
    }
  }
  return results
}

export { getInitialFilters, getAppliedFilters}