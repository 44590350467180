const otherFields = [
  {
    type: 'text',
    name: 'title',
    label: 'Title',
    validationType: 'string',
    validations: null
  },
  {
    type: 'checkbox',
    name: 'featured',
    label: 'Featured',
    validationType: 'boolean',
    validations: null
  }
]

const schema = (editingImage, hideOtherFields = false) => {
  return {
    name: 'edit-image-form',
    title: 'Image',
    fieldsets: [
      {
        id: 'edit-image',
        fields: [
          {
            type: 'file',
            name: 'image',
            label: 'Upload an image',
            multiple: true,
            validationType: 'mixed',
            validations: [
              {
                type: 'fileSize',
                params: ['File too large', '5480000']
              },
              {
                type: 'fileFormat',
                params: [
                  'Unsupported Format',
                  ['image/jpg', 'image/jpeg', 'image/gif', 'image/png']
                ]
              },
              {
                type: 'nullable'
              }
            ],
            accept: 'image/png,image/jpeg,image/jpg'
          },
          ...(editingImage && !hideOtherFields ? otherFields : [])
        ]
      }
    ]
  }
}

export default schema
