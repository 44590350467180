import { useState, useEffect, useRef } from 'react'
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api'

import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'

import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'

import Autocomplete from './Autocomplete'

const LIBRARIES = ['maps', 'places']
const MapDialog = ({
  open,
  countries = ['it'],
  address,
  lat,
  lng,
  onSave,
  onClose,
}) => {
  const COUNTRIES = countries
  const defaultInit = { lat: lat || 41.9028, lng: lng || 12.4964 }
  const [markerPosition, setMarkerPosition] = useState(defaultInit)
  const [markerDraggable, setMarkerDraggable] = useState(false)
  const [intermediateValue, setIntermediateValue] = useState(address ? { address, lat, lng } : null)
  const markerRef = useRef(null)
  const autocompleteRef = useRef(null)

  const containerStyle = {
    width: '600px',
    height: '400px'
  }

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GMAPS_APIKEY,
    libraries: LIBRARIES
  })

  const handleMarkerDragEnd = async (event) => {
    const { latLng } = event
    const newLat = Number(latLng.lat().toFixed(6))
    const newLong = Number(latLng.lng().toFixed(6))
    setIntermediateValue({ ...intermediateValue, lat: newLat, lng: newLong })
    setMarkerPosition({ lat: newLat, lng: newLong })
  }

  const handleSave = (e) => {
    if (intermediateValue) {
      const { address, lat, lng, place_id, ...rest } = intermediateValue
      onSave({ address: address, place_id, lat: Number(lat), lng: Number(lng), ...rest })
      onClose(e)
    }
  }

  const handlePlaceChange = (place) => {
    const { lat, lng, description, place_id, ...placeRest } = place
    setIntermediateValue({ place_id, address: description, lat: Number(lat.toFixed(6)), lng: Number(lng.toFixed(6)), ...placeRest })
    setMarkerPosition({ lat: Number(lat.toFixed(6)), lng: Number(lng.toFixed(6)) })
  }

  useEffect(() => {
    if (address || intermediateValue?.address) {
      setMarkerDraggable(true)
    }
  }, [intermediateValue?.address, address])

  useEffect(() => {
    if (lat && lng) {
      setMarkerPosition({ lat: Number(lat), lng: Number(lng) })
    }
  }, [lat, lng])

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        autocompleteRef.current?.querySelector('input').focus()
      })
    }   
  }, [open])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={false}
      sx={{ marginLeft: { xl: '250px' } }}>
      <DialogTitle>Select Location on the map</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500]
        }}>
        <CloseIcon />
      </IconButton>
      {isLoaded ? (
        <DialogContent>
          <div ref={autocompleteRef}>
            <Autocomplete
              place={address}
              countries={COUNTRIES}
              onPlaceChange={handlePlaceChange}
            />
          </div>
          <MDBox mt={2}>
            <MDBox mb={1}>
              <MDTypography
                component="caption"
                variant="caption"
                display="inline-block"
                textAlign="left">
                You can drag the marker only after selecting a location.
                <br />
                Afterwards this will change the coordinates but not the address.
              </MDTypography>
            </MDBox>
            <GoogleMap
              options={{
                fullscreenControl: false,
                streetViewControl: false,
                mapTypeControl: false,
                styles: [
                  {
                    featureType: 'all',
                    elementType: 'labels.text',
                    stylers: [
                      {
                        color: '#878787'
                      }
                    ]
                  },
                  {
                    featureType: 'all',
                    elementType: 'labels.text.stroke',
                    stylers: [
                      {
                        visibility: 'off'
                      }
                    ]
                  },
                  {
                    featureType: 'landscape',
                    elementType: 'all',
                    stylers: [
                      {
                        color: '#f9f5ed'
                      }
                    ]
                  },
                  {
                    featureType: 'road.highway',
                    elementType: 'all',
                    stylers: [
                      {
                        color: '#f5f5f5'
                      }
                    ]
                  },
                  {
                    featureType: 'road.highway',
                    elementType: 'geometry.stroke',
                    stylers: [
                      {
                        color: '#c9c9c9'
                      }
                    ]
                  },
                  {
                    featureType: 'water',
                    elementType: 'all',
                    stylers: [
                      {
                        color: '#aee0f4'
                      }
                    ]
                  }
                ]
              }}
              zoom={12}
              center={markerPosition}
              mapContainerStyle={containerStyle}
              onClick={handleMarkerDragEnd}
            >
              <Marker
                ref={markerRef}
                position={markerPosition}
                draggable={markerDraggable}
                onDragEnd={handleMarkerDragEnd}
              />
            </GoogleMap>
          </MDBox>
        </DialogContent>
      ) : null}
      <DialogActions>
        <MDButton color="primary" onClick={handleSave}>Save</MDButton>
      </DialogActions>
    </Dialog>
  )
}

export default MapDialog
