import { useState, useEffect } from "react";

import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox"
import Badge from "@mui/material/Badge"

import Form from 'components/Form'

export const FilterButton = ({ activeFiltersNumber, children }) => {
  return activeFiltersNumber > 0 ? (
    <Badge color="primary" badgeContent={activeFiltersNumber}>
      {children}
    </Badge>
  ) : (
    children
  )
}

const Filters = ({ id = "filters-form", initialFilters, appliedFilters, onApply }) => {
  const [activeFilters, setActiveFilters] = useState({})
  const [schema, setSchema] = useState(initialFilters)

  useEffect(() => {
    if (!initialFilters) return
    setActiveFilters(appliedFilters);
  }, [appliedFilters, initialFilters]);

  const handleFieldChange = (e, values, setFieldValue) => {
    const { name, value } = e.target
    const fieldToReset = name === 'all_in_venue' ? 'town' : 'all_in_venue'
    setActiveFilters({ ...activeFilters, [name]: value, [fieldToReset]: null })
    if (['all_in_venue', 'town'].includes(name)) {
      setFieldValue(fieldToReset, null)
    }
    if (['min_duration', 'max_duration'].includes(name)) {
      const otherField = name === 'min_duration' ? 'max_duration' : 'min_duration'
      setSchema({
        ...schema, 
        fieldsets: schema.fieldsets.map((fieldset) => {
          return {
            ...fieldset,
            fields: fieldset.fields.map((field) => {
              if (field.name === otherField) {
                return {
                  ...field,
                  options: field.options.map((o) => {
                    return {
                      ...o,
                      disabled: otherField === 'max_duration' ? value > o.value : value < o.value
                    }
                  })
                }
              }
              return field
            })
          }
        })
      })
    }
  }

  const handleFiltersSubmit = () => {
    onApply(activeFilters)
  }

  const handleResetFilters = () => {
    onApply({})
  }

  const watchedFields = initialFilters.fieldsets.reduce((acc, fieldset) => {
    fieldset.fields.forEach(field => {
      if (field.name) {
        acc.push(field.name)
      }
    })
    return acc
  }, [])

  return initialFilters ? (
    <MDBox p={2}>
      <Grid container spacing={2}>
        <Form
          id={id}
          sx={{ width: '-webkit-fill-available' }}
          {...schema}
          values={appliedFilters}
          watchedFields={watchedFields}
          onFieldChange={handleFieldChange}
          onCancel={handleResetFilters}
          onSubmit={handleFiltersSubmit}
        />
      </Grid>
    </MDBox>
  ) : null
}

export default Filters;
