import { useState } from 'react'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

const SettingsButton = ({ options, onClick }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const onOptionsSelect = (id) => {
    onClick(id)
    handleClose()
  }

  return (
    <>
      <IconButton
        aria-label="more"
        aria-haspopup="true"
        id="long-button"
        aria-controls="long-menu"
        sx={{ border: '1px solid #0000008a', borderRadius: '50%' }}
        onClick={handleClick}>
        <Icon fontSize="medium">more_vert</Icon>
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}>
        {options.map(({ label, id, icon }) => {
          const onSelect = () => {
            onOptionsSelect(id)
          }
          return (
            <MenuItem key={id} onClick={onSelect}>
              <Icon sx={{ mr: 1 }}>{icon}</Icon>
              {label}
            </MenuItem>
          )
        })}
      </Menu>
    </>
  )
}

export default SettingsButton
