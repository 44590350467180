import { useState, useEffect } from 'react'

import { useDispatch } from 'react-redux'

import {
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Icon,
  IconButton,
  Divider
} from '@mui/material'

import { smartwayApi } from 'services/api'

import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import DataTable from 'components/Tables/DataTable'

import Select from 'components/Fields/Select'
import Form from 'components/Form/Form'

import { removeDuplicates } from 'utils/functions'

import schema from './schemas/person'

const SinglePeopleDialog = ({ open, title, company, profile, onSave, onClose }) => {
  const dispatch = useDispatch()
  const [selectValue, setSelectValue] = useState(null)
  const [peopleOptions, setPeopleOptions] = useState(null)
  const [peopleList, setPeopleList] = useState([])
  const [peopleListTable, setPeopleListTable] = useState(null)

  const resetState = () => {
    setSelectValue(null)
    setPeopleList([])
    setPeopleListTable(null)
  }
  const handleOnClose = () => {
    if (onClose && typeof onClose === 'function') {
      onClose()
    }
    resetState()
  }

  const handlePeopleChange = (event) => {
    const { value } = event.target
    const people = [...peopleOptions.filter((p) => value.includes(p.email))]
    const uniquePeopleList = removeDuplicates(people, 'email')
    setPeopleList(uniquePeopleList)
  }

  const handlePeopleSubmit = (values) => {
    setPeopleList((list) => removeDuplicates([values, ...list], 'email'))
  }

  const handleOnSave = () => {
    if (onSave && typeof onSave === 'function') {
      onSave(peopleList)
    }
    resetState()
  }

  useEffect(() => {
    const getUsersList = async () => {
      const usersPromise = dispatch(
        smartwayApi.endpoints.fetchEntities.initiate({
          entity: 'user',
          page_size: 200,
          company
        })
      )
      const promises = profile.is_staff && profile.company && profile.company.id !== company
        ? [
            usersPromise,
            dispatch(
              smartwayApi.endpoints.fetchEntities.initiate({
                entity: 'user',
                page_size: 100,
                company: profile.company.id
              })
            )
          ]
        : [usersPromise]
      const [usersResponse, smartwayUsersResponse] = await Promise.all(promises)
      if (usersResponse.status === 'fulfilled') {
        let results = [...usersResponse.data.results]
        if (smartwayUsersResponse && smartwayUsersResponse.status === 'fulfilled') {
          results = [...results, ...smartwayUsersResponse.data.results]
        }
        setPeopleOptions(
          results && !!results.length
            ? results.map((p) => ({ ...p, label: `${p.first_name} ${p.last_name} - ${p.email}`, groupBy: p.profile.company.name }))
            : []
        )
      }
    }
    if (!peopleOptions && profile.isFull && company) {
      getUsersList()
    }
  }, [company, dispatch, peopleOptions, profile, profile.isFull])

  useEffect(() => {
    if (peopleList && !!peopleList.length) {
      const table = {
        columns: [
          { Header: 'firstname', accessor: 'firstname' },
          { Header: 'lastname', accessor: 'lastname' },
          { Header: 'email', accessor: 'email' }
        ],
        rows: peopleList.map(({ first_name = 'Mario', last_name = 'Rossi', email }) => {
          return {
            firstname: first_name,
            lastname: last_name,
            email
          }
        })
      }
      setPeopleListTable(table)
    }
  }, [peopleList])

  return (
    <Dialog
      key={open}
      open={open}
      maxWidth="md"
      fullWidth
      sx={{ marginLeft: { xl: '250px' } }}
      onClose={handleOnClose}>
      <Container>
        <DialogTitle>{title}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleOnClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}>
          <Icon>close</Icon>
        </IconButton>
        <DialogContent className="dialogContent">
          <MDBox>
            <Select
              name="company-people"
              labelProp="label"
              valueProp="email"
              value={selectValue}
              multiple
              groupBy={(o) => o.groupBy}
              label="Choose an employee from your company"
              options={peopleOptions}
              onChange={handlePeopleChange}
            />
            <Divider>or</Divider>
            <MDBox display="flex" alignItems="center">
              <MDBox flexGrow="1">
                <Form {...schema} resetAfterSubmit onSubmit={handlePeopleSubmit} />
              </MDBox>
              <MDBox mb={3} ml={1}>
                <MDButton type="submit" form="person-form" size="medium" color="secondary">
                  Add
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
          {peopleListTable ? (
            <MDBox py={2}>
              <DataTable title="People of your company" table={peopleListTable} />
            </MDBox>
          ) : null}
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleOnClose}>Cancel</MDButton>
          <MDButton onClick={handleOnSave} variant="contained" color="primary">
            Save
          </MDButton>
        </DialogActions>
      </Container>
    </Dialog>
  )
}

export default SinglePeopleDialog
