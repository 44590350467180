import { Link } from 'react-router-dom'

import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Icon from '@mui/material/Icon'

import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'

import { getMainImage, getBestFormat } from 'utils/image'

const iconStyles = {
  transitionProperty: 'opacity, transform',
  transitionDuration: '0.3s',
  opacity: 0,
  transform: 'scale(0.8)',
  position: 'absolute',
  top: '12px',
  right: '12px',
  zIndex: '10',
  color: 'white !important',
  borderRadius: '50%',
  width: '30px',
  height: '30px',
  backgroundColor: 'primary.main',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}

const PackageCard = ({ id, title, images, excerpt, town, all_in_venue, agendas, onDeletePackage, onClonePackage }) => {
  const fullVenue = town || all_in_venue
  const fullVenueImage = fullVenue.featured_image ? fullVenue.featured_image : fullVenue.images ? getMainImage(fullVenue.images) : null
  const mainImage = images && images.length ? getMainImage(images) : fullVenueImage

  const handleDeletePackage = () => {
    onDeletePackage(id)
  }

  const handleClonePackage = () => {
    onClonePackage({ id, title })
  }

  return (
    <Card
      className="package-card"
      sx={{
        flexDirection: 'row',
        height: '100%',
        '&:hover': {
          '.package-card__icon': {
            opacity: 1,
            transform: 'scale(1)'
          }
        }
      }}>
      <CardMedia
        component="img"
        src={!!mainImage ? getBestFormat(mainImage.formats, 'mobile') : ''}
        alt={title}
        sx={{
          margin: 0,
          minHeight: '100%',
          width: '33% !important',
          minWidth: '33% !important',
          objectFit: 'cover',
          borderRadius: '12px 0 0 12px',
          display: 'inline-block',
          position: 'relative',
          '::before': {
            content: "''",
            border: 'none',
            width: '100%',
            height: 'calc(100% + 10px)',
            backgroundColor: 'rgba(230, 230, 230, 1)',
            position: 'absolute',
            top: '50%',
            left: 0,
            transform: 'translateY(-50%)'
          }
        }}
      />
      <MDBox py={1} width="95%" maxWidth="95%" position="relative">
        <MDBox
          className="package-card__icon"
          sx={{
            ...iconStyles,
            right: '48px',
            backgroundColor: 'transparent',
            color: 'primary.main',
            border: '1px solid',
            borderColor: 'primary.main'
          }}>
          <Icon fontSize="small" onClick={handleClonePackage}>
            copy
          </Icon>
        </MDBox>
        <MDBox className="package-card__icon" sx={iconStyles}>
          <Icon fontSize="small" onClick={handleDeletePackage}>
            delete
          </Icon>
        </MDBox>
        <CardContent sx={{ display: 'flex', flexDirection: 'column', height: '100%', pr: 2 }}>
          <MDTypography variant="h4" fontWeight="medium" maxWidth="80%">
            {title}
          </MDTypography>
          <MDTypography variant="subtitle2" dangerouslySetInnerHTML={{ __html: excerpt }} mb={3} maxWidth="80%"/>
          <MDTypography variant="button" display="flex" alignItems="center" mt={1}>
            <Icon sx={{ mr: 1 }}>location_on</Icon> {fullVenue?.name}
          </MDTypography>
          <MDBox
            pt={2}
            display="flex"
            alignItems="flex-end"
            justifyContent="space-between"
            flexGrow={1}
            sx={{ marginBottom: '-16px' }}>
            <Link to={`/dashboard/premade-packages/${id}`}>
              <MDButton variant="gradient" color="secondary" sx={{ mr: 1 }}>
                <Icon sx={{ mr: 1 }}>search</Icon> Package details
              </MDButton>
            </Link>
          </MDBox>
        </CardContent>
      </MDBox>
    </Card>
  )
}

export default PackageCard
