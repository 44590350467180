const filters = {
  name: 'packages-filters',
  fieldsets: [
    {
      title: 'Title',
      subtitle: "Enter the title of the package you're looking for",
      fields: [
        {
          type: 'text',
          name: 'title',
          grid: { xs: 12 }
        },
        {
          type: 'divider',
          hr: true
        }
      ]
    },
    
    {
      title: 'Town / Venue',
      subtitle: 'Choose the town or venue of the experience',
      fields: [
        {
          type: 'select',
          name: 'town',
          label: 'Town',
          // multiple: true,
          valueProp: 'id',
          labelProp: 'name',
          grid: { xs: 6 },
          optionsGetter: {
            endpoints: [
              { entity: 'town' },
            ]
          }
        },
        {
          type: 'select',
          name: 'all_in_venue',
          label: 'Venue',
          // multiple: true,
          valueProp: 'id',
          labelProp: 'name',
          grid: { xs: 6 },
          optionsGetter: {
            endpoints: [
              { entity: 'allInVenue' },
            ]
          }
        },
        {
          type: 'divider',
          hr: true
        }
      ]
    },
    {
      title: 'Package categories',
      subtitle: "Select the categories of the package you're looking for",
      fields: [
        {
          type: 'badges',
          name: 'categories',
          labelProp: 'name',
          valueProp: 'id',
          multiple: true,
          optionsGetter: {
            endpoints: [
              {
                entity: 'packageCategories'
              }
            ]
          }
        },
        {
          type: 'divider',
          hr: true
        }
      ]
    },
    {
      title: 'Agenda categories',
      subtitle: "Select the categories of the agenda you're looking for",
      fields: [
        {
          type: 'badges',
          name: 'agenda_categories',
          labelProp: 'name',
          valueProp: 'id',
          multiple: true,
          optionsGetter: {
            endpoints: [
              {
                entity: 'agendaCategories'
              }
            ]
          }
        },
        {
          type: 'divider',
          hr: true
        }
      ]
    },
    {
      title: 'Number of days',
      subtitle: "Select the number of days range you're looking for",
      fields: [
        {
          type: 'range',
          name: 'nb_days',
          min: 1,
          max: 10,
          initialValue: [1, 4],
          minDistance: 1,
          withInputs: true,
          grid: { xs: 12, display: 'flex', justifyContent: 'center' },
          boxStyles: {
            width: '100%',
            maxWidth: '600px'
          }
        },
        {
          type: 'divider',
          hr: true
        }
      ]
    },
    {
      title: 'Partcipants number',
      subtitle: 'How many participants must the package accodomate?',
      fields: [
        {
          type: 'range',
          name: 'participants',
          min: 0,
          max: 150,
          suffix: 'pp',
          initialValue: [50, 150],
          minDistance: 20,
          withInputs: true,
          grid: { xs: 12, display: 'flex', justifyContent: 'center' },
          boxStyles: {
            width: '100%',
            maxWidth: '600px'
          }
        },
        {
          type: 'divider',
          hr: true
        }
      ]
    },
    {
      title: 'Price per person',
      subtitle: "Select the price per person range you're looking for",
      fields: [
        {
          type: 'range',
          name: 'price',
          min: 0,
          max: 1500,
          prefix: '€',
          initialValue: [200, 600],
          minDistance: 50,
          withInputs: true,
          grid: { xs: 12, display: 'flex', justifyContent: 'center' },
          boxStyles: {
            width: '100%',
            maxWidth: '600px'
          }
        }
      ]
    }
  ]
}

export default filters
