import { Link } from 'react-router-dom'
import { format } from 'date-fns'
import { excerptHtml } from 'utils/functions'

import MDBox from 'components/MDBox'

import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import CardActionArea from '@mui/material/CardActionArea'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Icon from '@mui/material/Icon'

import Image from 'components/Image'
import MDTypography from 'components/MDTypography/index'

const LocationCard = ({
  id,
  url,
  image,
  title,
  description,
  publishedAt,
  price,
  location,
  canClone,
  onDelete,
  onClone
}) => {

  const Subtitle = () => {
    return (
      <MDBox display="flex" alignItems="center" mt={1}>
        <Icon sx={{ mr: 1 }} color={publishedAt ? 'success' : 'warning'}>
          {publishedAt ? 'check' : 'warning'}
        </Icon>
        <MDTypography variant="caption">
          {publishedAt ? `Published at ${format(new Date(publishedAt), 'dd/MM/yyyy')}` : 'Draft'}
        </MDTypography>
      </MDBox>
    )
  }

  const handleOnDelete = () => {
    onDelete(id)
  }
  const handleOnClone = () => {
    onClone({ id, title })
  }

  return (
    <Card>
      <CardHeader sx={{ minHeight: '116px'}} title={title} subheader={<Subtitle />} />
      <CardActionArea>
        <Link to={url} style={{ display: 'flex', flexDirection: 'column' }}>
          <Image {...image} minHeight="180px" maxHeight="180px" format="desktop" />
          <CardContent sx={{ flexGrow: 1 }}>
            { description ? (
              <Typography
                variant="body2"
                color="text.secondary"
                dangerouslySetInnerHTML={{ __html: excerptHtml(description, 80, false) }}></Typography>
            ) : null }
            {price ? (
              <MDTypography variant="body2" fontWeight="regular" color="text" mt={1}>
                {price}
              </MDTypography>
            ) : null}
            {location ? (
              <MDBox color="text" display="flex" mt={2}>
                <Icon color="inherit" sx={{ mt: 0.5, mr: 0.5 }}>
                  place
                </Icon>
                <Link to={url}>
                  <MDTypography variant="button" fontWeight="light" color="text">
                    {location}
                  </MDTypography>
                </Link>
              </MDBox>
            ) : null}
          </CardContent>
        </Link>
      </CardActionArea>
      <CardActions disableSpacing sx={{ justifyContent: 'flex-end', marginTop: 'auto' }}>
        <IconButton aria-label="delete" onClick={handleOnDelete} sx={{ ':hover': { backgroundColor: 'grey.200'} }}>
          <Icon color="error">delete</Icon>
        </IconButton>
        { canClone ? (
          <IconButton aria-label="copy" onClick={handleOnClone} sx={{ ':hover': { backgroundColor: 'grey.200'} }}>
            <Icon>copy</Icon>
          </IconButton>
        ) : null}
        <Link to={url}>
          <IconButton aria-label="edit" sx={{ ':hover': { backgroundColor: 'grey.200'} }}>
            <Icon>edit</Icon>
          </IconButton>
        </Link>
      </CardActions>
    </Card>
  )
}

export default LocationCard
