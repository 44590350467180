import { useState, useEffect, useCallback } from 'react'

// react-router-dom components
import { useNavigate, useSearchParams, Link, useSubmit, useActionData } from 'react-router-dom'

// Redux components
import { useDispatch } from 'react-redux'
import { notify } from 'store/reducers/UISlice'
import { smartwayApi } from 'services/api'

// Firebase
import { getFirebaseError } from 'services/firebase'

// Form
import form from './form'
import Form from 'components/Form'

// @mui material components
import Card from '@mui/material/Card'
import Alert from '@mui/material/Alert'
// import Switch from '@mui/material/Switch'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'

// Authentication layout components
import BasicLayout from 'layouts/authentication/components/BasicLayout'

// Images
import bgImage from 'assets/images/montepulciano.jpeg'

const SignIn = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [errorAlert, setErrorAlert] = useState(null)
  const [initialValues, setInitialValues] = useState({})
  const dispatch = useDispatch()
  const submit = useSubmit()
  const actionData = useActionData()
  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams()

  const handleSignIn = async (values) => {
    setErrorAlert(null)
    submit(
      { ...values, email: values.email?.toLowerCase() },
      { method: 'post', encType: 'application/json' }
    )
  }

  const verifyFirebaseToken = useCallback(async () => {
    try {
      const firebaseResponse = await dispatch(
        smartwayApi.endpoints.verifyToken.initiate(null, { forceRefetch: true })
      )
      if (firebaseResponse.status === 'fulfilled' && firebaseResponse.data.success) {
        setIsLoading(false)
        const next = searchParams.get('next')
        navigate(next ? next : '/dashboard')
      } else {
        setIsLoading(false)
        dispatch(smartwayApi.util.resetApiState())
      }
    } catch (error) {
      setIsLoading(false)
    }
  }, [dispatch, navigate, searchParams])

  useEffect(() => {
    if (isLoading) {
      verifyFirebaseToken()
    }
  }, [verifyFirebaseToken, isLoading])

  useEffect(() => {
    if (actionData && actionData.error) {
      const { code } = actionData.fullError
      if (code === 'auth/user-not-found') {
        setErrorAlert({
          severity: 'warning',
          message: 'No user found with this email. Please register and try again.',
          sx: { 
            flexWrap: 'wrap', 
            alignItems: 'center',
            '.MuiAlert-icon': { flex: 0 },
            '.MuiAlert-message': { flex: 1 },
            '.MuiAlert-action': { flexBasis: '100% !important', display: 'flex', justifyContent: 'flex-end' } },
          action: <MDButton sx={{ minHeight: 'auto !important', pt: '0 !important' }}>
            <Link to="/authentication/sign-up">Register</Link>
          </MDButton>
        })
      }
      if (code === 'auth/user-disabled') {
        setErrorAlert({
          severity: 'warning',
          message: 'Your must confirm your email to activate your account. Please check your emails (including spam) and click on the confirmation link.'
        }
        )
      }
      dispatch(
        notify({
          type: 'error',
          title: 'Login failed',
          icon: 'error',
          message: getFirebaseError(actionData.fullError),
          timeout: 8000,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right'
          }
        })
      )
    }
  }, [actionData, dispatch])

  useEffect(() => {
    if (searchParams.get('deleted')) {
      const newSearchParams = {}
      for (const [key, value] of searchParams.entries()) {
        if (key !== 'deleted') {
          newSearchParams[key] = value
        }
      }
      setSearchParams(newSearchParams)
      dispatch(
        notify({
          type: 'success',
          title: 'See you next time!',
          message: (
            <p>
              Your account has been deleted successfully. <br /> In case you did it by mistake,
              contact an administrator.
            </p>
          ),
          anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
        })
      )
    }
    if (searchParams.get('ie')) {
      if (!searchParams.get('ui')) {
        navigate(`/authentication/sign-up?ie=${searchParams.get('ie').replace(' ', '+')}`)
      } else {
        setInitialValues({ ...initialValues, email: searchParams.get('ie') })
      }
    }
  }, [dispatch, navigate, searchParams, setSearchParams])

  return !isLoading ? (
    <BasicLayout image={bgImage}>
      <Card sx={{ position: 'relative', zIndex: 100 }}>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center">
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox>
            <Form {...form} values={initialValues} onSubmit={handleSignIn} />
            {errorAlert && (
              <MDBox mb={3}>
                <Alert variant="outlined" {...errorAlert} dismissible>
                  {errorAlert.message}
                </Alert>
              </MDBox>
            )}
            <MDBox mb={1} display="flex" justifyContent="flex-end">
              <MDButton variant="gradient" color="info" type="submit" form="signin">
                Sign in
              </MDButton>
            </MDBox>
            <MDBox mt={1} display="flex" justifyContent="center">
              <MDButton variant="text" size="small">
                <Link to="/authentication/reset-password">Forgot password?</Link>
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Don't have an account?{' '}
                <Link
                  to={`/authentication/sign-up${
                    searchParams.get('next')
                      ? `?next=${searchParams.get('next')}`
                      : searchParams.get('ie')
                      ? `?ie=${searchParams.get('ie')}`
                      : ''
                  }`}>
                  Sign up
                </Link>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  ) : (
    <p>Loading...</p>
  )
}

export default SignIn
