import { smartwayApi } from "services/api/index"
import { redirect, redirectDocument } from "react-router-dom"

export const editOrCreateCompanyAction =
(dispatch, entity, redirectBaseUrl, store) =>
async ({ request, params }) => {
  try {
    const action = request.method === 'POST' ? 'createEntity' : 'updateEntity'
    const payload = await request.formData()
    if (payload.get('intent') && payload.get('intent') === 'users') {
      const _payload = Object.fromEntries(payload.entries());
      delete _payload.intent
      const searchParams = new URLSearchParams(_payload)
      return redirect(`/dashboard/companies/${params.id}?${searchParams.toString()}`)
    }

    const companyId = payload.get('id')
    // form data to json
    let payloadJSON = {}
    const logoFormData = new FormData()
    if (payload.get('logo')) {
      logoFormData.append('logo', payload.get('logo'))
    }
    if (payload.get('logo_negative')) {
      logoFormData.append('logo_negative', payload.get('logo_negative'))
    }
    for (const pair of logoFormData.entries()) {
      console.log(pair[0], pair[1]);
    }
    for (let [key, value] of payload) {
      const exclude = ['logo', 'logo_negative', '__address', 'hq_longitude', 'hq_latitude']
      if (!exclude.includes(key)) {
        if (key === 'accepted_domains') {
          value = value.split(',')
        }
        payloadJSON[key] = value
      }
    }
    const avoidCompanyPatch = Object.keys(payloadJSON).length === 1 && Object.keys(payloadJSON)[0] === 'id'
    const [resultCompany, logoResult] = await Promise.all([
      ...(avoidCompanyPatch ? [] : [dispatch(smartwayApi.endpoints[action].initiate({ entity, ...payloadJSON }))]),
      ...(!companyId ? [] : [dispatch(smartwayApi.endpoints.updateEntity.initiate({ 
        bodyType: 'formData',
        entity: 'company',
        id: companyId,
        action: 'logo/',
        formData: logoFormData
      }))])
    ])

    if (!companyId && !logoResult) {
      await dispatch(smartwayApi.endpoints.updateEntity.initiate({
        bodyType: 'formData',
        entity: 'company',
        id: resultCompany.data.id,
        action: 'logo/',
        formData: logoFormData
      }))
    }
    

    if (action === 'createEntity') {
      if (resultCompany.data) {
        // TODO: understand why redirect doesn't work and does not trigger reload of the routes
        await dispatch(smartwayApi.endpoints.createEntity.initiate({ entity: 'agenda', company: resultCompany.data.id, title: `Agenda for ${resultCompany.data.name}` }))
        const hasCompany = !!store.getState().auth.profile?.company
        return redirectDocument(hasCompany ? `${redirectBaseUrl}/${resultCompany.data.id}?new=true` : `/dashboard/profile`)
      } else {
        let message = resultCompany.error?.message || 'Error during creation'
        if (resultCompany.error.name) {
          message = 'A company with this name already exists. Please contact your Smartway referent for more information.'
        }
        return {
          error: 'Error during creation',
          fullError: message
        }
      }
    }
    if (resultCompany.data) {
      return { success: true }
    } else {
      if (resultCompany.error) {
        const arrayMessage = []
        Object.keys(resultCompany.error).forEach(k => {
          if (k !== 'message') {
            arrayMessage.push(`${k}: ${resultCompany.error[k]}`)
          }
        })
        return {
          error: resultCompany.error?.message || 'Error during update',
          fullError: arrayMessage.join('\n'),
        }
      } else {
        throw new Error('Error during update')
      }
    }
  } catch (error) {
    return {
      error: 'There was an error!',
      fullError: error
    }
  }
}