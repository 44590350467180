import { defer } from 'react-router-dom'
import { BaseLoader } from 'router/BaseLoader'
import { smartwayApi } from 'services/api'
import { OFFER_STATUSES } from 'utils/offsiteStatus'
import offersFilters from 'layouts/pages/offers/list/filters'

const waiter = (resolver, time = 3000) => {
  return new Promise((resolve) => setTimeout(() => {
    resolve(resolver)
  }, time))
}

export class OffersLoader extends BaseLoader {
  listLoader = async ({ request }) => {
    const url = new URL(request.url)
    try {
      const results = this._loader(
        smartwayApi.endpoints.fetchEntities,
        request,
        {
          entity: 'offsite',
          ...Object.fromEntries(url.searchParams),
          status: OFFER_STATUSES.map(s => s.name),
          page_size: 25
        }
      )
      return defer({
        results,
        initialFilters: offersFilters
      })
    } catch (error) {
      return error
    }
  }

  detailLoader = async ({ params, request }) => {
    const isNew = !params.id
    const promises = isNew
      ? [
          this._loader(smartwayApi.endpoints.fetchFullProfile, request),
          new Promise(resolve => resolve({
            status: ['OFFER'],
            payment_policy: `
              <div>
                <p>All tariffs are standard policy:</p>
                <ul>
                  <li>100% payment at signing</li>
                  <li>0% refund for cancelled participants.</li>
                  <li>Flexible policies available upon request.</li>
                </ul>`,
            service_in: `Branded app and Town Angel support during the offsite|Dedicated workspace during the offsite|Accommodation in double rooms|Event secretariat`,
            service_out: `Round-trip transfer from a single pick-up point to the village|Additional experiences`
          }))
        ]
      : [
          this._loader(smartwayApi.endpoints.fetchFullProfile, request),
          this._loader(smartwayApi.endpoints.fetchEntity, request, {
            entity: 'offsite',
            id: params.id
          }),
          this._loader(smartwayApi.endpoints.fetchOffsiteEntity, request, {
            entity: 'offer',
            offsiteId: params.id
          }),
        ]
    const [profile, offsite, offer] = await Promise.all([
      ...promises
    ])
    let offsiteVenue = {}
    if (offsite && (offsite.town || offsite.all_in_venue)) {
      offsiteVenue = await this._loader(
        smartwayApi.endpoints.fetchEntity,
        request,
        {
          entity: offsite.all_in_venue ? 'allInVenue' : 'town',
          id: offsite.all_in_venue || offsite.town
        }
      )
    }

    return {
      offsite: {
        ...offsite,
        ...offer
      },
      profile,
      offsiteVenue
    }
  }

  detailDefinitionLoader = async ({ params, request }) => {
    const agendasResponse = await this._loader(
      smartwayApi.endpoints.fetchEntities,
      request,
      {
        entity: 'agenda',
        offsite: params.id
      }
    )
    if (agendasResponse && agendasResponse.results && agendasResponse.results[0]) {
      const isActivities = agendasResponse.results[0].activities && agendasResponse.results[0].activities.length
      const activities = isActivities ? agendasResponse.results[0].activities : []
      return {
        activitiesNumber: activities.length
      }
    }
    return {}
  }
  detailAgendaLoader = async ({ params, request }) => {
    let activities = []
    const [activitiesResponse] = await waiter(Promise.all([
      this._loader(smartwayApi.endpoints.fetchOffsiteEntity, request, {
        entity: 'activities',
        offsiteId: params.id,
        params: {
          page: 1,
          page_size: 200
        }
      }, { forceRefetch: true }),
      
    ]), 500)
    if (activitiesResponse && activitiesResponse.results) {
      activities = activitiesResponse.results
    }

    return {
      activities,
      participants: []
    }
  }
  detailCommunicationLoader = async ({ params, request }) => {
    const communication = await this._loader(smartwayApi.endpoints.fetchOffsiteEntity, request, {
        entity: 'offer',
        offsiteId: params.id
      }, { forceRefetch: true })
    return {
      communication
    }
  }
  detailReviewLoader = async ({ params, request}) => {
    const [participants, agendas] = [
      this._loader(smartwayApi.endpoints.fetchOffsiteEntity, request, {
        entity: 'participants',
        offsiteId: params.id,
        params: {
          page: 1,
          page_size: 200
        }
      }),
      this._loader(smartwayApi.endpoints.fetchEntities, request, {
        entity: 'agenda',
        offsite: params.id,
        page: 1,
        page_size: 200
      })
    ]



    return defer({
      participants,
      agendas
    })
  }
}
