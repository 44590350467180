import { useState } from 'react'

import MDButton from 'components/MDButton'
import MDBox from 'components/MDBox'
import MDAlert from 'components/MDAlert'

import {
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Icon
} from '@mui/material'

import schema from './schemas/avatar'

import Form from 'components/Form'

const AvatarDialog = ({ open, avatar, title, error, onClose, onSave }) => {
  const [previewURL, setPreviewURL] = useState(avatar?.mobile)
  const onError = (e) => {
    setPreviewURL('https://via.placeholder.com/640x360.jpg?text=Set%20Image')
  }
  const handleFieldChange = (event) => {
    const { files, name } = event.target
    if (name === 'avatar') {
      if (files) {
        const reader = new FileReader()
        reader.onload = () => {
          setPreviewURL(reader.result)
        }
        reader.readAsDataURL(files[0])
      } else {
        setPreviewURL(null)
      }
    }
  }

  const handleOnSave = (values) => {
    if (onSave && typeof onSave === 'function') {
      onSave(Array.isArray(values.avatar) ? values.avatar[0] : values.avatar)
      setPreviewURL(null)
    }
  }

  const handleOnClose = () => {
    setPreviewURL(null)
    if (onClose && typeof onClose === 'function') {
      onClose()
    }
  }

  return (
    <Dialog
      key={open}
      open={open}
      maxWidth="sm"
      fullWidth
      sx={{ marginLeft: { xl: '250px' }}}
      onClose={handleOnClose}>
      <Container>
        {error ? (
          <MDAlert color="error" sx={{ mt: 1 }}>
            <Icon fontSize="large">error</Icon>&nbsp;
            {error}
          </MDAlert>
        ) : null}
        <DialogTitle>{title}</DialogTitle>
        <DialogContent className="dialogContent">
          {previewURL ? (
            <MDBox px={2} display="flex" justifyContent="center">
              <MDBox
                component="img"
                src={previewURL}
                alt={title}
                borderRadius="lg"
                shadow="md"
                position="relative"
                zIndex={1}
                sx={{
                  maxWidth: '100%',
                  maxHeight: '25vh',
                  objectFit: 'contain'
                }}
                onError={onError}
              />
            </MDBox>
          ) : null}
          <Form
            {...schema}
            id="avatar-form"
            watchedFields={['avatar']}
            onSubmit={handleOnSave}
            onFieldChange={handleFieldChange}
          />
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleOnClose}>Cancel</MDButton>
          <MDButton
            form="avatar-form"
            type="submit"
            variant="contained"
            color="primary">
            Save
          </MDButton>
        </DialogActions>
      </Container>
    </Dialog>
  )
}

export default AvatarDialog
