import acceptedDomains from "utils/acceptedDomains"
const getCompanySchema = ({ isCompany, inDialog }) => {
  return {
    id: 'company-form',
    title: inDialog ? null : 'Company Information',
    ctasPosition: 'top',
    fieldsets: [
      {
        id: 'general',
        title: '',
        fields: [
          {
            type: 'text',
            label: 'Name',
            name: 'name',
            required: true,
            validationType: 'string',
            validations: [
              {
                type: 'required',
                params: ['This field is required']
              }
            ]
          },
          {
            type: 'file',
            label: 'Logo',
            name: 'logo',
            required: !isCompany,
            validationType: 'mixed',
            validations: [
              {
                type: 'fileSize',
                params: ['File too large', '5480000']
              },
              {
                type: 'fileFormat',
                params: ['Unsupported Format', ['image/jpg', 'image/jpeg', 'image/gif', 'image/png']]
              },
              ...(!isCompany ? [{
                type: 'required',
                params: ['This field is required']
              }] : [{
                type: 'nullable'
              }]),
            ],
            accept: 'image/png,image/jpeg,image/jpg'
          },
          {
            type: 'file',
            label: 'Logo (with inverted colors)',
            name: 'logo_negative',
            validationType: 'mixed',
            validations: [
              {
                type: 'fileSize',
                params: ['File too large', '5480000']
              },
              {
                type: 'fileFormat',
                params: ['Unsupported Format', ['image/jpg', 'image/jpeg', 'image/gif', 'image/png']]
              },
              {
                type: 'nullable'
              },
            ],
            accept: 'image/png,image/jpeg,image/jpg'
          },
          {
            type: 'text',
            label: 'VAT',
            name: 'vat',
            validationType: 'string',
            validations: [
              {
                type: 'vat',
                params: ['Invalid VAT format']
              }
            ]
          },
          {
            type: 'address',
            name: 'hq_address',
            label: 'Address',
            latitudeProp: 'hq_latitude',
            longitudeProp: 'hq_longitude',
            validationType: 'string',
            validations: null
          },
          {
            type: 'select',
            multiple: true,
            freeSolo: true,
            name: 'accepted_domains',
            label: 'Accepted email domains',
            validationType: 'array',
            validations: [
              {
                type: 'acceptedValues',
                params: ['Domain(s) not allowed', acceptedDomains]
              },
              {
                type: 'nullable'
              }
            ],
            helperText: 'List of full domains. Ex: company.com, company.org, my-company.it',
          }
        ]
      }
    ],
    submitButton: inDialog ? null : {
      label: 'Save'
    },
    cancelButton: inDialog ? null : {
      label: 'Cancel'
    }
  }
}


export default getCompanySchema
