import { Suspense, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  Await,
  useLocation,
  useLoaderData,
  useActionData,
  useSubmit,
  useNavigate,
  useSearchParams
} from 'react-router-dom'

import { capitalizeFirstLetter } from 'utils/functions'

// @mui material components
import Grid from '@mui/material/Grid'
import Icon from '@mui/material/Icon'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import ImageCarousel from 'components/ImageCarousel'

import { notify } from 'store/reducers/UISlice'

import LocationForm from './LocationForm'
import AccommodationRooms from 'components/AccommodationRooms'
import PlaceInfos from 'components/PlaceInfos'

import {
  LocationDetailSkeleton,
  RoomsSkeleton
} from '../listPage/Skeletons'

const publicPath = {
  accommodation: 'accommodations',
  experience: 'experiences',
  catering: 'food-drink',
  coworking: 'coworkings',
  plenaryHall: 'event-spaces',
  allInVenue: 'venues',
  venueAccommodation: 'venue-accommodations',
  workshop: 'team-building'
}

const LocationDetailPage = ({ entityName, editingMode, routeSegment }) => {
  const { entity, images, rooms } = useLoaderData()
  const { state } = useLocation()
  const actionData = useActionData()
  const submit = useSubmit()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    if (actionData?.error) {
      dispatch(
        notify({
          type: 'error',
          title: 'There was an error',
          message: actionData?.fullError,
          anchorOrigin: { vertical: 'top', horizontal: 'right' }
        })
      )
    }
    if ((searchParams && searchParams.get('new')) || actionData?.success) {
      dispatch(
        notify({
          type: 'success',
          title: 'All right!',
          message: `${capitalizeFirstLetter(entityName)} saved successfully`,
          anchorOrigin: { vertical: 'top', horizontal: 'right' }
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionData, dispatch, entityName])

  const onCancel = () => {
    let search = ''
    if (state && state.searchParams) {
      search = `?${new URLSearchParams(state.searchParams).toString()}`
    }
    navigate(`/dashboard/locations/${routeSegment}s${search}`)
  }
  const onSave = async (newEntity) => {
    let cleanEntity = {...newEntity}
    if (cleanEntity.features && cleanEntity.features.length === 0) {
      delete cleanEntity.features
    }
    submit(newEntity, {
      method: editingMode ? 'patch' : 'post',
      encType: 'application/json'
    })
  }

  const onPublishChange = (payload) => {
    submit(payload, { method: 'patch', encType: 'application/json' })
  }

  const handleImagesChange = () => {
    submit({ onlyImages: true }, { method: 'post', encType: 'application/json' })
  }

  const handleSaveRoom = async (values) => {
    const isNew = !values.id
    const roomId = isNew ? {} : { roomId: values.id }
    const payload = {
      subentity: 'room',
      accommodationId: values.entityId,
      ...values,
      ...roomId
    }
    submit(payload, { method: isNew ? 'post' : 'patch', encType: 'application/json' })
  }

  const handleBulkAddRooms = (values) => {
    submit({ subentity: 'bulkRoom', accommodationId: values.entityId, ...values }, { method: 'post', encType: 'application/json' })
  }

  const handleDeleteRoom = async ({ entityId, id }) => {
    const payload = {
      subentity: 'room',
      accommodationId: entityId,
      roomId: id
    }
    submit(payload, { method: 'delete', encType: 'application/json' })
  }

  useEffect(() => {
    if (searchParams.get('new')) {
      setSearchParams({})
    }
  }, [searchParams, setSearchParams])

  return (
    <Suspense fallback={<LocationDetailSkeleton />}>
      <Await resolve={entity} errorElement={<p>There was an error</p>}>
        {(entity) => {
          return (
            <MDBox my={3}>
              <MDBox mb={6}>
                {editingMode ? (
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} lg={12}>
                      <MDBox display="flex" justifyContent="space-between" alignItems="center">
                        <MDTypography variant="h4" fontWeight="medium">
                          {entity.name}
                        </MDTypography>
                        {!['town'].includes(entityName) ? (
                          <a
                            href={`https://listings.smartway.work/${publicPath[entityName]}/${entity.id}`}
                            target="_blank"
                            rel="noreferrer">
                            <MDButton variant="outlined" color="dark" size="small">
                              See the public page
                              <Icon sx={{ ml: 1 }}>open_in_new</Icon>
                            </MDButton>
                          </a>
                        ) : null}
                      </MDBox>
                    </Grid>
                  </Grid>
                ) : null}
              </MDBox>
              <Grid container spacing={3} justifyContent="center">
                {editingMode ? (
                  <Grid item xs={12} lg={4}>
                    <ImageCarousel
                      id={entity.id}
                      entity={entityName}
                      images={images}
                      onChange={handleImagesChange}
                    />
                  </Grid>
                ) : null}
                <Grid item xs={12} lg={8}>
                  <LocationForm
                    entityName={entityName}
                    entity={entity}
                    onSubmit={onSave}
                    onCancel={onCancel}
                    onPublishChange={onPublishChange}
                  />
                  {['accommodation', 'venueAccommodation'].includes(entityName) && editingMode ? (
                    <Suspense fallback={<RoomsSkeleton />}>
                      <Await resolve={rooms}>
                        {(rooms) => {
                          return (
                            <MDBox mt={3}>
                              <AccommodationRooms
                                entity={entity}
                                rooms={rooms?.results}
                                entityName={entityName}
                                onSave={handleSaveRoom}
                                onBulkAdd={handleBulkAddRooms}
                                onDelete={handleDeleteRoom}
                              />
                            </MDBox>
                          )
                        }}
                      </Await>
                    </Suspense>
                  ) : null}
                  { entityName === 'catering' && entity.place_details ? (
                    <>
                      <PlaceInfos placeDetails={entity.place_details} />
                    </>
                  ) : null }
                </Grid>
              </Grid>
            </MDBox>
          )
        }}
      </Await>
    </Suspense>
  )
}

export default LocationDetailPage
