import { useEffect } from 'react'

import {
  useLoaderData,
  useNavigate,
  useSubmit,
  useSearchParams,
  useActionData
} from 'react-router-dom'

import { useDispatch } from 'react-redux'
import { notify } from 'store/reducers/UISlice'

// @mui material components
import Grid from '@mui/material/Grid'
import Sidenav from 'layouts/pages/account/profile/components/Sidenav'
import DataTable from 'components/Tables/DataTable'
import UserCell from 'components/Tables/Cells/UserCell'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

// CompanyDetailPage components
import CompanyForm from './components/CompanyForm'
import Card from '@mui/material/Card'

const CompanyDetailPage = ({ editingMode }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { company, users } = useLoaderData()
  const actionData = useActionData()
  const submit = useSubmit()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  let sidenavItems = [
    { icon: 'business', label: 'company infos', href: 'basic-info' },
    { icon: 'group', label: 'people', href: 'users' }
  ]
  const table = {
    columns: [
      { Header: 'user', accessor: 'user' },
      { Header: 'role', accessor: 'role' },
      { Header: 'status', accessor: 'status' }
    ],
    rows:
      users && users.length
        ? users.map(({ id, first_name = 'Mario', last_name = 'Rossi', profile, groups }) => {
            const { avatar } = profile
            const name = `${first_name} ${last_name}`
            return {
              user: <UserCell id={id} image={avatar} name={name} baseUrl="/dashboard/users/"/>,
              role: groups && !!groups.length ? groups.map(g => g.name).join(', ') : 'Not defined'
            }
          })
        : []
  }

  const onCancel = () => navigate(`/dashboard/companies`)
  const onSave = async (newEntity) => {
    const formData = new FormData()
    
    for (const [key, value] of Object.entries(newEntity)) {
      const shouldUpdate = company && company[key] ? value !== company[key] : true
      if (['logo', 'logo_negative'].includes(key)) {
        if (Array.isArray(value) && value[0] instanceof File) {
          formData.append(key, value[0])
        }
      } else if (shouldUpdate || key === 'id') {
        formData.append(key, value)
      }
    }
    submit(formData, {
      method: editingMode ? 'patch' : 'post',
      encType: 'multipart/form-data'
    })
  }

  const handleSearch = (value) => {
    const newSearchParams = {}
    searchParams.delete('page')
    if (!value) {
      searchParams.delete('search')
    }
    for (const [key, value] of searchParams.entries()) {
      newSearchParams[key] = value
    }
    submit(
      { ...newSearchParams, intent: 'users', ...(value ? { search: value } : {}) },
      { method: 'post', encType: 'multipart/form-data' }
    )
  }

  const handlePageSizeChange = (pageSize) => {
    const newSearchParams = {}
    for (const [key, value] of searchParams.entries()) {
      newSearchParams[key] = value
    }
    submit(
      { ...newSearchParams, intent: 'users', page_size: pageSize },
      { method: 'post', encType: 'multipart/form-data' }
    )
  }

  useEffect(() => {
    if (actionData?.error) {
      dispatch(
        notify({
          type: 'error',
          title: 'There was an error',
          message:
            actionData.fullError && typeof actionData.fullError === 'string'
              ? actionData.fullError
              : 'Please try again later or contact an administrator',
          anchorOrigin: { vertical: 'top', horizontal: 'right' }
        })
      )
    }
    if ((searchParams && searchParams.get('new')) || actionData?.success) {
      dispatch(
        notify({
          type: 'success',
          title: 'All right!',
          message: `Company saved successfully`,
          anchorOrigin: { vertical: 'top', horizontal: 'right' }
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionData, dispatch])

  useEffect(() => {
    if (searchParams.get('new')) {
      setSearchParams({})
    }
  }, [searchParams, setSearchParams])

  return (
    <MDBox my={3}>
      <MDBox mb={6}>
        {company && company.name ? (
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} lg={6}>
              <MDTypography variant="h4" fontWeight="medium">
                {company.name}
              </MDTypography>
            </Grid>
          </Grid>
        ) : null}
      </MDBox>

      <Grid container spacing={3} justifyContent="center">
        {users && users.length ? (
          <Grid item xs={12} lg={3}>
            <Sidenav items={sidenavItems} />
          </Grid>
        ) : null}
        <Grid item xs={12} lg={9} id="basic-info">
          <CompanyForm
            company={company}
            onSubmit={onSave}
            onCancel={onCancel}
            editingMode={editingMode}
          />
        </Grid>
        {users && users.length ? (
          <>
            <Grid item lg={3}></Grid>
            <Grid item xs={12} lg={9} id="users">
              <Card>
                <DataTable
                  title="People"
                  canSearch
                  table={table}
                  // pagination={{
                  //   count,
                  //   page: parseInt(searchParams.get('page')) || 1,
                  //   lastPage: page_number,
                  //   pageSize: parseInt(searchParams.get('page_size')) || 10
                  // }}
                  onSearch={handleSearch}
                  onPageSizeChange={handlePageSizeChange}
                />
              </Card>
            </Grid>
          </>
        ) : null}
      </Grid>
    </MDBox>
  )
}

export default CompanyDetailPage
