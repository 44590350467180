import { useState, useCallback } from 'react'

import { Link } from 'react-router-dom'

import getSchema from './schema'
import Form from 'components/Form'
import Card from '@mui/material/Card'
import Icon from '@mui/material/Icon'
import MDBox from 'components/MDBox'
import MDAlert from 'components/MDAlert'
import MDTypography from 'components/MDTypography'

const PackageDetailForm = ({ entity, venueType, venue, editingMode, onSubmit, onCancel }) => {
  const [noAgendas, setNoAgendas] = useState(false)
  const handleNoResults = useCallback(() => {
    setNoAgendas(true)
  }, [])
  
  const schema = getSchema({ entity, editingMode, venueType, venue, handleNoResults })

  const handleOnSubmit = (values) => {
    onSubmit(values)
  }

  return (
    <Card>
      <MDBox p={4}>
        {noAgendas ? (
          <MDAlert dismissible color="warning">
            <MDBox display="flex" flexDirection="column">
              <MDTypography color="white" variant="h6" fontWeight="medium">
                There are no agendas for {venue.name}!
              </MDTypography>
              <MDTypography
                color="white"
                variant="h6"
                sx={{ display: 'flex', alignItems: 'center', fontWeight: 400 }}>
                Please create one before creating the package
                <Link
                  to="/dashboard/agendas/new"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    textDecoration: 'none',
                    color: 'white',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}>
                  <Icon sx={{ ml: 1, color: 'inherit' }}>open_in_new</Icon>
                </Link>
              </MDTypography>
            </MDBox>
          </MDAlert>
        ) : null}
        <Form
          {...schema}
          values={entity}
          onSubmit={handleOnSubmit}
          onCancel={onCancel}
        />
      </MDBox>
    </Card>
  )
}

export default PackageDetailForm
