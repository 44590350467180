import { useState, useEffect, useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

import Form from 'components/Form'
import schemas from './schemas'

import Card from '@mui/material/Card'
import MDBox from 'components/MDBox'

const LocationForm = ({ entity, entityName, onSubmit, onCancel, onPublishChange }) => {
  const [isVenue, setIsVenue] = useState(!!entity.all_in_venue)
  const [venuePrefix, setVenuePrefix] = useState(!!entity.all_in_venue ? 'allInVenue' : 'town')
  const [placeId, setPlaceId] = useState(null)
  const groups = useSelector((state) => state.auth.groups)
  const [schema, setSchema] = useState(
    schemas[entityName]({ groups, [entityName]: entity, onPublishChange })
  )

  const formatPayload = useCallback(
    (payload) => {
      const { position, position_switch, price_switch, town, __address, ...rest } = payload
      let _payload = {}
      if (position_switch) {
        _payload = { position: position ? Number(position) * 1000 : 0 }
      }
      if (!price_switch) {
        _payload = { ..._payload, price: 0 }
      }
      if (town && typeof town === 'string') {
        const splitted = town.split('_')
        const venueValue = splitted[1] ? Number(splitted[1]) : Number(splitted[0])
        if (isVenue) {
          _payload = { ..._payload, all_in_venue: venueValue, town: null }
        } else {
          _payload = { ..._payload, all_in_venue: null, town: venueValue }
        }
      } else {
        _payload = { ..._payload, town }
      }
      if (placeId) {
        _payload = { ..._payload, place_id: placeId }
      }
      return {
        position,
        ...rest,
        ..._payload,
      }
    },
    [isVenue, placeId]
  )

  const handlePublishChange = useCallback(
    (published, originalEntity) => {
      const payload = formatPayload(originalEntity)
      onPublishChange({ ...payload, published_at: published ? new Date() : null })
    },
    [formatPayload, onPublishChange]
  )

  const handleSubmit = (values) => {
    const payload = formatPayload({ ...entity, ...values })
    onSubmit(payload)
  }

  const handleFieldChange = (event, values) => {
    const { value, name, fullValue } = event.target
    if (['position_switch', 'price_switch'].includes(name)) {
      setSchema(
        schemas[entityName]({
          groups,
          [entityName]: { ...entity, ...values, [name]: value }
        })
      )
    }
    if (
      name === 'town' &&
      ['catering', 'experience', 'coworking', 'plenaryHall'].includes(entityName)
    ) {
      setIsVenue(fullValue ? fullValue.entity === 'Venues' : false)
      setVenuePrefix(fullValue ? fullValue.prefix : 'town')
    }
    if (name === '__address' && entityName === 'catering') {
      setPlaceId(value.place_id)
    }
  }

  const values = useMemo(
    () => ({
      ...entity,
      town: entity?.all_in_venue
        ? `${venuePrefix}_${entity.all_in_venue}`
        : entity?.town
        ? `${venuePrefix}_${entity.town}`
        : null,
      town_angel: entity?.town_angel?.profile_id,
      host: entity?.host?.profile_id,
      concierge: entity?.concierge?.profile_id,
      position_switch: !!entity?.position,
      position: entity?.position ? entity?.position / 1000 : 0,
      price_switch: !!entity?.price_min || !!entity?.price_max || !!entity?.normal_price
    }),
    [entity, venuePrefix]
  )

  useEffect(() => {
    if (entity) {
      setSchema(
        schemas[entityName]({
          groups,
          [entityName]: values,
          onPublishChange: handlePublishChange
        })
      )
    }
  }, [entity, entityName, groups, handlePublishChange, onPublishChange, values])

  return (
    <Card>
      <MDBox p={4}>
        <Form
          {...schema}
          watchedFields={['__address', 'position_switch', 'price_switch', 'town']}
          onFieldChange={handleFieldChange}
          values={values}
          onSubmit={handleSubmit}
          onCancel={onCancel}
        />
      </MDBox>
    </Card>
  )
}

export default LocationForm
