import { useState } from 'react'
import Form from 'components/Form'
import getCompanySchema from './schema'

import Card from '@mui/material/Card'
import MDBox from 'components/MDBox'
import MDAvatar from 'components/MDAvatar'
import { getBestFormat } from 'utils/image'

const CompanyForm = ({ company, inDialog = false, onSubmit, onCancel }) => {
  const mainLogo = getBestFormat(company?.logo_formats, 'mobile')
  const negativeLogo = getBestFormat(company?.logo_negative_formats, 'mobile')
  const [previewURL, setPreviewURL] = useState(mainLogo)
  const [previewNegativeURL, setPreviewNegativeURL] = useState(negativeLogo)

  const handleSubmit = (values) => {
    onSubmit({ ...company, ...values })
  }

  const schema = getCompanySchema({ isCompany: !!company, inDialog })

  const handleFieldChange = (event) => {
    const { files, name } = event.target
    if (name === 'logo') {
      if (files) {
        const reader = new FileReader()
        reader.onload = () => {
          setPreviewURL(reader.result)
        }
        reader.readAsDataURL(files[0])
      } else {
        setPreviewURL(null)
      }
    }
    if (name === 'logo_negative') {
      if (files) {
        const reader = new FileReader()
        reader.onload = () => {
          setPreviewNegativeURL(reader.result)
        }
        reader.readAsDataURL(files[0])
      } else {
        setPreviewNegativeURL(null)
      }
    }
  }

  return inDialog ? (
    <MDBox>
      <MDBox display="flex" gap={2}>
        {previewURL ? (
          <MDAvatar src={previewURL} size="xl" shadow="xl" sx={{ objectFit: 'fill', margin: 3 }} />
        ) : null}
        {previewNegativeURL ? (
          <MDAvatar
            src={previewNegativeURL}
            size="xl"
            shadow="xl"
            sx={{ objectFit: 'fill', margin: 3 }}
          />
        ) : null}
      </MDBox>
      <Form
        {...schema}
        watchedFields={['logo', 'logo_negative']}
        onFieldChange={handleFieldChange}
        values={company}
        onSubmit={handleSubmit}
        onCancel={onCancel}
      />
    </MDBox>
  ) : (
    <Card>
      <MDBox display="flex" gap={1}>
        {previewURL ? (
          <MDAvatar src={previewURL} size="xl" shadow="xl" sx={{ objectFit: 'fill', margin: 3 }} />
        ) : null}
        {previewNegativeURL ? (
          <MDAvatar
            src={previewNegativeURL}
            size="xl"
            shadow="xl"
            sx={{ objectFit: 'fill', margin: 3 }}
          />
        ) : null}
      </MDBox>
      <MDBox p={3}>
        <Form
          {...schema}
          watchedFields={['logo', 'logo_negative']}
          onFieldChange={handleFieldChange}
          values={company}
          onSubmit={handleSubmit}
          onCancel={onCancel}
        />
      </MDBox>
    </Card>
  )
}

export default CompanyForm
