import { smartwayApi } from 'services/api/index'
import { redirect } from 'react-router-dom'
import { set, differenceInDays, addDays } from 'date-fns'
import { saveParticipantPreferences } from 'utils/offsites'

export const definitionAction = (dispatch, entity, redirectBaseUrl, redirectStep) => {
  return async ({ request }) => {
    try {

      const payload = await request.json()
      const action = request.method === 'POST' ? 'createEntity' : 'updateEntity'

      const result = await dispatch(
        smartwayApi.endpoints[action].initiate({
          entity,
          ...payload
        })
      )
      if (result && result.data) {
        /**
         * When dates of an offsite are changed, we need to update the dates of all activities
         */
        const offsiteChangeDates = action === 'updateEntity' && payload.changeOffsiteDates
        if (offsiteChangeDates) {
          const { id, start_at } = result.data
          // Number is negative if date is anterior to start_at
          const differenceDays = differenceInDays(
            new Date(start_at),
            new Date(payload.oldDates.start_at)
          )
 
          const agendas = await dispatch(
            smartwayApi.endpoints.fetchEntities.initiate({
              entity: 'agenda',
              offsite: id
            }),
            { forceRefetch: true }
          )

          // It is a double check because I already did it in the page to set property changeOffsiteDates
          if (agendas.data && agendas.data.results && agendas.data.results[0]) {
            const activities = agendas.data.results[0].activities
            let activitiesPromises
            activitiesPromises = activities.map(({ location, ...activity }) => {
              // Get the original hours because addDays reset the hours
              const startAtHour = new Date(activity.start_at).getHours()
              const startAtMinutes = new Date(activity.start_at).getMinutes()

              // differenceDays could be negative but addDay handles it and act like subDays
              let newStartAt = addDays(new Date(activity.start_at), differenceDays)
              newStartAt = set(newStartAt, {
                hours: startAtHour,
                minutes: startAtMinutes
              })

              // We cannot just take the same date as start date because the end date could be the day after (party at night for example)
              // Get the original hours because addDays reset the hours
              const endAtHour = new Date(activity.end_at).getHours()
              const endAtMinutes = new Date(activity.end_at).getMinutes()

              // differenceDays could be negative but addDay handles it and act like subDays
              let newEndAt = addDays(new Date(activity.end_at), differenceDays)
              newEndAt = set(newEndAt, {
                hours: endAtHour,
                minutes: endAtMinutes
              })
              

              const newActivity = { ...activity, start_at: newStartAt, end_at: newEndAt }
              return dispatch(
                smartwayApi.endpoints.updateEntity.initiate({
                  entity: 'activities',
                  id: activity.id,
                  ...newActivity
                })
              )
            })
            await Promise.all(activitiesPromises)
          }
        }

        if (payload.transformInOffsite) {
          const excludedProps = ['id', 'slug', 'agendas', 'updated_at', 'created_at', 'rooms', 'participants']
          const newOffsitePayload = {}
          for (const [key, value] of Object.entries(result.data)) {
            if (!excludedProps.includes(key)) {
              newOffsitePayload[key] = value
            }
          }
          if (newOffsitePayload.start_at && newOffsitePayload.end_at) {
            delete newOffsitePayload.indicative_number_days  
          } else {
            delete newOffsitePayload.start_at
            delete newOffsitePayload.end_at
          }
          if (newOffsitePayload.town) {
            delete newOffsitePayload.all_in_venue
          } else {
            delete newOffsitePayload.town
          }

          const newOffsite = await dispatch(smartwayApi.endpoints.createEntity.initiate({ 
            entity: 'offsite',
            ...newOffsitePayload,
            offer_id: result.data.id,
            // We remove all the offer related statuses
            status: ['SIGNED_CONTRACT', 'DRAFT']
          }))
          if (newOffsite.data) {
            const agendaId = result.data.agendas && result.data.agendas[0] ? result.data.agendas[0] : null
            if (agendaId) {
              // Se c'è un agenda lo clono nel nuovo offsite
              await dispatch(smartwayApi.endpoints.cloneEntity.initiate({ 
                entity: 'agenda',
                id: agendaId,
                offsite: newOffsite.data.id
              }))
            } else {
              // Altrimenti lo creo da zero
              await dispatch(smartwayApi.endpoints.createEntity.initiate({ 
                entity: 'agenda',
                offsite: newOffsite.data.id,
                title: `Agenda for ${newOffsite.data.title}`,
              }))
            }
            
            return redirect(`/dashboard/offsites/${newOffsite.data.id}/definition?contract-signed=true`)
          }
        }

        const step = redirectStep || 'participants'
        if (redirectBaseUrl) {
          return redirect(
            `${redirectBaseUrl}/${result.data.id}/${step}${
              action === 'updateEntity' ? '' : '?new=true'
            }`
          )
        }

        return {
          success: true,
          id: result.data.id,
          entity: 'Offer'
        }
      }
      return { error: 'There was an error!' }
    } catch (error) {
      return {
        error: 'There was an error!',
        fullError: error
      }
    }
  }
}
export const offsiteDefaultAction =
  (dispatch, entity) =>
  async ({ request }) => {
    try {
      const actions = {
        default: {
          POST: 'createOffsiteEntity',
          PATCH: 'updateOffsiteEntity',
          DELETE: 'deleteOffsiteEntity'
        },
        agenda: {
          POST: 'createEntity',
          PATCH: 'updateEntity',
          DELETE: 'deleteEntity'
        },
        preferences: {
          PATCH: 'updateEntity',
        }
      }
      const payload = await request.json()
      const subentity = payload.subentity || 'default'
      const action = actions[subentity][request.method]

      if (payload.intent) {
        if (payload.intent === 'participant-preferences') {
          const responses = await saveParticipantPreferences({ payload, dispatch, context: 'offsite' })
          return {
            intent: 'participant-preferences',
            success: responses.every((r) => !!r.data)
          }
        }
      }

      if (!payload.onlyReload) {
        const result = await dispatch(
          smartwayApi.endpoints[action].initiate({
            entity,
            ...payload
          }, { forceRefetch: true })
        )

        if (result && result.data) {
          return {
            success: true,
            id: result.data.id
          }
        }
      } else {
        return {
          success: true
        }
      }
      return { error: 'There was an error!' }
    } catch (error) {
      return {
        error: 'There was an error!',
        fullError: error
      }
    }
  }
